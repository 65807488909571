async function importDialogTemplate(templateId) {
  switch (templateId) {
    case 'vst-core-column-options':
      return (await import('../dialog-templates/vst-core-column-options.js')).template;
    case 'vst-ui-message-box':
      return (await import('../dialog-templates/vst-ui-message-box.js')).template;
    case 'vst-core-export':
      return (await import('../dialog-templates/vst-core-export.js')).template;
    case 'vst-ui-custom-expression-info':
      return (await import('../dialog-templates/vst-ui-custom-expression-info.js')).template;
    case 'vst-ui-presentation':
      return (await import('../dialog-templates/vst-ui-presentation.js')).template;
    case 'vst-ui-prompt':
      return (await import('../dialog-templates/vst-ui-prompt.js')).template;
    case 'vst-core-feature-flags':
      return (await import('../dialog-templates/vst-core-feature-flags.js')).template;
    case 'vst-core-device-info':
      return (await import('../dialog-templates/vst-core-device-info.js')).template;
    case 'vst-core-device-manager':
      return (await import('../dialog-templates/vst-core-device-manager.js')).template;
    case 'vst-core-fit-options':
      return (await import('../dialog-templates/vst-core-fit-options.js')).template;
    case 'vst-core-meter-select':
      return (await import('../dialog-templates/vst-core-meter-select.js')).template;
    default:
      return '';
  }
}

export const DialogMediator = Base =>
  class extends Base {
    constructor() {
      super();

      this.isDialogMediator = true;

      this.dialogs = {
        templates: [],
        close: dialogId => {
          if (!this.dialogs[dialogId]) return;
          this.dialogs[dialogId].show = false;
          this.requestUpdate();
          if (this.dialogs[dialogId].onClose) this.dialogs[dialogId].onClose();
          if (this.dialogs[dialogId].element.hasAttribute('open'))
            this.dialogs[dialogId].element.toggleAttribute('open');
        },
      };

      this.addEventListener('open-dialog', event => {
        if (this.$services.popoverManager.hasDialogs()) this.$services.popoverManager.closeAll();
        event.stopPropagation();
        const {
          detail: { dialog, params, onClose },
        } = event;
        this.dialogs[dialog].show = true;
        this.dialogs[dialog].params = params;
        this.dialogs[dialog].onClose = onClose;
        this.requestUpdate();
        if (!this.dialogs[dialog].element.hasAttribute('open')) {
          this.dialogs[dialog].element.toggleAttribute('open');
        }
      });

      this.addEventListener('close-dialog', event => {
        event.stopPropagation();
        const { detail: dialogId } = event;
        this.dialogs.close(dialogId);
      });

      this.addEventListener('register-dialog', event => {
        event.stopPropagation();
        const { detail: dialog } = event;
        this.dialogs[dialog.id] = {
          show: false,
          element: dialog,
        };
        dialog.addEventListener('dialog-close', event => {
          event.stopPropagation();
          const { detail: closingDialog } = event;
          if (closingDialog.id !== dialog.id) return;
          if (this.dialogs[dialog.id].show) this.dialogs.close(dialog.id);
        });
      });
      this.addEventListener('dispose-dialog', event => {
        event.stopPropagation();
        const { detail: dialog } = event;
        delete this.dialogs[dialog.id];
      });
    }

    /**
     *
     * @param {String/Array} templateId a single templateId String, or an Array of templateId Strings. Valid templateIds are in the importDialogTemplate function above.
     */
    async registerDialogTemplate(templateId) {
      if (Array.isArray(templateId))
        return this.dialogs.templates.push(
          ...(await Promise.all(templateId.map(importDialogTemplate))),
        );
      return this.dialogs.template.push(await importDialogTemplate(templateId));
    }
  };
