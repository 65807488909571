// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-extraneous-dependencies
import EventEmitter from 'eventemitter3';

let nextId = 0;

export class Spectrum extends EventEmitter {
  constructor({
    baseColId = 0,
    color = 'rgba(120,120,120,1)',
    dataSetId = 0,
    id = `spectrum-${nextId++}`,
    lineWeight = 2,
    name = 'spectrum',
    points = [],
    position = 0,
    traceColId = 0,
    type = 'spectrum',
  }) {
    super();
    this._baseColId = baseColId;
    this._color = color;
    this._dataSetId = dataSetId;
    this._id = id;
    this._lineWeight = lineWeight;
    this._name = name;
    this._points = points;
    this._position = position;
    this._traceColId = traceColId;
    this._type = type;
  }

  get baseColId() {
    return this._baseColId;
  }

  set baseColId(baseColId) {
    this._baseColId = baseColId;
  }

  get color() {
    return this._color;
  }

  set color(color) {
    this._color = color;
    this.emit('color-change', color);
  }

  get dataSetId() {
    return this._dataSetId;
  }

  set dataSetId(dataSetId) {
    this._dataSetId = dataSetId;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get lineWeight() {
    return this._lineWeight;
  }

  set lineWeight(lineWeight) {
    this._lineWeight = lineWeight;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.emit('name-changed', name);
  }

  get points() {
    return this._points;
  }

  set points(points) {
    this._points = points;
    this.emit('points-changed', points);
  }

  get position() {
    return this._position;
  }

  set position(position) {
    this._position = position;
  }

  get traceColId() {
    return this._traceColId;
  }

  set traceColId(traceColId) {
    this._traceColId = traceColId;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }
}
