import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';
import '@common/libs/array.prototype.at.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip-wrapper.js';
import './va-app.js';

if (!('PointerEvent' in window)) {
  (async () => {
    await import('@wessberg/pointer-events');
  })();
}

if (!('ResizeObserver' in window)) {
  (async () => {
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  })();
}

if (!PRODUCTION && !('toJs' in window)) {
  (async () => {
    const mobx = await import('mobx');
    window.toJS = mobx.toJS;
  })();
}

serviceWorkerInitializer.addEventListener('first-install-complete', () => {
  const runThumbnailPrecache = async ({ data }) => {
    if (data.type === 'PRECACHE_SAMPLE_THUMBNAILS') {
      const { sampleVideos } = await import('./va-welcome/sampleVideos.js');
      const sampleThumbs = sampleVideos.map(data => data.thumbnail);
      for (const thumbnail of sampleThumbs) {
        // create the images and have the browser fetch them, the serviceworker will then cache them for future use
        const thumb = new Image();
        thumb.crossOrigin = true;
        thumb.src = thumbnail;
      }
      navigator.serviceWorker.removeEventListener('message', runThumbnailPrecache);
    }
  };
  navigator.serviceWorker.addEventListener('message', runThumbnailPrecache);
});
serviceWorkerInitializer.init();
