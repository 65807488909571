import EventTarget from '@ungap/event-target';
import { nextTick } from '../nextTick.js';

export class ServiceWorkerInitializer extends EventTarget {
  constructor(
    nativeModulesScriptSource = 'DataCollection.js',
    appHasDeviceAccess = true,
    serviceWorkerLocation = './sw.js',
  ) {
    super();
    /** @type {string} the [src] of the nativeModulesScript for the app */
    this.nativeModulesScriptSource = nativeModulesScriptSource;
    this.appHasDeviceAccess = appHasDeviceAccess;
    this.serviceWorkerLocation = serviceWorkerLocation;
    this._updating = null;

    this.addEventListener(
      'new-serviceworker-ready',
      () => {
        this._newServiceWorkerReady = true;
      },
      { once: true },
    );

    this._setupFrameMessageChannel = this.setupFrameMessageChannel.bind(this);
    window.addEventListener('message', this._setupFrameMessageChannel);
  }

  get authoringMode() {
    return this._authoringMode;
  }

  set authoringMode(value) {
    throw new Error(
      `authoringMode is read-only and cannot be changed here. The current value is ${this._authoringMode} and cannot be changed to ${value}`,
    );
  }

  /**
   * Retrieves the active service worker.
   *
   * @return {ServiceWorker} The active service worker.
   */
  get activeServiceWorker() {
    return this.registration.active;
  }

  /**
   * Retrieves the waiting service worker.
   *
   * @return {ServiceWorker | null} The waiting service worker or null if none is found.
   */
  get waitingServiceWorker() {
    return this.registration.waiting;
  }

  /**
   * Retrieve the incoming service worker.
   *
   * @return {ServiceWorker | null} The incoming service worker or null if not available.
   */
  get incomingServiceWorker() {
    return this.registration.installing;
  }

  get allAvailableServiceworkers() {
    return [this.incomingServiceWorker, this.waitingServiceWorker, this.activeServiceWorker].filter(
      worker => worker,
    );
  }

  /**
   * Returns whether the ServiceWorker was installed in a previous session but the update was not triggered.
   *
   * @return {boolean} Whether the ServiceWorker was installed in a previous session but the update was not triggered.
   */
  get priorInstalledServiceWorkerNeverUpdated() {
    return this.waitingServiceWorker && this.activeServiceWorker;
  }

  /**
   * Returns a promise that resolves when the new service worker is ready.
   *
   * @return {Promise<ServiceWorkerInitializer>} A promise that resolves with the active service worker.
   */
  get newServiceWorkerReady() {
    return new Promise(resolve => {
      if (this._newServiceWorkerReady) resolve(this);
      else
        this.addEventListener('new-serviceworker-ready', () => resolve(this), {
          once: true,
        });
    });
  }

  /**
   * Returns whether this is the first installation of the service worker.
   *
   * @return {boolean} Returns true if this is the first installation of the service worker, otherwise false.
   */
  get isFirstInstallOfServiceWorker() {
    return this.incomingServiceWorker && !this.activeServiceWorker;
  }

  /**
   * Checks if the first installation is complete.
   *
   * @return {boolean} Returns true if the first installation is complete, otherwise false.
   */
  get isFirstInstallComplete() {
    return this.waitingServiceWorker?.state === 'installed' && !this.activeServiceWorker;
  }

  /**
   * Returns true if the service worker has finished installing and is currently active.
   *
   * @return {boolean} - true if the service worker has finished installing and is currently active
   */
  get isUpdateInstallComplete() {
    return this.waitingServiceWorker?.state === 'installed' && this.activeServiceWorker;
  }

  /**
   * Returns a Promise that resolves to a boolean indicating whether an updated ServiceWorker has been found.
   *
   * @return {Promise<boolean>} A Promise that resolves to a boolean value. True if an update has been found, false otherwise.
   */
  get updateComplete() {
    return new Promise(resolve => {
      if (this._updating !== null) {
        return resolve(window._updateAvailable);
      }
      this.addEventListener(
        'update-available',
        () => {
          resolve(true);
        },
        { once: true },
      );
      this.addEventListener(
        'upate-not-found',
        () => {
          resolve(false);
        },
        { once: true },
      );
      return true;
    });
  }

  /**
   * Get the value of the _joinedSession property.
   *
   * @return {boolean} The value of the _joinedSession property.
   */
  get _joinedSession() {
    return this.__joinedSession;
  }

  /**
   * Setter for the _joinedSession property.
   *
   * @param {boolean} value - The new value for the _joinedSession property.
   * @event joined-session
   * @return {void} - This function does not return a value.
   */
  set _joinedSession(value) {
    this.__joinedSession = value;
    this.dispatchEvent(new Event('joined-session'));
  }

  /**
   * waits for the session to be joined in the serviceworker, then resolves with the active serviceworker.
   *
   * @return {Promise<ServiceWorker>} A promise that resolves with the active service worker.
   */
  get joinedSession() {
    if (this._joinedSession) {
      return Promise.resolve(this.activeServiceWorker);
    }
    return new Promise(resolve => {
      this.addEventListener(
        'joined-session',
        () => {
          resolve(this.activeServiceWorker);
        },
        { once: true },
      );
    });
  }

  /**
   * Sets up the frame message channel to get messages from a host window.
   *
   * @param {Event} event - The message event object containing the setup data.
   */
  async setupFrameMessageChannel(event) {
    if (event.data.type !== 'setup_message_channel') return this;
    const { setupFrameMessageChannel } = await import('./blockHostInitialization.js');
    await setupFrameMessageChannel.call(this, event);
    return this;
  }

  /**
   * Raise an event for messages coming through to detect milestone events
   * @param {MessageEvent} event the Event from a 'message' event
   * @param {ServiceWorkerMessage} event.data the data passed through the 'message'
   */
  raiseEventsForIncomingMessages() {
    navigator.serviceWorker.addEventListener('message', ({ data: { type } }) => {
      this.dispatchEvent(new Event(type));
    });
  }

  /**
   * an update was found for the ServiceWorker, once it is done installing, notify the user of the update's availability
   */
  finishInstallAndNotify() {
    this._updating = true;
    this.raiseEventsForIncomingMessages();
    this.incomingServiceWorker.addEventListener('statechange', () => {
      if (this.isUpdateInstallComplete) {
        this.notifyUpdateAvailable();
      }
    });
  }

  onInstallTakeControl() {
    if (!this.isFirstInstallComplete) return;
    this._firstInstall = true;
    navigator.serviceWorker.addEventListener(
      'controllerchange',
      () => {
        this.dispatchEvent(new Event('first-install-complete'));
        this.dispatchEvent(new Event('new-serviceworker-ready'));
        this.joinSession();
      },
      { once: true },
    );
    this.waitingServiceWorker.postMessage({ type: 'FIRST_INSTALL' });
  }

  async groupCollectionCompleted() {
    this.activeServiceWorker.postMessage({ type: 'GROUP_COLLECTION_COMPLETE' });
  }

  /**
   * checks the app is in an embed state and is the host then events the host is ready and triggers the initialized attribute
   * @return {Promise<ServiceWorkerInitializer>}
   */
  async notifyHostInitialized(services) {
    if (!this.hostMessageChannel) return this;
    const { blockHostInitialization } = await import('./blockHostInitialization.js');
    await blockHostInitialization.call(this, services);
    return this;
  }

  /**
   * Initializes the service worker.
   *
   * @return {ServiceWorkerInitializer} - The initializer.
   */
  async init() {
    if (this.registration) return this;
    if (!('serviceWorker' in navigator)) {
      const { handleCordova } = await import('./handleCordova.js');
      return handleCordova();
    }
    this.registration = await navigator.serviceWorker.register(this.serviceWorkerLocation, {
      scope: './',
    });
    this.allAvailableServiceworkers.forEach(worker =>
      worker.postMessage({
        type: window.location.search.includes('demo-mode') ? 'DEMO-MODE' : 'NOT-DEMO-MODE',
      }),
    );

    if (this.registration) {
      this.registration.addEventListener('updatefound', () => this.finishInstallAndNotify());
      try {
        await this.registration.update();
        await nextTick();
      } catch (error) {
        if (navigator.onLine) console.error(error);
        else console.warn('Cannot perform a serviceworker update while offline. Skipping update.');
      }
      if (this._updating === null) this.dispatchEvent(new Event('no-update-found'));
      this._updating = false;

      if (this.priorInstalledServiceWorkerNeverUpdated) this.notifyUpdateAvailable();

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this._firstInstall || PLATFORM_ID !== 'web') return;
        window.location.reload();
      });
    }

    if (this.isFirstInstallOfServiceWorker) {
      this.incomingServiceWorker.addEventListener('statechange', () => this.onInstallTakeControl());
    } else {
      // wait for a service worker to be active
      await navigator.serviceWorker.ready;
      this.dispatchEvent(new Event('new-serviceworker-ready'));
      this.joinSession();
    }
    return this;
  }

  /**
   * Notifies the window that an update is available.
   */
  notifyUpdateAvailable() {
    if (PLATFORM_ID !== 'web') {
      this.activeServiceWorker.postMessage({ type: 'SKIP_WAITING', claim: true });
    } else {
      window._updateAvailable = true;
      window.dispatchEvent(new CustomEvent('update-available'));
    }
    this._updating = false;
  }

  /**
   * Posts to the waiting serviceworker to skip waiting and claim the clients.
   */
  skipWaitingAndClaim() {
    this.waitingServiceWorker.postMessage({ type: 'SKIP_WAITING', claim: true });
  }

  /**
   * Triggers the loading of native modules.
   */
  triggerNativeModulesLoad() {
    const head = document.querySelector('head');
    const nativeModulesScript = document.createElement('script');
    nativeModulesScript.src = this.nativeModulesScriptSource;

    head.append(nativeModulesScript);
    window.frameElement?.toggleAttribute('host', true);
  }

  /**
   * Handles session messages from the ServiceWorker.
   *
   * @param {MessageEvent} message - a postMessage message event from the ServiceWorker.
   * @param {ServiceWorkerMessage|JoinedMessage} message.data
   */
  async handleSessionMessages(message) {
    const { data } = message;
    const { type, host } = data;
    if (type === 'JOINED') {
      if (host) {
        this.triggerNativeModulesLoad();
        if (this.appHasDeviceAccess) {
          const { hostDevices } = await import('./hostDevices.js');
          hostDevices(this.activeServiceWorker);
        }
      } else {
        /**
         * denotes a session client that is dependent on a host that has native modules loaded
         * Checked to make calls that are host/client specific
         */
        window.__isSessionClient = true;
        if (this.appHasDeviceAccess) {
          const { proxyDeviceCalls } = await import('./proxyDeviceCalls.js');
          proxyDeviceCalls(this.activeServiceWorker);
        }
        window.frameElement?.toggleAttribute('host', false);
      }
      if (window.wasmModuleReady) this.activeServiceWorker.postMessage({ type: 'WASM_READY' });
      else
        document.addEventListener('wasm-ready', () => {
          this.activeServiceWorker.postMessage({ type: 'WASM_READY' });
        });
    }
    if (type === 'WASM_READY') {
      if (typeof window.Module.postRun === 'function') window.Module.postRun();
      this._joinedSession = true;
    }
    if (this.hostMessageChannel && type === 'GROUP_COLLECTION_COMPLETE') {
      this.hostMessageChannel.postMessage({ type: 'GROUP_COLLECTION_COMPLETE' });
    }
  }

  /**
   * Join a session.
   */
  async joinSession() {
    if (PLATFORM_ID !== 'web') return;
    navigator.serviceWorker.addEventListener('message', this.handleSessionMessages.bind(this));

    const { sessionId, experimentId, hostId } = await import(
      '@services/adapters/url-handler/id-utils.js'
    );

    this.activeServiceWorker.postMessage({
      type: 'JOIN',
      sessionId: sessionId(),
      experimentId: experimentId(),
      hostId: hostId(),
    });
  }
}

export const serviceWorkerInitializer = new ServiceWorkerInitializer();

/**
 * @typedef {import('./ServiceWorkerInitializer.d.ts').ServiceWorkerMessage} ServiceWorkerMessage
 * @typedef {import('./ServiceWorkerInitializer.d.ts').JoinedMessage} JoinedMessage
 */
