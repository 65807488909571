import { css } from 'lit';

export default css`
  /*  a lot of duplication between vst-ui-listbox and here */
  :host {
    background: var(--vst-color-bg);
    display: block;
    padding: var(--vst-space-s);
    max-block-size: 75vh;
    min-inline-size: max-content;
    overflow: auto;
    border: 1px solid var(--vst-color-popover-border);
  }

  .listbox__list {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
  }

  .listbox__item {
    border-bottom: 1px solid var(--vst-color-bg-secondary);
  }
  .listbox__item--select {
    /*  special case for change units select */
    display: flex;
    padding: var(--vst-space-xs) var(--vst-space-s);
    justify-content: space-between;
    align-items: center;
  }

  .listbox__item:last-of-type {
    border-bottom: 0;
  }

  .listbox__sub-options-label {
    display: flex;
    padding-block-end: var(--vst-space-s);
  }

  .listbox__switch {
    display: block;
    padding: var(--vst-space-s);
  }
`;
