import { v4 as uuid } from 'uuid';
import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';

// "We recommend that you keep these private to your organization. If you deploy the measurement
// protocol client-side, you should regularly rotate api_secrets to avoid excessive SPAM."
// — Google, https://developers.google.com/analytics/devguides/collection/protocol/ga4/reference?client_type=gtag#payload_query_parameters
const API_SECRET = 'KomJ9y25SPqMAqdylUlRQw';
const MEASUREMENT_ID = 'G-MG8Y2B8V27';
const LOCAL_STORAGE_KEY = 'ga4-client-id';

const collectionURL = `https://google-analytics.com/mp/collect?measurement_id=${MEASUREMENT_ID}&api_secret=${API_SECRET}`;

export default class Analytics {
  constructor() {
    this.lastEventTime = null;
  }

  /**
   * Get a unique client ID for tracking
   *
   * @return {string} client ID
   */
  static _getClientId() {
    let clientId = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!clientId) {
      clientId = uuid();
      localStorage.setItem(LOCAL_STORAGE_KEY, clientId);
    }

    return clientId;
  }

  /**
   * Track an event of the given name and params in Google Analytics 4
   *
   * @param {string} name Event name
   * @param {object} params Event params
   */
  trackEvent(name, params) {
    if (!isFeatureFlagEnabled('ff-google-analytics')) {
      return;
    }

    // Check limitations noted in https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=gtag
    console.assert(name.length <= 40, 'Event names must be 40 characters or fewer');
    console.assert(Object.keys(params).length <= 25, 'Events can have a maximum of 25 parameters');
    console.assert(
      Object.keys(params).every(p => p.length <= 40),
      'Parameter names must be 40 characters or fewer',
    );
    console.assert(
      Object.values(params).every(p => p.length <= 100),
      'Parameter values must be 100 character or fewer',
    );

    const timeSinceLastEvent = this.lastEventTime
      ? Math.round(performance.now()) - this.lastEventTime
      : 0;

    const data = {
      client_id: Analytics._getClientId(),
      // Cached (e.g. batch, offline) events can be sent with a unix timestamp in microseconds
      // timestamp_micros: new Date().getTime() * 1000,
      events: [
        {
          name,
          params: {
            ...params,
            engagement_time_msec: timeSinceLastEvent,
            language: navigator.language,
            page_encoding: document.characterSet,
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
          },
        },
      ],
    };
    fetch(collectionURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    this.lastEventTime = Math.round(performance.now());
  }

  /**
   * Track a page view with the given location and optional title
   *
   * @param {string} location Location
   * @param {string} [title] Title
   */
  trackPageView(location, title) {
    this.trackEvent('page_view', {
      page_location: `https://graphicalanalysis.app${location}`,
      page_title: title || document.title,
    });
  }
}
