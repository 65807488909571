import { css } from 'lit';
import { buttonReset, buttonDisabled, toolbarCollectButton } from '@styles/index.js';

export default css`
  /*
 Themable with these properties
  --toolbar-color-bg
  --toolbar-color-icon
  --toolbar-color-icon-hover
  --toolbar-color-icon-hover-bg
*/
  :host {
    background: var(--toolbar-color-bg, var(--vst-color-bg-secondary));
  }

  .toolbar {
    position: relative;
    display: flex;
    height: var(--vst-toolbar-height);
    padding: 0 var(--vst-space-xs);
  }

  /* //////////////////////////// */
  .left-content,
  .right-content {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .left-content {
    min-inline-size: 0;
  }

  .right-content {
    justify-content: flex-end;
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 var(--vst-space-xs);
  }

  :host(.show-wait) .toolbar-collect {
    z-index: calc(var(--vst-z-popover) + 1);
  }

  ${toolbarCollectButton(css`.toolbar-collect--secondary`)}

  .toolbar-collect.toolbar-collect--two-line {
    font-size: var(--vst-font-size-xs);
  }
  .toolbar-collect__second-line {
    display: block;
  }

  .toolbar-collect--secondary {
    background: var(--vst-color-brand);
    color: #fff;
    border: 1px solid var(--vst-color-brand);
    margin-inline-start: var(--vst-space-xs);
  }
  .toolbar-collect--secondary:hover {
    background: var(--vst-color-brand-dark);
    color: #fff;
    border: 1px solid var(--vst-color-brand);
  }
  .toolbar-collect--secondary:disabled:hover {
    background: var(--vst-color-brand);
    border: 1px solid var(--vst-color-brand);
    color: #fff;
  }

  ${buttonDisabled(css`.toolbar-collect`)}
  ${toolbarCollectButton(css`.toolbar-collect`)}


  ${buttonReset(
    css`
      ::slotted(.toolbar-icon) ;
    `,
  )}

  ::slotted(.toolbar-icon) {
    position: relative;
    display: flex;
    align-items: center;
    height: 2.25rem;
    background-color: transparent;
    border-radius: var(--vst-radius);
    border: 1px solid transparent;
    padding: 0 0.3125rem;
    background-clip: padding-box;
    /*  https://stackoverflow.com/a/5612502 */
    font-size: var(--vst-font-size-xs);
    font-weight: bold;
    color: var(--toolbar-color-icon, var(--vst-color-fg-secondary));
    --vst-ui-icon-color: var(--toolbar-color-icon, var(--vst-color-fg-secondary));
  }

  ::slotted(.toolbar-icon:hover),
  ::slotted(.toolbar-icon:focus) {
    outline: 0;
    cursor: pointer;
    background-color: var(--toolbar-color-icon-hover-bg, var(--vst-color-bg));
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: var(--vst-shadow-xs);
    color: var(--toolbar-color-icon-hover, #000);
    --vst-ui-icon-color: var(--toolbar-color-icon-hover, var(--vst-color-fg-primary));
    --toolbar-color-icon-hover-bg: var(--vst-color-btn-toolbar-bg-hover);
  }

  ::slotted(.toolbar-icon:active) {
    background-color: var(--vst-color-bg);
    box-shadow: var(--box-shadow-inset);
  }

  ::slotted(.toolbar-icon:disabled) {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  ::slotted(.experiment-meta) {
    --inline-size: auto;
    display: flex;
    flex-basis: auto;
    flex-shrink: 1;
    align-items: center;
    inline-size: fit-content;
    max-inline-size: 100%;
  }
`;
