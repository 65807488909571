import { toBlob } from 'html-to-image';
import * as platform from './PLATFORM_ID';

/**
 * @return {Promise<Blob>} screenshot
 */
export const capture = async node => {
  if (platform.capture) {
    return platform.capture(node);
  }

  // filter out hidden elements - if you don't filter these when dom-to-image encounters a canvas element that isn't on the screen it'll throw an error
  const filter = _node => !_node.hidden;

  const connectedGraphEls = [...node.querySelectorAll('.app-graph')].filter(graph => !graph.hidden);

  // if the node IS the app-graph which we find via a class (TODO?) we add that to the connectedGraphEls array
  if (node.classList.contains('app-graph')) {
    connectedGraphEls.push(node);
  }

  // it seems for safari we may need to prime the capture tool to make it work reliably ¯\_ಠ_ರೃ_/¯
  await toBlob(node, { filter });
  await toBlob(node, { filter });

  return toBlob(node, { filter });
};
