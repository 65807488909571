import { LitElement, html } from 'lit';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import VstUiContentLayoutOptionsStyles from './vst-ui-content-layout-options.css.js';
import '@components/vst-ui-popover/vst-ui-popover.js';
import '@components/vst-ui-switch/vst-ui-switch.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';
import '@components/vst-ui-pro-info/vst-ui-pro-info.js';

class VstUiContentLayoutOptions extends ObservableProperties(LitElement) {
  static get properties() {
    return {
      layoutOptions: {
        type: Array,
      },
      graphOptions: {
        type: Array,
      },
      showProPreview: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.layoutOptions = [
      {
        title: 'Video',
        id: 'video',
        checked: false,
      },
      {
        title: 'Graphs',
        id: 'graphs',
        checked: true,
      },
      {
        title: 'Data Table',
        id: 'table',
        checked: false,
      },
    ];
    this.showProPreview = false;

    this.graphOptions = [
      { title: '1 Graph', id: 'graph_1', selected: true },
      { title: '2 Graphs', id: 'graph_2', selected: false },
      { title: '3 Graphs', id: 'graph_3', selected: false },
    ];
  }

  async _handleLayoutOption(e) {
    const { id } = e.target;
    this.layoutOptions.forEach(layoutOption => {
      if (layoutOption.id === id) layoutOption.checked = e.detail;
    });

    this.requestUpdate();
    await this.updateComplete;
    this._dispatchLayoutOptions({ changedLayoutOptionId: id });
  }

  async _handleGraphOptions({ target }) {
    this.graphOptions.forEach((item, index) => {
      item.selected = index <= target.selectedIndex;
    });
    this.requestUpdate();
    await this.updateComplete;
    this._dispatchLayoutOptions();
  }

  _dispatchLayoutOptions({ changedLayoutOptionId } = {}) {
    this.dispatchEvent(
      new CustomEvent('layout-option-changed', {
        detail: {
          layoutOptions: this.layoutOptions,
          graphOptions: this.graphOptions,
          changedLayoutOptionId,
        },
      }),
    );
  }

  _toggleProPreview({ target: { id } }) {
    const popover = this.shadowRoot.querySelector(`#${id}_popover`);
    popover.show();
  }

  _computeSwitchTemplate(item) {
    if (item.proOnly)
      return html`
        <vst-ui-switch
          class="listbox__switch"
          id="${item.id}"
          pro-only
          label="${item.title}"
          label-placement="right"
          @click="${this._toggleProPreview}"
        ></vst-ui-switch>
        <vst-ui-popover id="${item.id}_popover" for="${item.id}" placement="bottom">
          <p class="body" margin="s">
            ${item.proMessage}
            <vst-ui-pro-info></vst-ui-pro-info>
          </p>
        </vst-ui-popover>
      `;
    return html` <vst-ui-switch
        class="listbox__switch"
        id="${item.id}"
        label="${item.title}"
        label-placement="right"
        ?checked="${item.checked}"
        @switch-changed="${this._handleLayoutOption}"
      ></vst-ui-switch>

      ${item.id === 'graphs'
        ? html`
            <label class="listbox__sub-options-label" for="graph_options">
              <span visually-hidden>${item.title}</span>
              <select
                id="graph_options"
                margin="inline-start-2xl inline-end-xl"
                ?disabled="${!item.checked}"
                @change="${this._handleGraphOptions}"
              >
                ${this.graphOptions.map(
                  graphOption => html`
                    <option
                      value="${graphOption.id}"
                      id="${graphOption.id}"
                      name="graphs"
                      .selected="${graphOption.selected}"
                    >
                      ${graphOption.title}
                    </option>
                  `,
                )}
              </select>
            </label>
          `
        : ''}`;
  }

  static get styles() {
    return [globalStyles, VstUiContentLayoutOptionsStyles];
  }

  render() {
    return html`
      <div class="listbox">
        <ul class="listbox__list">
          ${this.layoutOptions.map(
            item => html` <li class="listbox__item">${this._computeSwitchTemplate(item)}</li> `,
          )}
        </ul>
      </div>
    `;
  }
}

customElements.define('vst-ui-content-layout-options', VstUiContentLayoutOptions);
