import { cloneDeep } from 'lodash-es';

import { api } from '@services/datashare/api.js';

import { DataShareDataSet } from '@services/datashare/DataShareDataSet.js';
import { DataShareColumn } from '@services/datashare/DataShareColumn.js';
import { DataShareView } from '@services/datashare/DataShareView.js';
import { DataShareClient } from './DataShareClient';

/*
 * Note on the object ids
 *
 * We want the object ids to be numbers. This is the case for LQ2, but not for
 * LP, which produces string ids in the format nnn-XXXXXX, where nnn is a base
 * 10 id, and XXXXXX is a base 16 random number. The first part of this id is
 * no use to us, it will remain the same for datasets / columns across multiple
 * runs, so we strip it off, convert the hex part to a number, and then convert
 * that to a string in base 10 representation.
 *
 * The id property of the DataShare column we store is this translated id. The
 * original id string is stored as the 'apiId' property.
 *
 * Neither of these ids is the UDM id used in the backend; the backend will
 * generate its own ids, to ensure integrity of the data model. We pass
 * our id property into / out of the UDM API as the foreignId attribute, and
 * the udm id is then cached on our objects as the nativeId property.
 *
 * This function takes care of fixing up the incoming DS ids.
 *
 * NB: I have retained the convention that the object id is stored as a string.
 *     However, with this change in place, we could start treating them as
 *     numbers, if we wanted. If nothing else, it would get rid of the
 *     inconsistent number to string conversions scattered through out the
 *     codes base (sometimes + '', sometimes `${}`, possibly other ways too).
 */
function fixLPid(id) {
  if (id === undefined) {
    return id;
  }

  if (typeof id !== 'string') {
    return id.toString();
  }

  const dash = id.indexOf('-');
  if (dash === -1) {
    return id;
  }

  return parseInt(id.substring(dash + 1), 16).toString();
}

function getColumnPromise(client, column) {
  const p = client.getColumnById(column.id);

  return p.then(valuesObj => {
    const rows = [];
    const { values } = valuesObj;
    for (let i = 0; i < values.length; ++i) {
      if (values[i] === null) {
        values[i] = NaN;
      }
      rows.push(i);
    }
    column.update({
      values,
      updatedRows: rows,
    });
  });
}

export class DataShareProc {
  static create(client, info) {
    // TODO: based on version, create the appropriate processor
    let proc = null;
    const version = api.parseVersion(info.apiVers);

    if (version.major === 1 && version.minor === 0) {
      proc = new DataShareProc(client, version); // TODO: dispatch to subclass
    }

    return proc;
  }

  constructor(client, version) {
    this.client = client;
    this.version = version;

    this.info = null;
    this.status = null;

    this.session = {
      id: '',
      desc: '',
    };

    this.collection = null;

    this.requestTimeStamp = -1;
    this.columnListTimeStamp = -1;
    this.viewListTimeStamp = -1;

    this.columns = [];
    this.dataSets = [];
    this.views = [];

    return this;
  }

  clear() {
    this.columns = [];
    this.dataSets = [];
    this.views = [];

    this.session = {
      id: '',
      desc: '',
    };

    this.collection = null;

    this.requestTimeStamp = -1;
    this.columnListTimeStamp = -1;
    this.viewListTimeStamp = -1;
  }

  // Return success/failure boolean status
  processInfo(info) {
    this.info = info;
    return true;
  }

  processStatus(_status) {
    const status = cloneDeep(_status);
    const currentDataShareColumns = this.columns;

    this.status = status;

    // view.id needs to be a string, the LabQuest currently returns an integer
    Object.values(status?.views || {}).forEach(view => {
      view.apiId = view.id;
      view.id = fixLPid(view.id);

      // remap property names from Data Share API to be consistent the rest of our codebase
      if (view?.baseColID) {
        view.baseColumnId = fixLPid(view.baseColID);
        delete view.baseColID;
      }
      if (view?.colID) {
        view.meterColumnId = view.colID;
        delete view.colID;
      }

      if (view?.leftTraceColIDs) {
        view.leftTraceColumnIds = [];
        for (let i = 0; i < view.leftTraceColIDs.length; ++i) {
          view.leftTraceColumnIds[i] = fixLPid(view.leftTraceColIDs[i]);
        }
        delete view.leftTraceColIDs;
      }

      view.rightTraceColumnIds = [];
      if (view?.rightTraceColIDs) {
        view.rightTraceColIDs.forEach(columnId => {
          view.rightTraceColumnIds.push(fixLPid(columnId));
        });
        delete view.rightTraceColIDs;
      }
    });

    // data sets colIDs  must be strings
    Object.entries(status?.sets || {}).forEach(([id, dataSet]) => {
      dataSet.apiId = id;
      dataSet.id = fixLPid(id);

      dataSet.columnIds = [];
      for (let i = 0; i < dataSet?.colIDs?.length; ++i) {
        dataSet.columnIds[i] = fixLPid(dataSet.colIDs[i]); // convert to string and translate DS API colIDs to columnIds
      }
      delete dataSet.colIDs;
    });

    // data sets colIDs  must be strings
    Object.values(status?.columns || {}).forEach(column => {
      column.apiId = column.id;
      column.id = fixLPid(column.id);
      column.setId = fixLPid(column.setID); // translate DS API setID to setId
      column.groupId = column.groupID; // translate DS API groupID to groupId
      delete column.setID;
      delete column.groupID;
    });

    this.requestTimeStamp = status.requestTimeStamp;

    this.checkSession({
      id: status.sessionID,
      desc: status.sessionDesc,
    });

    this.checkCollection(status.collection);
    this.checkDataSets(status.sets);
    this.checkColumnList(status.columnListTimeStamp, status.columns);
    this.checkViews(status.viewListTimeStamp, status.views);

    return this.checkColumns(currentDataShareColumns, status.columns);
  }

  checkSession(session) {
    if (this.session.id !== session.id) {
      this.session.id = session.id;
      this.session.desc = session.desc;
      this.client.emitSessionChanged(this.session);
    }
  }

  checkCollection(collection) {
    let changed = false;
    if (this.collection === null) {
      this.collection = {};
      changed = true;
    } else if (
      this.collection.isCollecting !== collection.isCollecting ||
      this.collection.canControl !== collection.canControl
    ) {
      changed = true;
    }

    if (changed) {
      this.collection.isCollecting = collection.isCollecting;
      this.collection.canControl = collection.canControl;
      this.client.emitCollectionChanged(this.collection);
    }
  }

  // Check for columns that have been added or removed
  // @columns: the columns object obtained from the DataShare source
  checkColumnList(columnListTimeStamp, rawColumns = {}) {
    const newRawColumns = cloneDeep(rawColumns);

    // has the list changed?
    if (this.columnListTimeStamp !== columnListTimeStamp) {
      this.columnListTimeStamp = columnListTimeStamp;

      // find columns that have been removed
      this.columns.forEach(column => {
        if (!newRawColumns[column.apiId]) {
          this.columns = this.columns.filter(col => col.id !== column.id);
          this.client.emitColumnRemoved(column);
          column.off(); // remove all bindings
        }
      });

      // LP columns in the status are sorted by their (randomly generated) ids
      // This wreaks havoc with the VST-GRID based table, because it creates its dataset
      // objects on demand as the columns arrive -- we need the columns to be at least in
      // the order of their respective datasets, so sort them
      Object.keys(newRawColumns)
        .sort((k1, k2) => {
          const rawColumn1 = newRawColumns[k1];
          const rawColumn2 = newRawColumns[k2];

          const ds1 = this.dataSets.find(dataSet => dataSet.id === rawColumn1.setId);
          const ds2 = this.dataSets.find(dataSet => dataSet.id === rawColumn2.setId);

          let diff = ds1.position - ds2.position;

          if (diff === 0) diff = rawColumn1.position - rawColumn2.position;

          return diff;
        })
        .forEach(id => {
          // look through the columns and add what's new
          const newRawColumn = newRawColumns[id];
          const dataShareColumn = this.columns.find(column => column.apiId === id);
          const isQueuedColumn = DataShareClient.checkColumnPending(id);

          // if the column is new, add it
          if (!dataShareColumn) {
            if (!isQueuedColumn) {
              const dataSetName = this.dataSets.find(
                dataSet => dataSet.id === newRawColumn.setId,
              )?.name;

              const dataShareColumn = new DataShareColumn({
                ...newRawColumn,
                ...{
                  apiId: id,
                  dataSetName,
                },
              });

              this.columns.push(dataShareColumn);
              this.client.emitColumnAdded(dataShareColumn);
            }
          } else {
            // don't update timestamps yet
            delete newRawColumn.liveValueTimeStamp;
            delete newRawColumn.valuesTimeStamp;
            delete newRawColumn.id;

            dataShareColumn.update(newRawColumn);
          }
        });
    } else {
      // update existing column properties
      Object.values(newRawColumns).forEach(newRawColumn => {
        const dataShareColumn = this.columns.find(column => column.id === newRawColumn.id);

        if (dataShareColumn) {
          // don't update timestamps yet
          delete newRawColumn.liveValueTimeStamp;
          delete newRawColumn.valuesTimeStamp;
          delete newRawColumn.id;

          dataShareColumn.update(newRawColumn);
        }
      });
    }
  }

  checkColumns(currentDataShareColumns, rawColumns) {
    const newRawColumns = cloneDeep(rawColumns);
    const columnPromises = [];

    // update columns
    Object.values(newRawColumns)
      .sort((a, b) => a.position - b.position)
      .forEach(newRawColumn => {
        const dataShareColumn = currentDataShareColumns.find(
          column => column.id === newRawColumn.id,
        );

        let updateLiveValue = false;
        let updateValues = false;

        if (dataShareColumn) {
          updateLiveValue = dataShareColumn.liveValueTimeStamp !== newRawColumn.liveValueTimeStamp;
          updateValues = dataShareColumn.valuesTimeStamp !== newRawColumn.valuesTimeStamp;
          updateValues = updateValues || dataShareColumn.valueCount !== newRawColumn.valueCount; // extra check

          // update timestamps in the existing column Model object
          dataShareColumn.update({
            liveValueTimeStamp: newRawColumn.liveValueTimeStamp,
            valuesTimeStamp: newRawColumn.valuesTimeStamp,
          });
        }

        if (updateLiveValue) {
          this.client.emitColumnLiveValueChanged(dataShareColumn, dataShareColumn.liveValue);
        }

        if (updateValues) {
          columnPromises.push(getColumnPromise(this.client, dataShareColumn));
        }
      });

    return Promise.all(columnPromises);
  }

  checkDataSets(rawDataSets) {
    const newRawDataSets = cloneDeep(rawDataSets);

    // find dataSets that have been removed
    this.dataSets.forEach(dataSet => {
      if (!rawDataSets[dataSet.apiId]) {
        this.dataSets = this.dataSets.filter(set => dataSet.id !== set.id);
        this.emitDataSetRemoved(dataSet);
        dataSet.off(); // remove all bindings
      }
    });

    // Datasets need to be added in the position order, so we generate a sorted
    // array of their keys, and then iterate that instead of iterating the
    // dictionary itself
    const rawDataSetKeys = Object.keys(newRawDataSets);
    rawDataSetKeys.sort((a, b) => {
      const A = newRawDataSets[a];
      const B = newRawDataSets[b];
      const pa = A.position || 0;
      const pb = B.position || 0;

      if (pa < pb) return -1;
      if (pa > pb) return 1;

      return 0;
    });

    // look through the dataSets to make updates and add what's new
    rawDataSetKeys.forEach(id => {
      const rawDataSet = newRawDataSets[id];
      const dataShareDataSet = this.dataSets.find(dataSet => dataSet.apiId === id);
      if (dataShareDataSet) {
        // update the data set if it already exists
        dataShareDataSet.update(rawDataSet);
      } else {
        // otherwise, add it anew
        const dataSet = new DataShareDataSet(rawDataSet);
        this.dataSets.push(dataSet);
        this.client.emitDataSetAdded(dataSet);
      }
    });
  }

  processViews(viewListTimeStamp, rawViews) {
    const newRawViews = cloneDeep(rawViews);

    // loop through the collection and find views that have been removed
    this.views.forEach(view => {
      if (!newRawViews[view.apiId]) {
        this.views = this.views.filter(_view => _view.id !== view.id);
        this.client.emitViewRemoved(view);
        view.off();
      }
    });

    const rawColumns = this.client.getColumns();

    // look through the views and add what's new
    Object.values(newRawViews).forEach(newRawView => {
      // We can't create/update a view, unless all the columns have
      // UDM backing, so check that is the case

      const baseId = newRawView.baseColumnId || newRawView.meterColumnId; // graph || meter

      let newBaseId;
      let neededCount;
      let nativeCount = 0;

      if (newRawView.viewType === 'graph') neededCount = newRawView?.leftTraceColumnIds?.length + 1;
      else if (newRawView.viewType === 'meter') neededCount = 1;
      else console.warn(`Unsuported view type: ${newRawView.viewType}`);

      rawColumns.forEach(rawColumn => {
        if (rawColumn.nativeId) {
          if (rawColumn.id === baseId) {
            nativeCount++;
            newBaseId = rawColumn.nativeId;
          }

          newRawView?.leftTraceColumnIds?.forEach(id => {
            if (rawColumn.id === id) {
              nativeCount++;
            }
          });
        }
      });

      if (newBaseId && nativeCount >= neededCount) {
        if (!this.views.find(view => view.id === newRawView.id)) {
          const view = new DataShareView(newRawView);

          this.views.push(view);
          this.client.emitViewAdded(view);
        } else {
          // otherwise, just update it
          this.views.find(view => view.id === newRawView.id)?.update(newRawView);
        }
      }
    });

    // Only update the timestamp if we have completely processed all the views
    this.viewListTimeStamp = viewListTimeStamp;
  }

  checkViews(viewListTimeStamp, rawViews) {
    if (this.viewListTimeStamp !== viewListTimeStamp) {
      this.processViews(viewListTimeStamp, rawViews);
    }
  }

  refreshViews() {
    this.processViews(this.viewListTimeStamp, this.status.views);
  }
}
