import EventEmitter from 'eventemitter3';

export class VSTAnalysisAPI extends EventEmitter {
  constructor(rpc) {
    super();
    this.rpc = rpc;
  }

  computeCurveFit(type, xVals, yVals, wVals, cVals, handle) {
    const params = {
      type,
      xVals, // double*
      yVals, // double*
      wVals, // double*
      cVals, // double*
      handle,
    };

    return this.rpc.sendRequest('vsta:compute-curve-fit', params);
  }

  calcFitValues(type, coeffs, xVals, handle) {
    const params = {
      type,
      coeffs,
      values: xVals,
      handle,
    };

    return this.rpc.sendRequest('vsta:calc-fit-values', params);
  }

  /**
   *
   * @param {Float64Array} xVals - Buffer containing the x input column data
   * @param {Float64Array} yVals - Buffer containing the y input column data
   * @param {boolean} removeLinear - remove a linear fit (reduce DC noise & correct for slope)
   * @param {string} filterType - Filter types: NONE, WINDOW_HANN, WINDOW_BARTLET, WINDOW_WELCH
   */
  computeFFT(xVals, yVals, removeLinear, filterType) {
    const params = {
      xVals,
      yVals,
      removeLinear,
      filterType,
    };

    return this.rpc.sendRequest('vsta:compute-fft', params);
  }

  /**
   * Compute a histogram calculation on passed in data.
   * @param {Float64Array} inputValues Buffer containing the input column data
   * @param {number} binWidth width of bins
   * @param {number} binStart starting position within the binWidth.
   * @returns {object} `bins` Float64Array containing x-values, `counts` Float64Array containing y-values for historgram calculation.
   */
  computeHistogram(inputValues, binWidth, binStart) {
    return this.rpc.sendRequest('vsta:compute-histogram', { inputValues, binWidth, binStart });
  }

  parseEquation(equation) {
    return this.rpc.sendRequest('vsta:parse-equation', equation);
  }

  releaseHandle(handle) {
    return this.rpc.sendRequest('vsta:release-handle', handle);
  }

  setUseRadians(useRadians) {
    return this.rpc.sendRequest('vsta:set-use-radians', useRadians);
  }

  enableLogging(enable) {
    return this.rpc.sendRequest('vsta:enable-logging', enable);
  }
}
