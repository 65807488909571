export const Provider = Base =>
  class extends Base {
    constructor() {
      super();

      this._services = new Map();

      this.addEventListener('request-service', event => {
        const { key } = event.detail;
        if (this._services.has(key)) {
          event.detail.$service = this._services.get(key);
          event.stopPropagation();
        }
      });
    }

    provideService(key, $service) {
      this._services.set(key, $service);
    }

    provideServices(services = {}) {
      Object.keys(services).forEach(key => this.provideService(key, services[key]));
    }
  };
