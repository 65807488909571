import { LitElement } from 'lit';

export class VstCoreExternalImport extends LitElement {
  static get properties() {
    return {
      progress: { type: Number, reflect: true },
      href: { type: String, reflect: true },
      loading: { type: Boolean, reflect: true },
      mimeType: { type: String, reflect: true },
      filename: { type: String, reflect: true },
      crossorigin: { type: Boolean, reflect: true },
      cached: { type: Boolean, reflect: true },
      method: { type: String, reflect: true },
    };
  }

  constructor() {
    super();
    this.progress = 0;
    this.href = '';
    this.loading = false;
    this.mimeType = '';
    this.filename = 'file';
    this.crossorigin = false;
    this.cached = false;
    this.method = 'GET';
  }

  async updated(changedProperties) {
    if (changedProperties.has('href')) {
      this.cached = 'caches' in window ? await caches.match(this.href) : false;
      this.filename = this.href.split('/').pop().replace(/\?.+/, '');
    }
    if (changedProperties.has('cached') && this.cached)
      this.dispatchEvent(
        new CustomEvent('external-import-cached', { composed: true, bubbles: true }),
      );
  }

  async triggerFetch() {
    try {
      this.dispatchEvent(new CustomEvent('download-start', { bubbles: true, composed: true }));
      this.loading = true;
      const progressResponse = await fetch(this.href, {
        method: this.method,
        mode: this.crossorigin ? 'cors' : undefined,
      });
      const loadResponse = progressResponse.clone();
      const reader = progressResponse.body.getReader();
      const contentLength = +progressResponse.headers.get('Content-Length');
      this.dispatchEvent(
        new CustomEvent('download-metadata', {
          detail: { contentLength },
          bubbles: true,
          composed: true,
        }),
      );
      let receivedLength = 0;
      const chunks = [];
      do {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) {
          this.loading = false;
        }
        if (value) {
          chunks.push(value);
          receivedLength += value.length;
          this.progress = receivedLength / contentLength;
          this.dispatchEvent(
            new CustomEvent('download-progress', {
              detail: { progress: this.progress, receivedLength },
              bubbles: true,
              composed: true,
            }),
          );
        }
      } while (this.loading);
      const chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (const chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }
      const blob = await loadResponse.blob();
      const file = new File([blob], this.filename, { type: this.mimeType });
      this.cached = 'caches' in window ? await caches.match(this.href) : false;
      this.dispatchEvent(
        new CustomEvent('download-finish', {
          detail: { file, url: this.href },
          bubbles: true,
          composed: true,
        }),
      );
      return true;
    } catch (error) {
      console.error(error);
      this.dispatchEvent(
        new CustomEvent('download-error', { detail: { error }, bubbles: true, composed: true }),
      );
      return false;
    }
  }
}
customElements.define('vst-core-external-import', VstCoreExternalImport);
