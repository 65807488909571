import { EventBinder } from '@utils/EventBinder.js';

let graphAutoLayouts = {};
let graphViews = {};
let meterViews = {};

const getLayout = graphLayouts => {
  const dsLayout = {};
  for (let i = 0; i < 3; ++i) {
    dsLayout[`graph_${i + 1}`] = i <= graphLayouts.length - 1;
  }
  return dsLayout;
};

const createMeter = (dataWorld, view, column) => {
  const meterInstance = dataWorld.createMeter({
    id: view.id,
    name: column.name,
    units: column.units,
    color: 'red',
  });

  return meterInstance;
};

export const addGraph = (dataWorld, eventBinder, view, client) => {
  const { connectLines, leftTraceColumnIds = [], rightTraceColumnIds = [] } = view;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line prefer-const
  let { drawLines, drawPoints, graphType = 'normal' } = view;
  const leftColumnIds = leftTraceColumnIds.map(id => client.getColumnUdmId(id));
  const rightColumnIds = rightTraceColumnIds.map(id => client.getColumnUdmId(id));

  // Logic for backwards compatibility:
  if (!drawLines || !drawPoints) {
    drawLines = connectLines;
    drawPoints = !connectLines;
  }

  graphViews[view.id] = view;

  const graphLayout = {
    baseColumnId: client.getColumnUdmId(view.baseColumnId),
    leftUnits: [],
    leftColumnIds,
    rightColumnIds,
    options: {
      title: view.title,
      appearance: {
        lines: drawLines,
        points: drawPoints,
        bars: graphType === 'bar-chart',
      },
      baseRange: { min: view.baseMin, max: view.baseMax },
      leftRange: {
        min: view.leftTraceMin,
        max: view.leftTraceMax,
      },
      rightRange: {
        min: view.rightTraceMin,
        max: view.rightTraceMax,
      },
      graphType,
    },
  };

  // bind to change events
  const bindChange = (key, cb) => {
    eventBinder.bind(view, `${key}-changed`, value => {
      cb(value);
      graphAutoLayouts[view.id] = graphLayout;
      const graphLayouts = Object.values(graphAutoLayouts);
      dataWorld.emit('data-share-view-changed', {
        graphLayouts,
        appLayout: getLayout(graphLayouts),
      });
    });
  };

  bindChange('title', title => {
    graphLayout.options.title = title;
  });

  bindChange('baseColumnId', baseColumnId => {
    const udmId = client.getColumnUdmId(baseColumnId);
    if (udmId) {
      graphLayout.baseColumnId = udmId;
    } else {
      console.warn('baseColID changed to column without UDM backing');
    }
  });

  bindChange('leftTraceColumnIds', leftTraceColumnIds => {
    // This event is triggered from DataShareProc.prototype.checkViews,
    // so the columnIds here are the foreign (DataShare) ids and have
    // to be translated to native ids.
    const leftColumnIds = leftTraceColumnIds.map(id => client.getColumnUdmId(id));

    graphLayout.leftColumnIds = leftColumnIds;
  });

  bindChange('right-trace-column-ids', rightTraceColumnIds => {
    graphLayout.rightColumnIds = rightTraceColumnIds?.map(id => client.getColumnUdmId(id));
  });

  bindChange('baseMin', min => {
    graphLayout.options.baseRange.min = min;
  });
  bindChange('baseMax', max => {
    graphLayout.options.baseRange.max = max;
  });

  bindChange('leftTraceMin', min => {
    graphLayout.options.leftRange.min = min;
  });
  bindChange('leftTraceMax', max => {
    graphLayout.options.leftRange.max = max;
  });

  bindChange('right-trace-min', min => {
    graphLayout.options.rightRange.min = min;
  });
  bindChange('right-trace-max', max => {
    graphLayout.options.rightRange.max = max;
  });

  bindChange('connectLines', connectLines => {
    graphLayout.options.appearance.lines = connectLines;
    graphLayout.options.appearance.points = !connectLines;
  });

  bindChange('trace-drawing-style', style => {
    graphLayout.options.appearance.lines = style.drawLines;
    graphLayout.options.appearance.points = style.drawPoints;
  });

  bindChange('graphType', newType => {
    graphLayout.options.graphType = newType;
    graphLayout.options.appearance.bars = newType === 'bar-chart';
  });

  graphAutoLayouts[view.id] = graphLayout;
  return Object.values(graphAutoLayouts);
};

export const removeGraph = view => {
  delete graphAutoLayouts[view.id];
  return Object.values(graphAutoLayouts);
};

export const initDataShareAutoLayout = (dataWorld, client) => {
  const eventBinder = new EventBinder();
  const columns = {};
  graphAutoLayouts = {};
  graphViews = {};
  meterViews = {};

  const _addMeter = view => {
    const meterColumn = columns[view.meterColumnId];
    const meterInstance = createMeter(dataWorld, view, meterColumn);
    meterViews[view.id] = { view, meterInstance };
    dataWorld.addMeter(meterInstance);
  };

  const _removeMeter = view => {
    delete meterViews[view.id];
    dataWorld.removeMeter(view.id);
  };

  const _addGraph = view => {
    const graphLayouts = addGraph(dataWorld, eventBinder, view, client);
    dataWorld.emit('data-share-view-changed', {
      graphLayouts,
      appLayout: getLayout(graphLayouts),
    });
  };

  const _removeGraph = view => {
    delete graphAutoLayouts[view.id];
    const graphLayouts = Object.values(graphAutoLayouts);
    dataWorld.emit('data-share-view-changed', {
      graphLayouts,
      appLayout: getLayout(graphLayouts),
    });
  };

  const _getMeterForColumn = columnId => {
    let meter = null;
    Object.keys(meterViews).forEach(meterID => {
      const curMeter = meterViews[meterID];
      if (meter) return;
      if (curMeter.view.meterColumnId === columnId) meter = curMeter;
    });

    return meter;
  };

  const clientEventHandlers = {
    'column-added': column => {
      columns[column.id] = column;

      eventBinder.bind(column, 'name-changed', name => {
        const meter = _getMeterForColumn(column.id);
        if (meter) {
          meter.meterInstance.name = name;
        }
      });
      eventBinder.bind(column, 'units-changed', units => {
        const meter = _getMeterForColumn(column.id);
        if (meter) meter.meterInstance.units = units;
      });
    },

    'column-removed': column => {
      delete columns[column.id];
    },

    'view-added': view => {
      const { viewType } = view;

      if (viewType === 'meter') _addMeter(view);
      else if (viewType === 'graph') _addGraph(view);

      client.proc.refreshViews(); // TODO: might not be nessessary
    },

    'view-removed': view => {
      const { viewType } = view;

      if (viewType === 'meter') _removeMeter(view);
      else if (viewType === 'graph') _removeGraph(view);
    },

    'column-live-value-changed': (column, value) => {
      const meter = _getMeterForColumn(column.id);
      if (meter) meter.meterInstance.value = value;
    },

    'udm-column-added': column => {
      const keys = Object.keys(graphAutoLayouts);

      // Check if this column is a base colum for one of our graphs; if
      // it is, fix up the graphLayout base column
      keys.forEach(id => {
        const graphLayout = graphAutoLayouts[id];
        const view = graphViews[id];
        if (view.baseColumnId === column.id) {
          graphLayout.baseColumnId = client.getColumnUdmId(column.id);
        }
      });

      client.proc.refreshViews();
    },
  };

  Object.keys(clientEventHandlers).forEach(eventName => {
    const handler = clientEventHandlers[eventName];
    eventBinder.bind(client, eventName, handler);
  });

  eventBinder.bind(dataWorld, 'before-session-ended', () => {
    Object.values(meterViews).forEach(({ meterInstance }) =>
      dataWorld.removeMeter(meterInstance.id),
    );
    meterViews = {};
  });
  return eventBinder;
};
