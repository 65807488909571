import EventEmitter from 'eventemitter3';
import { api } from '@services/datashare/api.js';
import { DataShareProc } from '@services/datashare/DataShareProc.js';

// Events:

// [ Connection Status ]
// 'connect'    : client connection established
// 'disconnect' : client connection disconnected
// 'error'      : client communication/API error

// 'server-info-changed'
// 'server-session-changed'
// 'server-collection-changed'

// 'column-added' / 'column-removed'
// 'column-values-changed'
// 'column-live-value-changed'

// var {services} = require('@common/services');

// var INFO_PATH   = '/info';

// var STATUS_PATH = '/status';
// var COLUMN_PATH = '/columns';

const DEFAULT_POLL_TIMEOUT_MS = 1000;

const State = {
  DISCONNECTED: 0, // poll Info -> CONNECTED, else ERROR
  CONNECTED: 1, // while(CONNECTED) poll status and columns, else ERROR
  ERROR: 2, // a request failed
};

let queuedColumns = [];

// ////////////////////////////////////////////////
// options:
// pollTimeoutMS (optional), poll timeout, defaults to 1000ms
export class DataShareClient extends EventEmitter {
  constructor(_http, options = {}) {
    super();
    let http = _http;
    let sourceName = null;

    this.usePollingTimer = true;

    if (!http) {
      throw new Error('No HTTP Module Specified');
    }

    this.pollWaiting = false;

    if (options.rtcClient) {
      const { rtcClient } = options;
      http = rtcClient.createHttpShim();
      this.usePollingTimer = false;
      // Set this flag to prevent polling until after the entire Data Sharing machinery is started up.
      this.pollWaiting = true;
      rtcClient.pollFunc = this._poll.bind(this);
      this.rtcClient = rtcClient;
    }

    if (Object.hasOwnProperty.call(options, 'sourceName')) {
      sourceName = options.sourceName;
    }

    this.serverURL = null;
    this.sourceName = sourceName;
    this.http = http;
    this.state = State.DISCONNECTED;
    this.pollTimer = null;
    this.stopPolling = false;

    this.proc = null; // API Processor

    this.pollTimeoutMS = options.pollTimeoutMS || DEFAULT_POLL_TIMEOUT_MS;
    queuedColumns = [];
  }

  /**
   * Replace the rtcClient with a new one.
   * @param {RTCDataShareClient} rtcClient replacement client.
   */
  replaceRtcClient(rtcClient) {
    this.http = rtcClient.createHttpShim();
    this.usePollingTimer = false;
    this.pollWaiting = false;
    rtcClient.pollFunc = this._poll.bind(this);
    this.rtcClient = rtcClient;
  }

  bind(name, func, ctx) {
    this.on(name, func.bind(ctx));
  }

  getColumns() {
    let columns = [];
    if (this.proc) {
      columns = this.proc.columns;
    }
    return columns;
  }

  getColumnApiId(columnId) {
    if (this.proc) {
      const column = this.proc.columns.find(column => column.id === columnId);
      if (column) {
        return column.apiId;
      }
    }

    return columnId;
  }

  getDataSets() {
    let dataSets = null;
    if (this.proc) {
      dataSets = this.proc.dataSets;
    }
    return dataSets;
  }

  getInfo() {
    const url = api.getInfoURL(this.serverURL);
    return this.http.getJSON(url);
  }

  getStatus() {
    const url = api.getStatusURL(this.serverURL);
    return this.http.getJSON(url, { timeout: 15000 });
  }

  getColumnById(columnId) {
    const url = api.getColumnURL(this.serverURL, this.getColumnApiId(columnId));
    return this.http.getJSON(url, { timeout: 20000 });
  }

  getColumnUdmId(columnId) {
    const columns = this.getColumns();
    let udmId;

    columns.some(col => {
      if (col.id === columnId) {
        udmId = col.nativeId;
        return true;
      }
      return false;
    });

    return udmId;
  }

  _poll() {
    // this is used to make detect stale requests
    const { proc } = this;
    let p = null;

    // we're waiting for promises to resolve
    if (this.pollWaiting) {
      return;
    }

    const onResolved = () => {
      this.pollWaiting = false;
    };

    const onError = e => {
      this.pollWaiting = false;
      this._onError(e);
    };

    if (this.state === State.DISCONNECTED) {
      // Query info API
      this.pollWaiting = true;
      this.getInfo()
        .then(info => {
          if (this._processInfo(info)) {
            this._setState(State.CONNECTED);
          }
          onResolved();
          // If we are web-rtc (no polling timer) we need to re-trigger another poll so we can fetch and process status.
          if (!this.usePollingTimer) this._poll();
        })
        .catch(e => {
          onError(e);
        });
    } else if (this.state === State.CONNECTED) {
      // Poll status API
      this.pollWaiting = true;
      this.getStatus()
        .then(status => {
          if (proc === this.proc) {
            p = this.proc.processStatus(status);
          }
        })
        .catch(e => {
          onError(e);
        });
    }

    if (p) {
      p.then(onResolved).catch(onError);
    } else {
      onResolved();
    }
  }

  _setState(state, err) {
    const checkTransition = (from, to) => this.state === from && state === to;

    if (checkTransition(State.CONNECTED, State.DISCONNECTED)) {
      this.emitDisconnect();
    } else if (
      checkTransition(State.DISCONNECTED, State.ERROR) ||
      checkTransition(State.CONNECTED, State.ERROR)
    ) {
      this.stopPolling = true;
      this.emitError(err || new Error('Connection Error'));
    } else if (
      checkTransition(State.DISCONNECTED, State.CONNECTED) ||
      checkTransition(State.ERROR, State.CONNECTED)
    ) {
      this.stopPolling = false;
      this.emitConnect();
    }

    this.state = state;
  }

  _onError(error) {
    this._setState(State.ERROR, error);
  }

  _processInfo(info) {
    let success = false;

    this.proc = DataShareProc.create(this, info);
    if (this.proc) {
      success = this.proc.processInfo(info);
    } else {
      throw new Error(`Unsupported API version: ${info.apiVers}`);
    }
    return success;
  }

  start(serverURL, restored) {
    return new Promise((resolve, reject) => {
      let pending = true; // has the promise resolved or rejected?

      this.stop()
        .then(() => {
          if (this.serverURL !== serverURL) {
            this.serverURL = serverURL;
            this.emit('server-url-changed', serverURL);
          }
          if (!restored) {
            this.getInfo()
              .then(() => {
                const pollFunc = () => {
                  this._poll();
                  if (this.usePollingTimer && !this.stopPolling) {
                    this.pollTimer = setTimeout(pollFunc, this.pollTimeoutMS);
                  }

                  if (pending) {
                    pending = false;
                    // if (this.state === State.CONNECTED) {
                    resolve();
                    // }
                    // else {
                    // reject(new Error('Failed to connect to: ' + serverURL));
                    // }
                  }
                };
                pollFunc();
              })
              .catch(error => {
                reject(error);
              });
          } else {
            this.emitRestored();
            resolve();
          }
        })
        .catch(() => {
          console.error('Failed to start Data Share Client');
          reject(new Error('Failed to start Data Share Client'));
        });
    });
  }

  stop() {
    return new Promise(resolve => {
      this.pollWaiting = false;
      if (this.proc) {
        this.proc.clear();
      }

      if (this.pollTimer) {
        clearTimeout(this.pollTimer);
        this.pollTimer = null;

        this._setState(State.DISCONNECTED);
        this.proc = null;

        resolve();
      } else {
        resolve();
      }
    });
  }

  requestStartCollection() {
    const url = api.getStartCollectionURL(this.serverURL);
    return this.http.getJSON(url);
  }

  requestStopCollection() {
    const url = api.getStopCollectionURL(this.serverURL);
    return this.http.getJSON(url);
  }

  static checkColumnPending(id) {
    return queuedColumns.includes(id);
  }

  static addPendingColumn(columnId) {
    queuedColumns.push(columnId);
  }

  static removePendingColumn(columnId) {
    queuedColumns = queuedColumns.filter(id => id !== columnId);
  }

  emitConnect() {
    this.emit('connect');
  }

  emitDisconnect() {
    this.emit('disconnect');
  }

  emitRestored() {
    this.emit('restored');
  }

  emitError(e) {
    this.emit('error', e);
  }

  // session { id: 'session-id', desc: 'description' }
  emitSessionChanged(session) {
    this.emit('server-session-changed', session);
  }

  // collection: {
  //  isCollecting: (bool)
  //  canControl: (bool)
  // }
  emitCollectionChanged(collection) {
    this.emit('server-collection-changed', collection);
  }

  emitColumnAdded(column) {
    this.emit('column-added', column);
    DataShareClient.addPendingColumn(column.id);
  }

  emitColumnRemoved(column) {
    this.emit('column-removed', column);
  }

  emitUdmColumnAdded(column) {
    this.emit('udm-column-added', column);
    DataShareClient.removePendingColumn(column.id);
  }

  emitDataSetAdded(dataSet) {
    this.emit('dataset-added', dataSet);
  }

  emitDataSetRemoved(dataSet) {
    this.emit('dataset-removed', dataSet);
  }

  emitColumnLiveValueChanged(column, value) {
    this.emit('column-live-value-changed', column, value);
  }

  emitViewAdded(view) {
    this.emit('view-added', view);
  }

  emitViewRemoved(view) {
    this.emit('view-removed', view);
  }
}
