import { LitElement, html } from 'lit';
import { spinnerStyles } from './vst-style-spinner.css.js';

export class VstStyleSpinner extends LitElement {
  static get styles() {
    return spinnerStyles;
  }

  render() {
    return html`
      <div class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    `;
  }
}
customElements.define('vst-style-spinner', VstStyleSpinner);
