export class UdmAPI {
  constructor(rpc) {
    /** @type {import('../adapters/rpc-message-channel/rpc.js').RPCMessageChannel} */
    this.rpc = rpc;
  }

  /**
   * Get Document Age
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise.<int>} Document age
   */
  async getDocumentAge(experimentId) {
    return this.rpc.sendRequest('udm:get-document-age', { experimentId });
  }

  /**
   * Fetches all of the block identifiers from the persistence store.
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise<String[]>} array of string block identifiers, empty if there are none.
   * @throws {Error} describes error condition.
   */
  getAllBlockIdentifiers(experimentId) {
    return this.rpc.sendRequest('udm:get-block-ids', { experimentId });
  }

  /**
   * Fetch a blob of information containing persisted block information
   * @param {number} experimentId - UDM id of the experiment
   * @param {String} blockId identifier we use to look up blocks in UDM.
   * @returns {Promise<String>} block info string.
   * @throws {Error} describes error condition.
   */
  async getInfoForBlockId(experimentId, blockId) {
    const result = await this.rpc.sendRequest('udm:get-block-info', { experimentId, blockId });
    return result.info;
  }

  /**
   * Add or update block info in UDM.
   * @param {number} experimentId - UDM id of the experiment
   * @param {String} blockId identifier for block
   * @param {String} info information to store for this block.
   * @returns {Promise} whose completion indicates operation complete.
   * @throws {Error} on error condition.
   */
  setInfoForBlockId(experimentId, blockId, info) {
    return this.rpc.sendRequest('udm:set-block-info', { experimentId, info, blockId });
  }

  /**
   * Remove block info from UDM store.
   * @param {number} experimentId - UDM id of the experiment
   * @param {String} blockId alpnumeric identifier.
   * @returns {Promise} on completion indicates completed operation.
   * @throws {Error} describes what went wrong.
   */
  removeInfoForBlockId(experimentId, blockId) {
    return this.rpc.sendRequest('udm:remove-block-info', { experimentId, blockId });
  }

  /**
   * Fetches all objects with a given type from the udm store.
   * @param {number} experimentId experiment from which to fetch.
   * @param {string} objectType one of the string enum values in Udm.objectTypes
   * @returns {Promise<Object[]>} array of objects suitable for importing into
   * observable stores.
   */
  fetchObjects(experimentId, objectType) {
    return this.rpc.sendRequest('udm:fetch-objects', { experimentId, objectType });
  }

  /**
   * Adds a new entry into the persistence store for a particular type of object
   * @param {number} experimentId experiment to add to.
   * @param {string} objectType one of the string enum values in Udm.objectTypes
   * @param {object} initialValues values suitable for this particular type of
   * object.
   * @returns {Promise<number>} udmId of the newly created store.
   */
  addObject(experimentId, objectType, initialValues) {
    return this.rpc.sendRequest('udm:add-object', { experimentId, objectType, ...initialValues });
  }

  /**
   * Removes an entry from the persistence store.
   * @param {number} experimentId experiment to remove from.
   * @param {number} id udmId of the store entry to remove.
   * @returns {Promise} indicating success or failure of the operation.
   */
  removeObject(experimentId, id) {
    return this.rpc.sendRequest('udm:remove-object', { experimentId, id });
  }

  /**
   * Updates an entry of a particular type with new values.
   * @param {number} experimentId experiment under update.
   * @param {number} id udmId of the store entry to update.
   * @param {objects} values appropriate for the type entry to update.
   * @returns {Promise} indicating success or failure of the operation.
   */
  updateObject(experimentId, id, values) {
    return this.rpc.sendRequest('udm:update-object', { experimentId, id, ...values });
  }
}
