import EventEmitter from 'eventemitter3';

export class SensorWorldAPI extends EventEmitter {
  constructor(rpc) {
    super();
    this.rpc = rpc;

    const RPC_METHODS = {};
    [
      'device-connection-changed',
      'sensor-added',
      'sensor-ready',
      'sensor-id-changed',
      'sensor-removed',
      'sensor-online',
      'sensor-offline',
      'sensor-live-voltage',
      'sensor-live-spectrum',
      'sensor-data',
      'sensor-property-change',
      'sensor-calibration-changed',
    ].forEach(id => {
      RPC_METHODS[id] = (server, notification) => {
        const { params } = notification;
        this.emit(id, params);
      };
    });

    this.rpc.registerMethods(RPC_METHODS, this);
  }

  /**
   * Sets native module to ignore sensors
   * Returns success Promise
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} ignoreSensors
   * @returns {Promise} Promise object represents a success or failure: { success: true|false }
   */
  setIgnoreAddedSensors(experimentId, ignoreSensors) {
    return this.rpc.sendRequest('sw:ignore-added-sensors', { experimentId, ignoreSensors });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} id
   * @param {string} unit
   */
  changeSensorUnit(experimentId, id, unit) {
    return this.rpc.sendRequest('sw:change-sensor-unit', {
      experimentId,
      id,
      unit,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {*} voltages
   * @param {*} references
   */
  calculateCalibrationCoeffs(experimentId, sensorId, voltages, references) {
    return this.rpc.sendRequest('calculate-calibration-coefficients', {
      experimentId,
      sensorId,
      voltages,
      references,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {*} coeffs
   */
  setCalibration(experimentId, sensorId, coeffs) {
    const calibration = {
      experimentId,
      sensorId,
      coeffs,
    };
    return this.rpc.sendRequest('set-sensor-calibration', calibration);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {*} wavelength
   */
  setSelectedWavelength(experimentId, sensorId, wavelength) {
    const params = {
      experimentId,
      sensorId,
      wavelength: wavelength || 0.0,
    };

    return this.rpc.sendRequest('set-sensor-selected-wavelength', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {*} curValue
   */
  zeroSensor(experimentId, sensorId, curValue) {
    return this.rpc.sendRequest('zero-sensor', {
      experimentId,
      sensorId,
      value: curValue,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   */
  reverseSensor(experimentId, sensorId) {
    return this.rpc.sendRequest('reverse-sensor', {
      experimentId,
      sensorId,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {boolean} enable
   */
  setSensorModeX4(experimentId, sensorId, enable) {
    return this.rpc.sendRequest('set-sensor-mode-x4', {
      experimentId,
      sensorId,
      enable,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   * @param {boolean} enable
   */
  setSensorZeroOnCollect(experimentId, sensorId, enable) {
    return this.rpc.sendRequest('set-sensor-zero-on-collect', {
      experimentId,
      sensorId,
      enable,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   */
  takeDarkReference(experimentId, sensorId) {
    return this.rpc.sendRequest('take-dark-reference', {
      experimentId,
      sensorId,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorId
   */
  calibrateSpectrometer(experimentId, sensorId) {
    return this.rpc.sendRequest('calibrate', { experimentId, sensorId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} sensorID
   * @param {*} params
   */
  setSpecSettings(experimentId, sensorID, params) {
    return this.rpc.sendRequest('set-spec-settings', {
      experimentId,
      sensorID,
      ...params,
    });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * Request the back-end to fake a soft usb device
   */
  enableSoftDevice(experimentId) {
    return this.rpc.sendRequest('enable-soft-device', { experimentId });
  }

  /**
   * Request the back-end to remove the soft usb device
   * @param {number} experimentId - UDM id of the experiment
   */
  disableSoftDevice(experimentId) {
    return this.rpc.sendRequest('disable-soft-device', { experimentId });
  }

  /**
   * Request the addition of a soft sensor associated with the soft device
   * This assumes the soft device has already been enabled
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   */
  enableSoftSignWaveSensor(experimentId, sensorTypeId) {
    return this.rpc.sendRequest('enable-soft-sine-wave-sensor', { experimentId, sensorTypeId });
  }

  /**
   * Request the addition of a soft sensor associated with the soft device
   * This assumes the soft device has already been enabled
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   * @param {Number} frequency frequency of the sensor sinusoid signal in Hz,
   * @param {Number} amplitude amplitude of the sensor sinusoid signal in V,
   * @param {Number} offset offset of the sesnor sinusoid signal in V
   * @returns {Promise}
   */
  enableAndConfigureSoftSignWaveSensor(experimentId, sensorTypeId, frequency, amplitude, offset) {
    return this.rpc.sendRequest('enable-and-configure-soft-sine-wave-sensor', {
      experimentId,
      sensorTypeId,
      frequency,
      amplitude,
      offset,
    });
  }

  /**
   * Request the removal of one of the active soft sensors matching sensorId
   * @param {number} experimentId - UDM id of the experiment
   * @param {Number} sensorTypeId non-zero integer id specifing the sensormap 'auto-id' of the sensor to emulate
   */
  disableSoftSignWaveSensor(experimentId, sensorTypeId) {
    return this.rpc.sendRequest('disable-soft-sine-wave-sensor', { experimentId, sensorTypeId });
  }

  /**
   * IA and SA apps only
   * Request the creation of soft sensors associated with the soft device
   * For IA this will be a soft Gas Chromatograph, for SA this will be a spec
   * This assumes the soft device has already been enabled
   * @param {number} experimentId - UDM id of the experiment
   */
  enableSpecialSoftSensor(experimentId) {
    return this.rpc.sendRequest('enable-soft-sensor', { experimentId });
  }

  /**
   * IA and SA apps only
   * Change configuration of the soft device
   * For IA this will be a soft Gas Chromatograph, for SA this will be a spec
   * @param {object} params
   * @param {Number} [params.experimentId] the experiment to add the sensor to
   * @param {Number} [params.scale] scale factor to apply to the data
   * @param {Number} [params.offset] offset to apply to the data
   * @param {Boolean} [params.levelOutput] whether to produce level output matching offset
   * @returns {Promise}
   */
  configureSpecialSoftSensor(
    params = { experimentId: 0, scale: 1, offset: 0, levelOutput: false },
  ) {
    return this.rpc.sendRequest('configure-soft-sensor', params);
  }

  /**
   * IA and SA apps only
   * Request the removal of one or more (?) soft sensors associated with the soft device
   * @param {number} experimentId - UDM id of the experiment
   */
  disableSpecialSoftSensor(experimentId) {
    return this.rpc.sendRequest('disable-soft-sensor', { experimentId });
  }

  /**
   * Add Soft device
   * @param {String} device order code
   * @returns {Promise} that resolves to the deviceId if successful.
   */
  addSoft2Device(orderCode) {
    return this.rpc.sendRequest('add-soft2-device', { orderCode });
  }

  /**
   * Remove Soft device
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  removeSoft2Device(deviceId) {
    return this.rpc.sendRequest('remove-soft2-device', { deviceId });
  }

  /**
   * Get soft device configuration
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  getSoft2DeviceConfig(deviceId) {
    return this.rpc.sendRequest('get-soft2-device-config', { deviceId });
  }

  /**
   * Set soft device configuration
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Object} config
   * @returns {Promise}
   */
  setSoft2DeviceConfig(deviceId, config) {
    return this.rpc.sendRequest('set-soft2-device-config', { deviceId, config });
  }

  /**
   * Add Soft sensor to a soft Labquest device
   * @param {String} deviceId as returned by addSoft2Device
   * @param {Number} channelNumber where to add the device (1-based)
   * @param {String} orderCode device order code; optional but either orderCode or sensorId must be given
   * @param {Number} sensorId sensor id; optional but either orderCode or sensorId must be given
   * @returns {Promise}
   */
  addSoft2Sensor(deviceId, channelNumber, orderCode = '', sensorId = 0) {
    return this.rpc.sendRequest('add-soft2-sensor', {
      deviceId,
      channelNumber,
      orderCode,
      sensorId,
    });
  }

  /**
   * Remove Soft sensor
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Number} channelNumber where to remove the device from (1-based)
   * @returns {Promise}
   */
  removeSoft2Sensor(deviceId, channelNumber) {
    return this.rpc.sendRequest('remove-soft2-sensor', { deviceId, channelNumber });
  }

  /**
   * Freeze Soft device
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @param {Number} atTimeCount when to freeze the device
   * @returns {Promise}
   */
  freezeSoft2Device(deviceId, atTimeCount) {
    return this.rpc.sendRequest('freeze-soft2-device', { deviceId, atTimeCount });
  }

  /**
   * Unfreeze Soft device
   * @param {String} deviceId as received from a previous call to addSoft2Device()
   * @returns {Promise}
   */
  unfreezeSoft2Device(deviceId) {
    return this.rpc.sendRequest('unfreeze-soft2-device', { deviceId });
  }

  /**
   * Initialize soft device interface
   * @returns {Promise}
   */
  initSoft2DeviceInterface() {
    return this.rpc.sendRequest('init-soft2-device-interface', {});
  }
}
