/* eslint-disable max-classes-per-file */
import { fitType } from '@services/analysis/api/fitType.js';

export class VSTAnalysisError extends Error {
  constructor(response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VSTAnalysisError);
    }

    this.response = response;
    this.name = `VSTAnalysisError: ${response.errorCode}`;
  }

  get errorCode() {
    return this.response.errorCode;
  }
}

export class VSTAnalysis {
  constructor(api) {
    this.api = api;
    this._useRadians = true;
  }

  // this is a special fix for the cos2 fit
  // this should probably get moved into vstanalysis ( vstanalysis::cosine_squared_cleanup )
  _fixCosSquared(result) {
    // Minimize the phase to preserve its sign and center it at 0
    // Special case for +/-180 degree GDX-POL data

    // if the phase (C) is outside the range +/- 90deg,
    // adjust the phase by 180deg until C is within that range
    const coeff = result.coefficients;
    let c = coeff[2];
    let t = this._useRadians ? Math.PI / 2 : 90;
    let f = this._useRadians ? Math.PI : 180;
    if (c < 0) {
      t = -t;
      f = -f;
    }
    while (Math.abs(c) > Math.abs(t)) {
      c -= f;
    }
    result.coefficients[2] = c;
  }

  async computeCurveFit(type, xVals, yVals, wVals, cVals, handle) {
    const response = await this.api.computeCurveFit(type, xVals, yVals, wVals, cVals, handle);

    if (response?.errorCode) {
      throw new VSTAnalysisError(response);
    }

    if (type === fitType.COSINE_SQUARED && response.coefficients) {
      this._fixCosSquared(response);
    }

    return response;
  }

  async calcFitValues(type, coeffs, xVals, handle = 0) {
    const response = await this.api.calcFitValues(type, coeffs, xVals, handle);
    if (response?.errorCode) {
      throw new VSTAnalysisError(response);
    }
    return response;
  }

  async computeFFT(xVals, yVals, removeLinear, filterType) {
    const response = await this.api.computeFFT(xVals, yVals, removeLinear, filterType);
    if (response?.errorCode) {
      throw new VSTAnalysisError(response);
    }
    return response;
  }

  async computeHistogram(inputValues, binWidth, binStart) {
    const response = await this.api.computeHistogram(inputValues, binWidth, binStart);
    if (response?.errorCode) {
      throw new VSTAnalysisError(response);
    }
    return response;
  }

  parseEquation(equation) {
    return this.api.parseEquation(equation);
  }

  releaseHandle(handle) {
    return this.api.releaseHandle(handle);
  }

  setUseRadians(useRadians) {
    this._useRadians = useRadians;
    return this.api.setUseRadians(useRadians);
  }

  enableLogging(enable) {
    return this.api.enableLogging(enable);
  }
}
