import { createUrlHandler } from '@services/adapters/url-handler/index.js';
import { VideoAnalysis } from './VideoAnalysis.js';
import { VideoAnalysisAPI } from './VideoAnalysisAPI.js';

export const initVaServices = async ({ dataWorld, rpc, videoUtils }) => {
  const vaAppService = new VideoAnalysis({
    api: new VideoAnalysisAPI(rpc),
    dataWorld,
    videoUtils,
  });
  const urlHandler = createUrlHandler();
  return {
    vaAppService,
    urlHandler,
  };
};
