import { makeAutoObservable, toJS } from 'mobx';
import { fitType } from '@common/services/analysis/api/fitType.js';

/**
 * @typedef {Object} ManualFitParams
 * @prop {number} graphId
 * @prop {number} baseColumnId
 * @prop {number} yColumnId
 * @prop {fitType} fitType
 * @prop {number[]} coefficients
 * @prop {number[]} uncertainties
 * @prop {number} rmse
 * @prop {number} correlation
 * @prop {boolean} showUncertainty
 * @prop {object} infoBox
 */

/**
 * @typedef {import(../services/api/DataWorldAPI.InfoBox)} InfoBox
 */

/**
 * @classdesc Manual fit model
 */
export class ManualFit {
  /** @type {number} unique udm identifier */
  id = 0;

  /** @type {fitType} */
  fitType = fitType.LINEAR;

  /** @type {string} */
  customEq = '';

  /** @type {number} */
  graphId = null;

  /** @type {number} base column ID */
  baseColumnId = null;

  /** @type {number} y column ID */
  yColumnId = null;

  /** @type {number[]} */
  coefficients = [];

  /** @type {number[]} */
  uncertainties = [];

  /** @type {number} */
  rmse = 0;

  /** @type {number} */
  correlation = 0;

  /** @type {boolean} */
  showUncertainty = false;

  /** @type {InfoBox} */
  infoBox = { x: 0, y: 0, isCollapsed: false };

  /**
   * @type {object} {min, max}. This is an unpersisted proerty.
   */
  range = { min: 0, max: 1 };

  /** @param {ManualFitParams} props */
  constructor(props) {
    makeAutoObservable(this, { id: false });
    Object.assign(this, props);
  }

  /**
   * @type {Object} an object suitable for storing and retrieving from
   * the persistence store.
   */
  get udmExport() {
    return toJS(this);
  }

  /**
   * Set statistics.
   * @param {object} params
   * @param {number} params.rmse
   * @param {number} params.correlation
   */
  setStats({ rmse, correlation }) {
    if (rmse !== undefined) this.rmse = rmse;
    if (correlation !== undefined) this.correlation = correlation;
  }
}
