export const eventHandlers = {
  'settings-changed': function settingsChanged(params) {
    this._setCollectionParams(params);
  },

  'rolling-counter-error': function rollingCounterError(params) {
    const { timestamp } = params;
    const message = `Rolling counter error detected at time: ${timestamp}`;

    // only propagate this message if the timestamp is >= 0 (ie during a collection)
    if (typeof timestamp === 'number' && timestamp >= 0) {
      this.emit('device-error', message);
    }
  },

  'device-error': function deviceError(params) {
    console.error('Device Error:');
    console.error(params);

    const errorCodes = {
      BLE_CONNECTION_ERROR: 7,
    };

    if (params.errorCode === errorCodes.BLE_CONNECTION_ERROR) {
      this.emit('ble-communication-error', params);
    } else {
      this.emit('device-error', params);
    }
  },

  'device-sensor-enum-completed': function deviceSensorEnumCompleted(params) {
    this.emit('device-sensor-enum-completed', params);
  },

  /**
   * @param {Object} params
   * @param {number} params.experimentId
   * @param {Object} params.values - object of columnId/value pairs
   */
  'keep-values-ready': function keyValuesReady(params) {
    const { values } = params;
    this.emit('keep-values-ready', values);
  },
};
