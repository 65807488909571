import { fileOpen /* , directoryOpen, fileSave, supported */ } from 'browser-fs-access';

export const showOpenDialog = async function showOpenDialog(accepts) {
  const options = accepts.map(({ extensions, mimeTypes, name }) => ({
    description: name,
    extensions: extensions.map(extension => `.${extension}`),
    mimeTypes,
  }));
  const file = await fileOpen(options);

  return { file, filepath: file.path || file.name };
};
