import { BlockInfo } from '../mobx-stores/BlockInfo';

export class Udm {
  constructor({ api = null }) {
    /** @type {import('./api/UdmAPI.js').UdmAPI} */
    this.api = api;
  }

  /**
   * @enum {string} udm object types currently supported by the generic object
   * vendor RPCs.
   */
  objectTypes = Object.freeze({
    MANUAL_FIT: 'manual-fit',
    MEASUREMENT_TOOL: 'measurement-tool',
  });

  /**
   * Get Document Age
   * @returns {Promise.<int>} Document age
   */
  async getDocumentAge(experimentId) {
    return this.api.getDocumentAge(experimentId);
  }

  /**
   * Fetch all block identifiers in the persistence store.
   * @returns {Promise<String[]>} list of block id strings.
   */
  getAllBlockIdentifiers(experimentId) {
    return this.api.getAllBlockIdentifiers(experimentId);
  }

  /**
   * Remove block info from UDM store.
   * @param {String} blockId alpnumeric identifier.
   * @returns {Promise} on completion indicates completed operation.
   * @throws {Error} describes what went wrong.
   */
  removeInfoForBlockId(experimentId, blockId) {
    return this.api.removeInfoForBlockId(experimentId, blockId);
  }

  /**
   * Set the block ID for this application. If set, we know that the application instance we are running in is a Connections Block.
   * @param {String} id identifier for the block, possibly obtained from the URL that prompted the instantiation of this application.
   * @param {import('../mobx-stores/BlockInfo').BlockInfoConfig} defaultInfo contains block info settings to be used in case we cannot retrieve block info from the store (e.g this ia new block).
   */
  async setBlockId(experimentId, id, defaultInfo = {}) {
    this._blockId = id;
    try {
      const infoString = await this.api.getInfoForBlockId(experimentId, id);
      this._blockInfo = new BlockInfo(JSON.parse(infoString));
      this._blockInfo.rehydrated = true;
    } catch (err) {
      // In case of error, use the passed-in info.
      console.warn(err);
      this._blockInfo = new BlockInfo(defaultInfo);
      // Add this block info to the store.
      this.api.setInfoForBlockId(experimentId, id, JSON.stringify(defaultInfo));
    }

    // Monitor changes on the block info. If any fields are changed, we'll submit the exported infoBlob to the back end.
    this._blockInfo.addObserver(async infoBlob => {
      try {
        await this.api.setInfoForBlockId(experimentId, this._blockId, JSON.stringify(infoBlob));
      } catch (error) {
        console.error(error);
      }
    });
    return this._blockInfo;
  }

  /**
   * @returns {String} block ID or 0 if this is not a block.
   */
  get blockId() {
    return this._blockId || 0;
  }

  /**
   * @returns {Boolean} true if this application is running in a Block, false if it's just a standalone app.
   */
  get isBlock() {
    return !!this._blockId;
  }

  /**
   * @returns {BlockInfo} block info object containing the view ids etc. required to set up the block.
   */
  get blockInfo() {
    return this._blockInfo;
  }

  /**
   * Fetches all objects with a given type from the udm store.
   * @param {number} experimentId experiment from which to fetch.
   * @param {string} objectType one of the string enum values in Udm.objectTypes
   * @returns {Promise<Object[]>} array of objects suitable for importing into
   * observable stores.
   */
  fetchObjects(experimentId, objectType) {
    return this.api.fetchObjects(experimentId, objectType);
  }

  /**
   * Adds a new entry into the persistence store for a particular type of object
   * @param {number} experimentId experiment to add to.
   * @param {string} objectType one of the string enum values in Udm.objectTypes
   * @param {object} initialValues values suitable for this particular type of
   * object.
   * @returns {Promise<number>} udmId of the newly created store.
   */
  addObject(experimentId, objectType, initialValues) {
    return this.api.addObject(experimentId, objectType, initialValues);
  }

  /**
   * Removes an entry from the persistence store.
   * @param {number} experimentId experiment to remove from.
   * @param {number} id udmId of the store entry to remove.
   * @returns {Promise} indicating success or failure of the operation.
   */
  removeObject(experimentId, id) {
    return this.api.removeObject(experimentId, id);
  }

  /**
   * Updates an entry of a particular type with new values.
   * @param {number} experimentId experiment under update.
   * @param {number} id udmId of the store entry to update.
   * @param {objects} values appropriate for the type entry to update.
   * @returns {Promise} indicating success or failure of the operation.
   */
  updateObject(experimentId, id, values) {
    return this.api.updateObject(experimentId, id, values);
  }
}
