import * as platform from './PLATFORM_ID';

export const platformReady = async () => {
  try {
    if (platform.platformReady) {
      await platform.platformReady();
    } else {
      return true;
    }
  } catch (err) {
    console.warn(err);
  }

  return true;
};
