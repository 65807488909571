import EventEmitter from 'eventemitter3';
import { UdmArchive } from '@utils/UdmArchive.js';
import { decimalPlaces } from '@utils/formatter.js';
import { CenterOfMass } from './CenterOfMass.js';

function toUdmId(id) {
  // same as Math.floor(id)
  return ~~id; // eslint-disable-line
}

export class VideoAnalysis extends EventEmitter {
  constructor({ api, dataWorld, videoUtils }) {
    super();
    this.dataWorld = dataWorld;
    this.dataWorld.videoAttachmentImportHook = this.videoAttachmentImportHook.bind(this);
    this.api = api;
    this.videoUtils = videoUtils;
    this._centerOfMass = new CenterOfMass({ videoAnalysis: this, api, dataWorld });
    this._existingTimeStamps = null; // UDM imported timestamp workaround.  See: _getUdmTimeStamp()

    this._frameRate = 29.97;

    dataWorld.on('session-ended', () => {
      this._centerOfMass.resetTransform();
      this.scaleFactor = 1;
      this.rotationAngle = 0;
      this._existingTimeStamps = null;
    });

    dataWorld.on('session-started', params => {
      this._fileFormat = params.fileFormat;
      console.log(`VA: UDM file format ${this._fileFormat}`); // eslint-disable-line no-console
    });

    (async () => {
      this.dataWorld.archive = await UdmArchive.create({
        maxDBSize: 2 * 1024 * 1024 * 1024, // example usage (5 MB): 5 * 1024 * 1024,
        leaveAfterPrune: 20,
      });
    })();
  }

  validateJWT = token => this.api.validateJWT(token);

  async readMetaData(db, filename) {
    const metadata = await this.videoUtils.readMetaData(db, filename);

    // Use average frame rate, as that is the only rate that can be used to convert time to frame numbers in
    // a linear fashion; if not included, fallback on the default and log an error -- we want to know if we
    // encounter a video that doesn't have an average rate in metadata.
    if (metadata.avgFrameRate) {
      this._frameRate = metadata.avgFrameRate;
    } else {
      console.error('video metadata does not include average rate!');
    }

    // There are some videos (namely mp4 created by ChromeOS) that cause metadata.numFrames
    // to come back with an incorrect value.
    // Here we attempt to detect those files by checking that the computed number of frames is within
    // the a few frames metadata.numFrames.
    // The reason we don't just compute this every time is because sometimes we're off
    // by a frame from the actual number of frames by computing it this way.
    const computedNumFrames = Math.floor(this._frameRate * metadata.duration);
    if (Math.abs(metadata.numFrames - computedNumFrames) > 5) {
      metadata.numFrames = computedNumFrames;
    }

    return metadata;
  }

  async setVideoProperties(file, params = {}) {
    const sessionType = 'DataCollection';
    const sessionTypeName = 'time-based';
    const sessionConfig = { type: sessionTypeName };
    // TODO: be smart about not starting a new session the first time the app loads
    await this.dataWorld.startNewSession(sessionType, sessionConfig);
    // We only allow single video, so clear any others from the archive first
    await this.dataWorld.archive.clearAttachments('videos');
    const { archPath, fileContents } = await this.dataWorld.archive.addAttachment('videos', file);
    // Finally, record the video in the UDM xml doc
    const _params = { ...params };

    // Enforce numericity of object ids
    if (params.videoId) {
      _params.videoId = toUdmId(params.videoId);
    }

    if (params.datasetId) {
      _params.datasetId = toUdmId(params.datasetId);
    }
    if (params.baseColumnId) {
      _params.baseColumnId = toUdmId(params.baseColumnId);
    }

    if (params.graphId) {
      _params.graphId = toUdmId(params.graphId);
    }

    _params.originX = -1;
    _params.originY = -1;

    // Pass through the full path of the attachement in the archive
    _params.archivePath = archPath;

    const metadata = await this.readMetaData(
      UdmArchive.dataBaseName,
      this.dataWorld.archive.archivePaths[0],
    );

    if (metadata.realFrameRate) {
      _params.frameRate = metadata.realFrameRate;
    }

    // NB: when this returns, the XML doc is dirty
    const videoId = await this.api.setVideoProperties(this.dataWorld.experimentId, _params);
    this.dataWorld.archive.setAttachmentId(archPath, videoId);
    const blob = new Blob([fileContents], { type: 'video/mp4' });

    this.emit('video-added', {
      blob,
      metadata,
      dbName: UdmArchive.dataBaseName,
      srcPath: `${this.dataWorld.archive.name}/${archPath}`,
    });

    return { metadata, videoId };
  }

  setTextStrings(strings) {
    return this.api.setTextStrings(this.dataWorld.experimentId, strings);
  }

  createObject(nameSuffix) {
    return this.api.createObject(this.dataWorld.experimentId, nameSuffix);
  }

  // note: the values array gets modified
  // values is an array of 3-tuple arrays
  // [ [timeStamp, x, y ], ...]
  updateObjectValues(objectId, values) {
    values.forEach(tuple => {
      tuple[0] = this._getUdmTimeStamp(tuple[0]);
    });
    return this.api.updateObjectValues(this.dataWorld.experimentId, objectId, values);
  }

  async updateObjectPoint(objectId, frame, x, y) {
    const timeStamp = this._frameToTimeStamp(frame);
    const values = [[timeStamp, x, y]];
    await this.updateObjectValues(objectId, values);
  }

  updateObjectProperties(objectId, props) {
    return this.api.updateObjectProperties(this.dataWorld.experimentId, objectId, props);
  }

  // direction: 'x' or 'y'
  getObjectPositionColumn(objectId, direction) {
    const column = this.dataWorld
      .getColumns()
      .find(
        c => (objectId ? c.objectId === objectId : c.objectId !== 0) && c.direction === direction,
      );
    return column;
  }

  getObjectColumns(objectId) {
    const cols = {
      xPos: this.getObjectPositionColumn(objectId, 'x'),
      yPos: this.getObjectPositionColumn(objectId, 'y'),
    };

    if (cols.xPos && cols.yPos) {
      this.dataWorld.getColumns().forEach(c => {
        const { type, group } = c;
        if (group && type === 'calc') {
          // velocity
          if (group.calcEquation === 'derivative') {
            if (group.calcDependentGroups.includes(cols.xPos.groupId)) {
              cols.xVel = c;
            } else if (group.calcDependentGroups.includes(cols.yPos.groupId)) {
              cols.yVel = c;
            }
          } else if (group.calcEquation === 'secondDerivative') {
            if (group.calcDependentGroups.includes(cols.xPos.groupId)) {
              cols.xAcc = c;
            } else if (group.calcDependentGroups.includes(cols.yPos.groupId)) {
              cols.yAcc = c;
            }
          }
        }
      });
    }

    return cols;
  }

  setVideoScaleUnits(units) {
    return this.api.setVideoScaleUnits(this.dataWorld.experimentId, units);
  }

  setVideoTrimRange(startFrame, endFrame) {
    return this.api.setVideoTrimRange(this.dataWorld.experimentId, startFrame, endFrame);
  }

  setVideoVisibility(visible) {
    return this.api.setVideoVisibility(this.dataWorld.experimentId, visible);
  }

  async setVideoScale(params = {}, importing = false) {
    const calculateScale = args => {
      const { scaleStartX, scaleStartY, scaleEndX, scaleEndY, scaleDistance } = args;

      if (!scaleDistance || !scaleStartX || !scaleStartY || !scaleEndX || !scaleEndY) {
        console.warn('VA service: setVideoScale() missing parameters. Defaulting to 1.0');
        return 1;
      }

      // get length of scale tool in pixels using pythagorean thm.
      let x2 = scaleEndX - scaleStartX;
      x2 *= x2;

      let y2 = scaleEndY - scaleStartY;
      y2 *= y2;

      const denominator = Math.sqrt(x2 + y2);

      if (denominator) {
        return scaleDistance / denominator;
      }

      console.warn('VA service: setVideoScale() cannot compute scale. Defaulting to 1.0');
      return 1;
    };

    try {
      if (!importing) {
        this.scaleFactor = await this.api.setVideoScale(this.dataWorld.experimentId, params);
      } else {
        this.scaleFactor = calculateScale(params);
      }
    } catch (e) {
      console.error(e);
    }
  }

  setSelectedFrame(selectedFrame) {
    this.api.setSelectedFrame(this.dataWorld.experimentId, selectedFrame);
  }

  setFrameAdvanceRate(rate) {
    this.api.setFrameAdvanceRate(this.dataWorld.experimentId, rate);
  }

  setFrameRateMultiplier(frameRateMultiplier) {
    this.api.setFrameRateMultiplier(this.dataWorld.experimentId, frameRateMultiplier);
  }

  setVideoOrigin(originX, originY) {
    this._centerOfMass.setVideoOrigin(originX, originY);
    this.api.setVideoOrigin(this.dataWorld.experimentId, originX, originY);
  }

  setVideoAxisRotation(angle) {
    this.rotationAngle = angle;
    this.api.setVideoAxisRotation(this.dataWorld.experimentId, angle);
  }

  setVideoShowTrails(showTrails) {
    this.api.setVideoShowTrails(this.dataWorld.experimentId, showTrails);
  }

  /**
   *
   * @param {number} [datasetId=currentDataSet.id]
   */
  async getFrames(datasetId = this.dataWorld.currentDataSet.id) {
    const { frameTimes } = await this.api.getFrameTimes(this.dataWorld.experimentId, datasetId);
    const frames = frameTimes.map(frameTime => Math.round(frameTime / (1 / this._frameRate)) + 1); // account for zero frame
    return frames;
  }

  getObjectSourcePixels(objectId) {
    return this.api.getObjectSourcePixels(this.dataWorld.experimentId, objectId);
  }

  changeVideoObjectTraceColor(objectId, color) {
    return this.api.changeVideoObjectTraceColor(this.dataWorld.experimentId, objectId, color);
  }

  enableVideoDataReplay() {
    return this.api.enableVideoDataReplay(this.dataWorld.experimentId);
  }

  disableVideoDataReplay() {
    return this.api.disableVideoDataReplay(this.dataWorld.experimentId);
  }

  setVideoReplayTimestamp(timestamp) {
    return this.api.setVideoReplayTimestamp(this.dataWorld.experimentId, timestamp);
  }

  removePoint(objectId, frame) {
    const timeStamp = this._frameToTimeStamp(frame);
    const udmTimeStamp = this._getUdmTimeStamp(timeStamp);

    return this.api.removePoint(this.dataWorld.experimentId, objectId, udmTimeStamp);
  }

  setCoordinateMode(mode) {
    return this.api.setCoordinateMode(this.dataWorld.experimentId, mode);
  }

  convertToVideoAbsoluteTime(adjustedTime) {
    return this.api.convertVideoTime(this.dataWorld.experimentId, true, adjustedTime);
  }

  convertToVideoAdjustedTime(absoluteTime) {
    return this.api.convertVideoTime(this.dataWorld.experimentId, false, absoluteTime);
  }

  toPixels(x, y) {
    const point = {};

    const { rotationAngle, scaleFactor } = this;
    const cosAngle = Math.cos(rotationAngle || 0);
    const sinAngle = Math.sin(rotationAngle || 0);

    // scaleFactor is the unit scale and is -1 if not set, so we Math.abs it to treat it as 1/1 px/px scale (which it is)
    const pixelToUnitScaleFactor = Math.abs(scaleFactor);

    // apply inverse rotation matrix
    point.x = (cosAngle * x + sinAngle * y) / pixelToUnitScaleFactor;
    point.y = (-sinAngle * x + cosAngle * y) / pixelToUnitScaleFactor;

    return point;
  }

  async enableCenterOfMassColumns(enable) {
    return this._centerOfMass.enableColumns(enable);
  }

  async enableAccelerationColumns(enable) {
    return this.api.enableAccelerationColumns(this.dataWorld.experimentId, enable);
  }

  async setVectorDisplay(vectorDisplay) {
    return this.api.setVectorDisplay(this.dataWorld.experimentId, vectorDisplay);
  }

  async hydrateFromFile(importedState) {
    if (this._fileFormat < 1) {
      const result = await this.api.getFrameTimes(
        this.dataWorld.experimentId,
        this.dataWorld.currentDataSet.id,
      );
      this._existingTimeStamps = result.frameTimes;
    }

    for (const prop of ['rotationAngle', 'originX', 'originY']) this[prop] = importedState[prop];
    await this._hydrateCenterOfMass(importedState);
  }

  async _hydrateCenterOfMass(importedState) {
    this._centerOfMass.setVideoOrigin(importedState.originX, importedState.originY);
    const isImporting = true;
    await this.setVideoScale(importedState, isImporting);
    if (importedState.centerOfMass.enabled) {
      await this._centerOfMass.setupColumns(importedState.centerOfMass);
    }
  }

  async videoAttachmentImportHook(blob) {
    const DBNAME = UdmArchive.dataBaseName;
    const metadata = await this.readMetaData(DBNAME, this.dataWorld.archive.archivePaths[0]);
    this.emit('video-added', {
      blob,
      metadata,
      dbName: DBNAME,
      srcPath: this.dataWorld.archive.archivePaths[0],
    });
  }

  async createTrackingContext(x, y, radius) {
    return this.videoUtils.createTrackingContext(x, y, radius);
  }

  async destroyTrackingContext(trackingCtxId) {
    return this.videoUtils.destroyTrackingContext(trackingCtxId);
  }

  async trackObjectNextFrame(trackingCtxId, frame, width, height, pixels) {
    const timeStamp = this._frameToTimeStamp(frame);
    return this.videoUtils.trackObjectNextFrame(trackingCtxId, timeStamp, width, height, pixels);
  }

  // frame is assumed to be 1-based.  :-(
  _frameToTimeStamp(frame) {
    if (this._fileFormat > 0) {
      return (frame - 1) / this._frameRate;
    }
    return decimalPlaces((frame - 1) / this._frameRate, 12); // this matches the UDM %12 formatting prior to format 1
  }

  // Get a timestamp valid to send to UDM
  //
  // This is a workaround for a bug in UDM which saves the timestamp values
  // as a string formatted with printf("%.12g") and therefore loses some precision.
  //
  // If you load a file, add a new object and then add a new point at an
  // existing frame (ie. the table has a row for that frame/timestamp already),
  // you get a new row for that frame time in the table.
  //
  // This happens due to the mismatch in precision between the front-end calculated
  // frame time and the imported time value in the table.
  //
  // This workaround was devised to first attempt to match the front-end calculated
  // timestamp against an existing set of timestamps.
  // Otherwise, just use the timestamp as is.
  //
  // This workaround is only required for fileFormat version < 1; as of version
  // 1 UDM stores all doubles with a full 17 digit precision.
  _getUdmTimeStamp(frameTimeStamp) {
    // try and find an existing timestamp for the given frame time
    if (this._existingTimeStamps) {
      for (let i = 0; i < this._existingTimeStamps.length; ++i) {
        const t = this._existingTimeStamps[i];
        if (Math.abs(t - frameTimeStamp) < 0.00000000001) {
          frameTimeStamp = t; // eslint-disable-line no-param-reassign
          break;
        }
      }
    }
    return frameTimeStamp;
  }
}
