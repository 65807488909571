import { getText } from '@utils/i18n.js';

const { cordova } = window;

export const readFile = filepath => {
  console.assert(filepath);
  return new Promise((resolve, reject) => {
    const fileHandle = window.resolveLocalFileSystemURL(filepath);

    fileHandle.file(file => {
      const reader = new FileReader();

      reader.onloadend = () => {
        console.log(`Successful file read: ${reader.result}`);
        resolve({
          file: reader.result,
          filepath,
        });
      };

      reader.readAsText(file);
    }, reject);
  });
};

export const showOpenDialog = async filters => {
  // Android no longer requires permissions to read files since API 29
  if (window.cordova.platformId !== 'android')
    await new Promise((resolve, reject) => {
      cordova.plugins.permissions.requestPermission(
        cordova.plugins.permissions.READ_EXTERNAL_STORAGE,
        status => {
          if (status.hasPermission) {
            resolve();
          } else {
            reject(new Error('User denied Read permission'));
          }
        },
      );
    });

  return new Promise((resolve, reject) => {
    filters.forEach(item => {
      item.description = item.name;
      delete item.name;
    });

    window.nativeFileSystem.chooseEntry(
      {
        type: 'openFile',
        accepts: filters,
      },
      result => {
        const url = result.uri;
        window.resolveLocalFileSystemURL(url, fileHandle => {
          fileHandle.file(
            file => {
              file.name = result.fileName || file.name;
              resolve({
                file,
                filepath: fileHandle.nativeURL,
              });
            },
            err => {
              reject(err);
            },
          );
        });
      },
    );
  });
};

/**
 * Creates a new file in the device's termporary storage directory. Cordova
 * only.
 * @param {string} filename file name for the new temporary file.
 * @param {Blob} blob file contents.
 * @param {function(string)} completion called on successful file write with a
 * url string pointing at the new temporary file.
 * @param {function(Error)} error called if there's a failure.
 */
export function writeTemporaryFile(filename, blob, completion = () => {}, error = () => {}) {
  window.requestFileSystem(LocalFileSystem.TEMPORARY, 0, fs => {
    fs.root.getFile(filename, { create: true, exclusive: false }, fileEntry => {
      fileEntry.createWriter(fileWriter => {
        fileWriter.onwriteend = () => {
          completion(fileEntry.nativeURL);
        };

        fileWriter.onerror = e => {
          error(e);
        };

        fileWriter.write(blob);
      });
    });
  });
}

/**
 * Displays a `prompt` dialog box allowing the user to enter a filename for
 * saving or sharing. This is used on iOS save / save-as, and on both iOS and
 * Android share.
 * @param {object} params
 * @param {string} params.suggestedName a default name for the file which the
 * user may modify.
 * @param {string} [params.title] title for the dialog box
 * @param {string} [params.inputDescription] describes the input field.
 * @param {string} [params.buttonString] title for the 'Save' button.
 * @param {EventTarget} [dispatcher]
 * @returns {Promise<string|undefined>} resolves to a file name in a string or
 * undefined if user cancels.
 */
export function chooseFileName(params, dispatcher = document.querySelector('#app')) {
  const {
    suggestedName,
    title = getText('Save Experiment'),
    inputDescription = getText('Experiment Name'),
    buttonString = getText('Save'),
  } = params;

  const closeDialog = () => {
    dispatcher.dispatchEvent(
      new CustomEvent('close-dialog', {
        bubbles: true,
        composed: true,
        detail: 'prompt',
      }),
    );
  };

  return new Promise(resolve => {
    dispatcher.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'prompt',
          params: {
            title,
            inputValue: suggestedName,
            inputDescription,
            buttonString,
            onSubmit: ({ detail: filename }) => {
              resolve(filename);
              closeDialog();
            },
            onCancel: () => {
              resolve(undefined);
              closeDialog();
            },
          },
          onClose: () => {
            resolve(undefined);
          },
        },
      }),
    );
  });
}
