import { EventBinder } from '@utils/EventBinder.js';

let eventBinder;

async function _bindEvents(opts, services) {
  const { dataWorld, sensorWorld, dataCollection } = services;

  function updateControlAndKeep(checkCollect) {
    const { mode } = dataCollection;
    let canCollect = true;
    let canKeep = false;

    if (mode.match('events')) {
      canKeep = true;
    } else if (
      (mode.match('drop-counting') && sensorWorld.sensors.length < 2) ||
      dataWorld.sessionType === 'ManualEntry'
    ) {
      canCollect = false;
    }

    if (checkCollect) {
      if (sensorWorld.ignoreSensors || sensorWorld.sensors.length < 1) {
        canCollect = false;
      }
      dataWorld.emit('collection-control-changed', canCollect);
      dataWorld.canCollect = canCollect;
    }
    dataWorld.emit('collection-keep-changed', canKeep);
  }

  eventBinder = new EventBinder();

  const _resetTimeUnits = () => {
    if (dataWorld.isSessionEmpty && dataCollection.timeUnits !== 's') {
      dataCollection.timeUnits = 's';
    }
  };

  updateControlAndKeep(true);

  if (opts && (opts.restored || opts.imported)) {
    updateControlAndKeep();
  }

  eventBinder.on(sensorWorld, 'sensor-added', () => {
    updateControlAndKeep(true);
    _resetTimeUnits();
  });

  eventBinder.on(sensorWorld, 'sensor-removed', () => {
    updateControlAndKeep(true);
    _resetTimeUnits();
  });

  eventBinder.on(dataCollection, 'collection-mode-changed', mode => {
    const withinEvents = mode.from.match('events') && mode.to.match('events');
    updateControlAndKeep(!withinEvents);
  });
}

async function init(opts, services) {
  await _bindEvents(opts, services);
}

function uninit() {
  eventBinder.unbindAll();
}

export const modeLayoutManager = {
  init,
  uninit,
};
