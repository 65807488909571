export function createPromiseSequence() {
  let rawSequence = {};

  // add a promise with a given priority.
  // a lower number priority means the promise will be sequenced first
  // promise is a function that when called, returns a promise
  function add(priority, promiseFunc) {
    // find next available slot
    while (rawSequence[priority]) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      priority++;
    }

    if (promiseFunc instanceof Promise) {
      rawSequence[priority] = () => promiseFunc();
    } else {
      rawSequence[priority] = promiseFunc;
    }
  }

  // executes the priority sorted promises
  function execute() {
    // get the objects sorted by their keys
    const promises = [];
    const numbersAscendingCompare = (a, b) => a - b;
    const keys = Object.keys(rawSequence)
      .map(n => parseInt(n))
      .sort(numbersAscendingCompare);
    keys.forEach(key => {
      promises.push(rawSequence[key]);
    });

    // sequence the promises
    return new Promise((resolve, reject) => {
      const doNext = () => {
        const promise = promises.shift();
        if (!promise) {
          rawSequence = {};
          resolve();
        } else {
          promise().then(doNext).catch(reject);
        }
      };
      doNext();
    });
  }

  return { add, execute };
}
