import { EventBinder } from '@utils/EventBinder.js';
import { keyboardEvents } from '@utils/keyboardEvents.js';
import { LitElement } from 'lit';
import { isPrivilegedIframe } from '@utils/isPrivilegedIframe.js';
import { getText } from '@utils/i18n.js';
import { MeterCentral } from '@services/datacollection/MeterCentral.js';
import { Meter } from '@common/mobx-stores/Meter.js';

/**
 * @typedef {Object} VseAppInfo
 * @property {string} appName app's full name, e.g. "Vernier XYZ Analysis"
 * @property {string} fileExtension app's document file extension, including
 * the dot.
 * @property {string} [sampleExperimentAddress] optional address where sample
 * experiments are located (used for some lookups)
 */

/**
 * VstApp is a baseclass for VSE application instances. It might eventually
 * contain much of the boilerplate code and properties duplicated in GaApp,
 * IaApp, SaApp, and VaApp.
 */
export class VseApp extends LitElement {
  // #region Instance properties.

  static get properties() {
    return {};
  }

  /**
   * Use this to bind events to various app methods.
   * @type {EventBinder}
   */
  eventBinder = new EventBinder();

  // #endregion

  // #region Lit and object Lifecycle Callbacks

  constructor() {
    super();

    this._onBackButton = this._onBackButton.bind(this);
    this._handleQuit = this._handleQuit.bind(this);
    this.trySave = this.trySave.bind(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('backbutton', this._onBackButton);
    document.removeEventListener('close-app', this._handleQuit);
    window.removeEventListener('beforeunload', this._handleQuit);
    this.eventBinder.unbindAll();
  }

  // #endregion

  // #region getters/setters

  /**
   * Returns specific information about this app. Subclasses may override to
   * return the information specific to the app.
   * @returns {VseAppInfo}
   */
  // eslint-disable-next-line class-methods-use-this
  get appInfo() {
    console.error('App instance must override appInfo() getter.');
    return {
      appName: '',
      fileExtension: '',
      sampleExperimentAddress: '',
    };
  }

  // #endregion

  // #region private methods

  /**
   * Quit event handler
   * @param {Event} event emitted by quit key or quit menu or app close events.
   * @private
   */
  _handleQuit(event) {
    // PWA: Show system "leave site?" when session is not empty
    if (PLATFORM_ID === 'web' && !this.$services.dataWorld.isSessionEmpty) {
      // Calling preventDefault on beforeunload shows the dialog
      event.preventDefault();
      // Chrome [doesn't support preventDefault on beforeunload](https://bugs.chromium.org/p/chromium/issues/detail?id=866818),
      // so use the deprecated field anyway.
      event.returnValue = false;
    }

    // This kicks off the file save check and ultimately data world uninit.
    this.$services.appLifecycle.closeWindow(false, event);
  }

  /**
   * Called when user presses the back button (on PWA et al.)
   * @returns {Promise} Promise that resolves after the last popover is closed
   * or after attempting to close the main window (this latter case will ask to
   * save file if necessary).
   * @private
   */
  _onBackButton() {
    const { popoverManager } = this.$services;
    return popoverManager.hasPopovers()
      ? this.$services.appLifecycle.closeWindow()
      : this.$services.popoverManager.closeLast();
  }

  // #endregion

  // #region public methods

  /**
   * Initializes common properties and installs listeners and event handlers.
   * Subclasses must call `super.initApp()` before proceeding to initialize
   * themselves.
   */
  initApp() {
    const { appLifecycle, dataWorld, sensorWorld } = this.$services;

    appLifecycle.onClose.addPromise(0, this.trySave);
    // Set up uninit
    appLifecycle.onClose.addBeforeClose(async () => {
      // Knowing if a user chooses "stay" at the "leave site?" beforeunload
      // prompt is not possible, so skip uninit for PWA. Closing the tab should
      // be adequate.
      if (PLATFORM_ID === 'web') return;
      await this.$services.dataCollection.uninit();
      await this.onStopDataShare?.();
    });

    document.addEventListener('backbutton', this._onBackButton);
    document.addEventListener('close-app', this._handleQuit);
    window.addEventListener('beforeunload', this._handleQuit);

    this.eventBinder.bindListeners({
      source: keyboardEvents,
      target: this,
      eventMap: {
        save: 'onSaveFile',
        'save-as': 'onSaveFile',
        quit: '_handleQuit',
      },
    });

    // Create a MeterCentral instance. This object will subscribe to various
    // events and set up Meters accordingly. This is a replacement to the
    // venerable modeLayoutPrefs function which has become increasingly
    // difficult to maintain.
    this._meterCentral = new MeterCentral({ dataWorld, sensorWorld });

    if (!dataWorld.udm.isBlock) {
      // Function that filters out specific column meters from appearing in the
      // bottom bar (e.g. live readouts bar).
      const excludeBottomBarMeters = (column, meter) => {
        let metered = meter.isVisibleInBottomBar;

        // No user created columns will be metered for the bottom BAR.
        if (column.group.isUserCreated) {
          metered = false;
        } else if (column.type === 'sensor') {
          // meter the sensor column unless it is replaced by a calculated colum
          const isCalcColumnReplaceDependent = columnId => {
            const col = dataWorld.getColumnById(columnId);
            return (
              col &&
              col.type === 'calc' &&
              col.metered &&
              !col.group.isUserCreated &&
              col.group.replaceDependent
            );
          };
          metered = !dataWorld.meters.some(
            meterItem =>
              meterItem.sensorInfo.id === meter.sensorInfo.id &&
              isCalcColumnReplaceDependent(meterItem.columnId),
          );
        } else if (column.type === 'calc') {
          // This addresses MEG-1285 which describes when auto-calc columns
          // should be metered or not on the bottom bar.
          const { group } = column;
          const hasCustomDerivative = group.customEq && group.customEq.includes('Derivative');
          const cannedDerivative =
            group.calcEquation === 'secondDerivative' || group.calcEquation === 'derivative';
          if (!hasCustomDerivative && !cannedDerivative) {
            // meter calculated column if it has a dependent sensor column
            const dependentGroups = group.calcDependentGroups.map(groupId =>
              dataWorld.getColumnGroupById(groupId),
            );
            // Don't show meters for calc-columns that are not sanctioned by
            // the column group's metered property.
            metered =
              group.metered &&
              dependentGroups.some(
                group => typeof group.sensorId === 'number' && group.sensorId !== 0,
              );
          }
        }
        return {
          bar: metered
            ? Meter.VisibilityOverride.FORCE_SHOWN
            : Meter.VisibilityOverride.FORCE_HIDDEN,
        };
      };

      this._meterCentral.addOverrideFunction(excludeBottomBarMeters);
    }
  }

  /**
   * Prompts user to save if necessary, and then calls the `onSaveFile()`
   * workflow.
   * @returns {Promise} Promise that resolves on successful file save. Will
   * reject if there's a file save problem OR the user cancels.
   */
  async trySave() {
    if (isPrivilegedIframe()) return {};

    const { dataWorld } = this.$services;

    const check = await dataWorld.checkForSave();
    let cancelSave = check.cancelled;
    if (check.save && !this.__notifiedNewFile) {
      const { promptForSave } = await import('@utils/promptForSave.js');
      const { cancelled, save } = await promptForSave.call(this, this.experimentName);
      cancelSave = cancelled;
      if (save) {
        return this.onSaveFile({ throwOnCancel: true });
      }
    }

    if (cancelSave) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ cancelled: true, cancelClose: true });
    }

    // don't save
    return Promise.resolve({});
  }

  /**
   * Bottleneck for saving files.
   * @param {object} [options] options for the save. What exact options are
   * expected is largely undocumented, but here are a couple of them:
   * @param {string} [options.eventName] if the eventName field is provided and
   * is `save-as` will perform force save operation.
   * @param {boolean} [options.throwOnCancel] if set, will throw an exception
   * when the user cancels in order to reset the app life cycle workflow.
   */
  async onSaveFile(options = {}) {
    const { toast, fileIO, dataWorld } = this.$services;
    const { fileExtension, sampleExperimentAddress } = this.appInfo;

    // check if we already have a filepath, and if we do, check if it has the
    // expected extension.
    const notAppFile =
      dataWorld.userFileMetaData.filepath &&
      // Android provides a file handle:
      !dataWorld.userFileMetaData.filepath.startsWith('content://') &&
      !dataWorld.userFileMetaData.filepath.endsWith(fileExtension);

    // Force save-as if we haven't saved before, or we've opened from a non-app
    // file (e.g. csv import) OR sample data experiment (indicated by the
    // specific experiment url address in the file path, currently GA only).
    const forceSaveAs =
      !dataWorld.userFileMetaData.filepath ||
      notAppFile ||
      dataWorld.userFileMetaData.filepath.includes(sampleExperimentAddress);

    if (forceSaveAs || options?.eventName === 'save-as') {
      options.saveAs = true;
      if (notAppFile) {
        const filenameParts = dataWorld.userFileMetaData.filepath.split('.');
        filenameParts.pop(); // drop the extension and let the app append the new one
        let suggestedName = filenameParts.join('.');
        if (suggestedName.includes(':')) suggestedName = suggestedName.split(':').pop();
        options.suggestedName = suggestedName;
      }
    }

    try {
      this.showWait?.(true, { message: getText('Saving') });
      if (await fileIO.saveFile(options)) toast.makeToast(getText('File saved'));
    } catch (err) {
      // We don't get an error back from cancel on some platforms.
      if (err) {
        console.error(err);
        toast.makeToast(getText('Save failed'), { duration: 10000 });
      }
      // Throw the magic which will reset the appLifeCycle object's promise,
      // otherwise the next quit will not invoke the file confirmation dialog.
      // eslint-disable-next-line no-throw-literal
      if (options?.throwOnCancel) throw { cancelClose: true };
    } finally {
      this.showWait?.(false);
    }
  }

  // #endregion
}
