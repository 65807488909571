import { css } from 'lit';

export const theme = (theme, cssProperties) => {
  if (theme === 'dark') {
    return css`
      :host(.dark:not(.light)) {
        ${cssProperties}
      }
    `;
  }
  return css``;
};
