import { DataShareSession } from '@services/session/DataShareSession.js';
import { DataCollectionSession } from '@services/session/DataCollectionSession.js';
import { ManualSession } from '@services/session/ManualSession.js';

// constructor arguments
const SESSION_CONSTRUCTORS = {
  datacollection: DataCollectionSession,
  datashare: DataShareSession,
  manualentry: ManualSession,
};

/**
 * Session Factory Function
 * @param {Object} services
 * @param {string} sessionType
 * @param {import('../dataworld/DataWorld').SessionConfig} sessionConfig
 * @returns {DataCollectionSession|DataShareSession|ManualSession}
 */
export async function createSession(services, sessionType = '', sessionConfig) {
  if (!sessionConfig.experimentId) {
    const { dataWorld } = services;
    const dc = dataWorld.dataCollection;
    const result = await dc.createExperiment();

    sessionConfig.experimentId = result.experimentId;
  }

  let session = null;

  const SessionConstructor = SESSION_CONSTRUCTORS[sessionType.toLowerCase()];
  if (typeof SessionConstructor === 'function') {
    try {
      session = new SessionConstructor(services, sessionConfig);
      session.experimentId = sessionConfig.experimentId;
    } catch (error) {
      console.error(`Creating Session: ${sessionType} failed:`);
      console.error(error);
    }
  }

  return session;
}
