/**
 * Compare function that defines the meter ascending sort order
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter1
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter2
 */
const MeterPositionCompare = (meter1, meter2) => {
  return meter1.position - meter2.position;
};

/**
 * Compare function that defines the meter descending sort order
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter1
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter2
 */
const meterPositionCompareDescending = (meter1, meter2) => {
  return meter2.position - meter1.position;
};

/**
 * Swaps positions between two meters
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter1
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter2
 */
const swapMeterPositions = (meter1, meter2) => {
  const oldMeter1Position = meter1.position;
  meter1.position = meter2.position;
  meter2.position = oldMeter1Position;
};

/**
 * Finds next position in list of unordered meters
 * @param {Array.<import('@common/mobx-stores/Meter.js').Meter>} meters
 * @returns {Number} meter position
 */
const findNextMeterPosition = meters => {
  return Math.max(0, ...meters.map(meter => meter.position)) + 1;
};

/**
 * Swaps positions between specified meter and meter with next lowest position
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter
 * @param {Array.<import('@common/mobx-stores/Meter.js').Meter>} meters
 * @returns {boolean} true if the position was changed, false if not.
 */
const decrementMeterPosition = (meter, meters) => {
  const _meters = [...meters].sort(meterPositionCompareDescending);
  const currentPosition = meter.position;
  const nextMeter = _meters.find(meter => meter.position < currentPosition && meter.position);
  if (nextMeter) {
    swapMeterPositions(meter, nextMeter);
    return true;
  }
  return false;
};

/**
 * Swaps positions between specified meter and meter with next highest position
 * @param {import('@common/mobx-stores/Meter.js').Meter} meter
 * @param {Array.<import('@common/mobx-stores/Meter.js').Meter>} meters
 * @returns {boolean} true if the position was changed, false if not.
 */
const incrementMeterPosition = (meter, meters) => {
  const _meters = [...meters].sort(MeterPositionCompare);
  const currentPosition = meter.position;
  const nextMeter = _meters.find(meter => meter.position > currentPosition);
  if (nextMeter) {
    swapMeterPositions(meter, nextMeter);
    return true;
  }
  return false;
};

export {
  decrementMeterPosition,
  incrementMeterPosition,
  MeterPositionCompare,
  findNextMeterPosition,
  meterPositionCompareDescending,
};
