/* eslint-disable camelcase */
/* eslint-disable no-bitwise */
import EventEmitter from 'eventemitter3';

const verboseLogging = false;

// Vernier Product IDs
// 2: Go!Temp
// 3: Go!Link
// 4: Go!Motion
// 6: SpectroVis
// 7: Mini GC
// 9: SpectroVis Plus
// 10: SpectroVis UV
// 15: Fluoresecence Spec
// 16: GoDirect
// 17: GoDirect SpectroVisPlus
// 18: Vernier GoDirect Bootloader
// 25: GoDirect Instruments
// 26: GoDirect Visible Spectrometer

// Note for Jimmy:  PIDs are in decimal not hex :)
const BLACKLIST_HID_PIDS = [2, 3, 4]; // hard coded Vernier HID productIds for the Go! Line (requires special handling on ChromeOS)
const HID_PIDS = [6, 7, 9, 10, 13, 15, 16, 17, 18, 25, 26]; // hard coded Vernier HID productIds
const VERNIER_VID = 0x08f7;

const FREESCALE_SEMICONDUCTOR_VID = 0x15a2; // NXP/GoDirect bootloader
const FREESCALE_SEMICONDUCTOR_PID = 0x0073; // NXP/GoDirect bootloader

function isHidDevice(dev) {
  return (
    (dev.vendorId === FREESCALE_SEMICONDUCTOR_VID &&
      dev.productId === FREESCALE_SEMICONDUCTOR_PID) ||
    (dev.vendorId === VERNIER_VID && HID_PIDS.includes(dev.productId))
  );
}

function isBlacklistHidDevice(dev) {
  return dev.vendorId === VERNIER_VID && BLACKLIST_HID_PIDS.includes(dev.productId);
}

// CHROME RUNTIME ERROR helper
const ChromeRuntimeError = function () {
  const { lastError } = window.chrome.runtime;
  Error.call(this, lastError.message);
  this.lastError = lastError;
};
ChromeRuntimeError.prototype = Object.create(Error.prototype);

// returns true if ok
ChromeRuntimeError.check = function (reject) {
  const hasError = !!window.chrome.runtime.lastError;
  if (hasError) {
    reject(new ChromeRuntimeError());
  }
  return !hasError;
};
const checkChromeError = function (reject) {
  return ChromeRuntimeError.check(reject);
};

// We need to format the object to look the same accross apis
const formatDevice = function (device) {
  const id = typeof device.deviceId === 'number' ? device.deviceId : device.device;
  return {
    deviceId: id,
    productId: device.productId,
    vendorId: device.vendorId,

    _usbId: device.device,
    _hidId: device.deviceId,
  };
};

const mergeDeviceObjs = function (d1, d2) {
  //    console.assert(d1.productId === d2.productId && d1.vendorId === d2.vendorId);

  const device = {
    deviceId: null,
    productId: d1.productId,
    vendorId: d1.vendorId,

    _usbId: typeof d1._usbId === 'number' ? d1._usbId : d2._usbId,
    _hidId: typeof d1._hidId === 'number' ? d1._hidId : d2._hidId,
  };

  const usbId = typeof device._usbId === 'number' ? device._usbId : 0;
  const hidId = typeof device._hidId === 'number' ? device._hidId : 0;

  // assert that the ids fit within 16-bits
  console.assert((hidId & 0xffff0000) === 0);
  console.assert((usbId & 0xffff0000) === 0);
  device.deviceId = ((usbId << 16) & 0xffff0000) | (hidId & 0xffff);

  return device;
};

export class ChromeUsbDeviceDiscovery extends EventEmitter {
  constructor() {
    super();
    this.chomeHidAddedListener = null;
    this.chromeHidRemovedListener = null;
    this.chromeUsbAddedListener = null;
    this.chromeUsbRemovedListener = null;
    this.allDevices = [];

    // HID blacklist device handling
    this.pending = {};
    this.pendingTimeout = null;
  }

  // Public method to get all attatched devices
  getAttachedDevices() {
    return this.allDevices;
  }

  findDeviceByVidPid(vendorId, productId) {
    return this.allDevices.find(d => d.vendorId === vendorId && d.productId === productId);
  }

  // USB device added
  // You can add a single device to the array by passing in a device
  _deviceAdded(device) {
    console.assert(device && device.productId);

    if (verboseLogging) {
      console.log('UsbDeviceDiscovery: device-added');
      console.dir(device);
    }
    this.allDevices.push(device);
    this.emit('device-added', device);
  }

  // USB device removed
  _deviceRemoved(device, optField) {
    let i;
    const deviceLen = this.allDevices.length;

    if (typeof optField !== 'string') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      optField = 'deviceId';
    }

    for (i = 0; i < deviceLen; ++i) {
      console.dir(this.allDevices[i]);
      if (this.allDevices[i][optField] === device.deviceId) {
        if (verboseLogging) {
          console.log('UsbDeviceDiscovery: device-removed');
          console.dir(device);
        }
        const removedDevice = this.allDevices.splice(i, 1)[0];
        this.emit('device-removed', removedDevice);
        break;
      }
    }
  }

  // HID devices require special handling
  _deviceAdded_BlacklistHID(device) {
    // add/merge in the device object
    const existingDevice = this.pending[device.productId];
    this.pending[device.productId] = mergeDeviceObjs(device, existingDevice || {});

    if (verboseLogging) {
      console.log('UsbDeviceDiscovery._deviceAdded_BlacklistHID: device added');
      console.dir(device);
    }

    // set a timeout so we can wait for duplicate events from the two apis
    clearTimeout(this.pendingTimeout);
    this.pendingTimeout = setTimeout(() => {
      const { pending } = this;
      this.pending = {};

      Object.keys(pending).forEach(key => {
        const device = pending[key];

        // if the device is ready
        if (device.deviceId !== null) {
          this._deviceAdded(device);
        } else {
          // otherwise put the device back in the pending list
          this.pending[key] = device;
          console.error('Failed to resolve merged USB/HID device');
          console.error(device);
        }

        clearTimeout(this.pendingTimeout);
        this.pendingTimeout = null;
      });
    }, 250);
  }

  _deviceRemoved_BlacklistHID(device, field) {
    this._deviceRemoved(device, field);
  }

  start() {
    function getHIDDevices(filters) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      filters = filters || {};
      return new Promise((resolve, reject) => {
        window.chrome.hid.getDevices(filters, devices => {
          if (checkChromeError(reject)) {
            resolve(devices);
          }
        });
      });
    }

    function getUSBDevices(filters) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      filters = filters || {};
      return new Promise((resolve, reject) => {
        window.chrome.usb.getDevices(filters, devices => {
          if (checkChromeError(reject)) {
            resolve(devices);
          }
        });
      });
    }

    // discover already connected devices
    getHIDDevices().then(hidDevices => {
      hidDevices.forEach(device => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        device = formatDevice(device);
        if (verboseLogging) {
          console.log('add device from HID STARTUP');
          console.dir(device);
        }

        if (isBlacklistHidDevice(device)) {
          this._deviceAdded_BlacklistHID(device);
        } else {
          this._deviceAdded(device);
        }
      });
    });

    // discover already connected devices
    getUSBDevices().then(usbDevices => {
      usbDevices.forEach(device => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        device = formatDevice(device);
        if (verboseLogging) {
          console.log('add device from USB STARTUP');
          console.dir(device);
        }

        if (isBlacklistHidDevice(device)) {
          this._deviceAdded_BlacklistHID(device);
        } else if (!isHidDevice(device)) {
          this._deviceAdded(device);
        }
      });
    });

    this._bindEvents();
  }

  // Bind to the deviceAdded/deviceRemoved events
  // This handles ignoring the duplicate events and only firing a single event
  _bindEvents() {
    //
    // chrome.hid api listeners
    //
    this.chomeHidAddedListener = device => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      device = formatDevice(device);

      if (isBlacklistHidDevice(device)) {
        this._deviceAdded_BlacklistHID(device);
      } else {
        this._deviceAdded(device);
      }
    };

    this.chromeHidRemovedListener = deviceId => {
      const device = this.allDevices.find(dev => dev._hidId === deviceId);

      if (device) {
        if (isBlacklistHidDevice(device)) {
          this._deviceRemoved_BlacklistHID(device, '_hidId');
        } else {
          this._deviceRemoved(device);
        }
      }
    };

    //
    // chrome.usb api listeners
    //
    this.chromeUsbAddedListener = device => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      device = formatDevice(device);

      if (isBlacklistHidDevice(device)) {
        this._deviceAdded_BlacklistHID(device);
      } else if (!isHidDevice(device)) {
        this._deviceAdded(device);
      }
    };

    this.chromeUsbRemovedListener = device => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      device = formatDevice(device);

      // check if this is a hid device because this will be handled by the hid.onDeviceAdded already
      if (isBlacklistHidDevice(device)) {
        this._deviceRemoved_BlacklistHID(device, '_usbId');
      } else if (!isHidDevice(device)) {
        this._deviceRemoved(device);
      }
    };

    // / HID ADD/REMOVE
    window.chrome.hid.onDeviceAdded.addListener(this.chomeHidAddedListener);
    window.chrome.hid.onDeviceRemoved.addListener(this.chromeHidRemovedListener);

    // / USB ADD/REMOVE
    window.chrome.usb.onDeviceAdded.addListener(this.chromeUsbAddedListener);
    window.chrome.usb.onDeviceRemoved.addListener(this.chromeUsbRemovedListener);
  }

  stop() {
    // / HID ADD/REMOVE
    window.chrome.hid.onDeviceAdded.removeListener(this.chomeHidAddedListener);
    window.chrome.hid.onDeviceRemoved.removeListener(this.chromeHidRemovedListener);

    // / USB ADD/REMOVE
    window.chrome.usb.onDeviceAdded.removeListener(this.chromeUsbAddedListener);
    window.chrome.usb.onDeviceRemoved.removeListener(this.chromeUsbRemovedListener);

    this.chomeHidAddedListener = null;
    this.chromeHidRemovedListener = null;
    this.chromeUsbAddedListener = null;
    this.chromeUsbRemovedListener = null;
  }
}

export const UsbDeviceDiscovery =
  window.chrome && window.chrome.hid ? ChromeUsbDeviceDiscovery : null;
