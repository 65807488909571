import * as platform from './PLATFORM_ID';

export const createAppAutoUpdate = appLifecycleService => {
  if (platform.createAppAutoUpdate) {
    return platform.createAppAutoUpdate(appLifecycleService);
  }

  // TODO: if we ever we add a new platform to this service, expose the below methods via the adapter pattern
  return {
    get isCheckingForUpdate() {
      return false;
    },

    get isUpdateAvailable() {
      return false;
    },

    get isUpdateDownloading() {
      return false;
    },

    get isUpdateDownloaded() {
      return false;
    },

    checkForUpdates() {
      return false;
    },

    downloadUpdate() {
      return false;
    },

    installUpdate() {
      return false;
    },

    // stub out EventEmitter methods
    on() {},
    off() {},
    once() {},
  };
};
