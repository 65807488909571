import { LitElement, html } from 'lit';

// This element is needed as a sibling of your app (<ga-app>) to get tooltip styling and positioning correct
export class VstUiTooltipWrapper extends LitElement {
  createRenderRoot() {
    /**
     * Render template in light DOM. Note that shadow DOM features like
     * encapsulated CSS are unavailable.
     */
    return this;
  }

  render() {
    return html`
      <style>
        .tooltip-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          pointer-events: none;
          z-index: var(--vst-z-popover) + 1;
        }

        .tippy-popper {
          pointer-events: auto;
        }

        .tippy-tooltip.vst-ui-theme {
          font-family: var(--vst-font-family);
          font-size: var(--vst-font-size-xs);
          background-color: var(--vst-color-dark-300);
          color: var(--vst-color-light-100);
        }

        /* Error theme */
        .tippy-tooltip.vst-ui--error-theme {
          font-size: var(--vst-font-size-m);
          background-color: var(--vst-color-danger-bg);
          color: var(--vst-color-danger-dark);
        }

        .tippy-tooltip.vst-ui--error-theme[x-placement^='top'] .tippy-arrow {
          border-top-color: var(--vst-color-danger-bg);
        }

        .tippy-tooltip.vst-ui--error-theme[x-placement^='bottom'] .tippy-arrow {
          border-bottom-color: var(--vst-color-danger-bg);
        }

        /* Instructions theme */
        .tippy-tooltip.vst-ui--instructions-theme {
          font-size: var(--vst-font-size-m);
          background-color: var(--vst-color-info-bg);
          color: var(--vst-color-info);
        }

        .tippy-tooltip.vst-ui--instructions-theme[x-placement^='top'] .tippy-arrow {
          border-top-color: var(--vst-color-info-bg);
        }

        .tippy-tooltip.vst-ui--instructions-theme[x-placement^='bottom'] .tippy-arrow {
          border-bottom-color: var(--vst-color-info-bg);
        }
      </style>

      <div id="tooltip_wrapper" class="tooltip-wrapper"></div>
    `;
  }
}

customElements.define('vst-ui-tooltip-wrapper', VstUiTooltipWrapper);
