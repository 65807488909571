import { makeAutoObservable } from 'mobx';
import { EventBinder } from '@utils/EventBinder.js';

/**
 * @typedef {object} MeterVisibility describes whether a meter ought to be
 * visible for a given meter location, and whether the user prefers to show
 * or hide the meter.
 * @property {Meter.VisibilityOverride} override One of NONE, FORCE_SHOWN, or
 * FORCE_HIDDEN.
 * @property {boolean} default default visibility for this meter, provided by
 * the sensormap.
 */

/**
 * @typedef {object} SensorMatching some meta-data that we can use to match a
 * saved meter with a live sensor.
 * @property {number} id  the sensor Id associated with the meter.
 * @property {number} autoId sensormap auto-id associated with this meter.
 * @property {string} name the name of an associated sensor.
 */

/**
 * Meter is a record that keeps track of currently displayed meters.
 * Meters are created and administered by MeterCentral, a singleton property of
 * the application (ga-app, sa-app et al.) instance.
 *
 * Meters can appear in 2 places: in the meter pane of the main view content,
 * aka `meter`, and in the bottom bar where live readouts are displayed, aka
 * `bar`.
 */
export class Meter {
  /**
   * Size of this meter.
   * @enum {string}
   */
  static Size = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
  });

  /**
   * @enum {string} An enumeration which specifies how to override the visibility
   * of a meter.
   */
  static VisibilityOverride = Object.freeze({
    NONE: 'none',
    FORCE_SHOWN: 'shown',
    FORCE_HIDDEN: 'hidden',
  });

  static DEFAULT_METER_VISIBILITY = { default: false, override: Meter.VisibilityOverride.NONE };

  // TODO: (jkelly) figure out whether we can simply proxy properties like color
  // and name et al. through the associated column group. Also, maybe we should
  // reference the column group in the meter instead of the column.

  /**
   * Identifier for this meter. Assigned and persisted through udm.
   * @type {number}
   * @readonly
   */
  id = 0;

  /**
   * Udm id for the data column this meter is associated with.
   * @type {number}
   */
  columnId = 0;

  /**
   * A color this meter should present itself as to the user.
   * @type {color}
   */
  color = {};

  /**
   * Should this meter be displayed on the page's meter pane?
   * @type {MeterVisibility}
   */
  meterVisibility = Meter.DEFAULT_METER_VISIBILITY;

  /**
   * Should this meter be displayed in the bottom bar?
   * @type {MeterVisibility}
   */
  barVisibility = Meter.DEFAULT_METER_VISIBILITY;

  /**
   * Name of this meter (typically reflects the column name).
   * @type {string}
   */
  name = '';

  /**
   * A number describing the meter's position in the page's meter pane. If null,
   * indicates that a position has not yet been assigned.
   * @type {?number}
   */
  position = null;

  /**
   * Units to display for this meter.
   * @type {string}
   */
  units = '';

  /**
   * The current live value of the meter.
   * @type {?number}
   */
  value = null;

  /**
   * Wavelength that the current live readout corresponds to (SA only).
   * @type {number}
   */
  wavelength = 0;

  /**
   * Size of the meter. TODO: (jkelly) do we use this to actually
   * adjust the size of the meter, or is this another way we describe whether
   * the meter shows up on the meter pane or the bottom bar?
   * @type {string}
   */
  size = Meter.Size.MEDIUM;

  /**
   * Metadata we can use to re-unite a meter with its sensor and column etc.
   * @type {?SensorMatching}
   */
  sensorInfo = null;

  /**
   * Column group id. Since column groups are ephemeral, this value is not
   * persisted.
   * @type {number}
   */
  groupId = 0;

  constructor(options = {}) {
    this.id = options.id || 0;
    this.columnId = options.columnId || 0;
    this.position = options.position || '';
    this.meterVisibility = options.meterVisibility || Meter.DEFAULT_METER_VISIBILITY;
    this.barVisibility = options.barVisibility || Meter.DEFAULT_METER_VISIBILITY;
    this.size = options.size || Meter.Size.MEDIUM;

    this.color = options.color || {};
    this.name = options.name || '';
    this.units = options.units || '';
    this.value = options.value || '';
    this.wavelength = options.wavelength || '';
    this.groupId = options.groupId || 0;

    this._eventBinder = null;

    this.sensorInfo = options.sensorInfo || {
      autoId: 0,
      name: '',
      id: 0,
    };

    this.experimentId = options.experimentId || 0;

    makeAutoObservable(this);
  }

  /**
   * @returns {Object} generic object containing properties specific to udm
   * persistence.
   */
  get udmExport() {
    return {
      id: this.id,
      columnId: this.columnId,
      meterVisibility: { ...this.meterVisibility },
      barVisibility: { ...this.barVisibility },
      size: this.size,
      sensorInfo: { ...this.sensorInfo },
      position: this.position ? this.position : -1,
    };
  }

  /**
   * Function passed into `addBinding(eventBinder)`.
   * @callback BindFunction
   * @param {EventBinder} binder
   */

  /**
   * Add event bindings to this Meter instance.
   * @param {BindFunction} func a function that is called immediately. Use the
   * passed-in EventBinder to add bindings to this object as necessary.
   */
  addBindings(func) {
    if (!this._eventBinder) this._eventBinder = new EventBinder();
    func(this._eventBinder);
  }

  /**
   * Removes all bindings from this Meter object.
   */
  removeAllBindings() {
    if (this._eventBinder) {
      this._eventBinder.unbindAll();
      this._eventBinder = null;
    }
  }

  /**
   * @returns {boolean} true if the bindings have already been added to this
   * meter instance.
   */
  get hasBindings() {
    return Boolean(this._eventBinder);
  }

  /**
   * Override the default visibility for this meter as displayed in the Meter
   * pane.
   * @param {Meter.VisibilityOverride} newOverride
   */
  overrideMeterVisibility(newOverride) {
    this.meterVisibility = {
      override: newOverride,
      default: this.meterVisibility.default,
    };
  }

  /**
   * Override the default visibility for this meter as displayed in the Bottom
   * Bar.
   * @param {Meter.VisibilityOverride} newOverride
   */
  overrideBarVisibility(newOverride) {
    this.barVisibility = {
      override: newOverride,
      default: this.meterVisibility.default,
    };
  }

  /**
   * @returns {boolean} true if the meter SHOULD be shown in the meter pane,
   * false if not.
   */
  get isVisibleInMeterPane() {
    switch (this.meterVisibility.override) {
      case Meter.VisibilityOverride.FORCE_SHOWN:
        return true;
      case Meter.VisibilityOverride.FORCE_HIDDEN:
        return false;
      default:
        return this.meterVisibility.default;
    }
  }

  /**
   * @returns {boolean} true if the meter SHOULD be shown in the bottom bar,
   * false if not.
   */
  get isVisibleInBottomBar() {
    switch (this.barVisibility.override) {
      case Meter.VisibilityOverride.FORCE_SHOWN:
        return true;
      case Meter.VisibilityOverride.FORCE_HIDDEN:
        return false;
      default:
        return this.barVisibility.default;
    }
  }
}
