// keyboard events //
// save: cmd/ctrl + s
// save-as: cmd/ctrl + shift + s
// new-file: cmd/ctrl + n
// open-file: cmd/ctrl + o

// devKeyDown: d

import EventEmitter from 'eventemitter3';

class KeyboardEvents extends EventEmitter {
  constructor() {
    super();
    this.init();
  }

  init() {
    const keyDownHandler = this._keyDownHandler.bind(this);
    const keyUpHandler = this._keyUpHandler.bind(this);
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);

    this.uninit = () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('keyup', keyUpHandler);
    };
  }

  emitEvent(event) {
    this.emit(event, { eventName: event });
  }

  _keyDownHandler(e) {
    const key = e.key.toUpperCase();
    const isMetaKeyDown = e.metaKey || e.ctrlKey;

    // all cmd/ctrl key combos
    if (isMetaKeyDown) {
      switch (key) {
        case 'D':
          this.devKeyDown = true;
          this.emit('dev-override');
          break;
        case 'S':
          if (e.shiftKey) {
            this.emitEvent('save-as');
          } else {
            this.emitEvent('save');
          }
          e.preventDefault();
          break;
        case 'N':
          this.emitEvent('new-file');
          e.preventDefault();
          break;
        case 'O':
          this.emitEvent('open-file');
          e.preventDefault();
          break;
        case 'Q':
        case 'W':
          this.emitEvent('quit');
          e.preventDefault();
          break;
        default:
      }
    } else {
      switch (key) {
        case 'D':
          this.devKeyDown = true;
          break;
        default:
      }
    }
  }

  _keyUpHandler(e) {
    const key = e.key.toUpperCase();

    switch (key) {
      case 'D':
        this.devKeyDown = false;
        break;
      default:
    }
  }
}

export const keyboardEvents = new KeyboardEvents();
