/* eslint-disable no-prototype-builtins */
import { http } from '@utils/http.js';

const SENSOR_MAP = 'resources/sensormap.json';

function constructSensorMap(sensorMap) {
  return Object.freeze({
    getSensorMap() {
      return sensorMap;
    },

    getSensorById(sensorId) {
      if (typeof sensorId !== 'string') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        sensorId = `${sensorId}`;
      }

      const sensors = sensorMap.sensor;

      function parseUnit(unit) {
        return {
          id: unit.id,
          minRange: parseFloat(unit['min-range']),
          maxRange: parseFloat(unit['max-range']),
          columnName: unit['_column-name'],
          units: unit.units,
          decimalPlaces: parseFloat(unit['decimal-places']),
        };
      }

      function createUnit(unitArray) {
        const unitConversion = [];

        if (typeof unitArray !== 'undefined') {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line no-param-reassign
          unitArray = Array.prototype.concat(unitArray); // Force into array if not already

          unitArray.forEach(unit => {
            unitConversion.push(parseUnit(unit));
          });
        }

        return unitConversion;
      }

      // extract all 'kN' keys in calibration for N in 0,1,2,...
      // return an array of these values
      function createCalibrationCoefficients(calibration) {
        function makeKey(index) {
          return `k${index}`;
        }

        let key;
        let i = 1;
        const coeffs = [];

        key = makeKey(0);
        while (key in calibration) {
          coeffs.push(parseFloat(calibration[key]));
          key = makeKey(i++);
        }

        return coeffs;
      }

      function createCalibration(sensor) {
        const { calibration } = sensor;

        const baseUnit = parseUnit(calibration.baseunit);
        const unitsArray = createUnit(calibration.unit);

        return {
          type: calibration.type,
          id: calibration.id,
          coeffs: createCalibrationCoefficients(calibration),
          unit: unitsArray,
          baseUnit,
          zero: calibration.zero,
          reverse: calibration.reverse,
          calibrate: calibration.calibrate,
          onePoint: calibration['single-point'],
        };
      }

      function _createSensorMap(sensor) {
        const sensorMap = {
          autoId: parseInt(sensor['auto-id'], 10),
          type: sensor.type,
          id: sensor.id,
          family: sensor._family,
          name: sensor._name,
          minimumDelta: parseFloat(sensor['minimum-delta']),
          typicalDelta: parseFloat(sensor['typical-delta']),
          typicalDuration: parseFloat(sensor['typical-duration']),
          // oversample:        sensor.oversample === 'Y',
          meter: sensor.calibration.meter,
          calibration: createCalibration(sensor),
        };

        if (sensor.hasOwnProperty('voltage-decimals')) {
          sensorMap.voltageDecimals = parseFloat(sensor['voltage-decimals']);
        }

        return sensorMap;
      }

      let sensor = sensors.find(s => s.id === sensorId);

      if (sensor) {
        sensor = _createSensorMap(sensor);
      }

      return sensor;
    },
  });
}

export async function createSensorMap() {
  let sensorMap = {};
  try {
    const response = await http.get(SENSOR_MAP);
    sensorMap = constructSensorMap(JSON.parse(response));
  } catch (err) {
    console.error(err);
  }

  return sensorMap;
}
