import { throttle } from 'lodash-es';

export class CenterOfMass {
  constructor({ api, dataWorld, videoAnalysis }) {
    this.api = api;
    this.videoAnalysis = videoAnalysis;
    this.dataWorld = dataWorld;
    this._updateTrace = throttle(this.updateTrace.bind(this), 1);
    this.posColumns = [];

    // point to pixel conversion
    this.resetTransform();
  }

  async enableColumns(enable) {
    const meta = await this.api.enableCenterOfMassColumns(this.dataWorld.experimentId, enable);

    this._unbindColumns();

    if (enable) {
      await this.setupColumns(meta);
    }
  }

  async setupColumns(meta) {
    const xCol = this.dataWorld.getColumnById(meta.xPosColumnId);
    const yCol = this.dataWorld.getColumnById(meta.yPosColumnId);
    this._unbindColumns();
    this._bindColumns(xCol, yCol);
    await this.updateTrace();
  }

  resetTransform() {
    this.xform = {
      originX: 0,
      originY: 0,
    };
  }

  // listen to values changing on the columns
  _bindColumns(xCol, yCol) {
    this.posColumns = [xCol, yCol];
    this.posColumns.forEach(c => {
      c.on('values-changed', this._updateTrace);
    });
  }

  _unbindColumns() {
    this.posColumns.forEach(c => {
      c.off('values-changed', this._updateTrace);
    });
    this.posColumns = [];
  }

  _transformPoint(point) {
    const { x, y } = this.videoAnalysis.toPixels(...point);
    const { originX, originY } = this.xform;

    point[0] = x + originX;
    point[1] = y + originY;
  }

  async updateTrace() {
    if (this.updatesPaused) {
      return;
    }

    const [xCol, yCol] = this.posColumns;
    const xValues = xCol.values;
    const yValues = yCol.values;

    if (xValues.length === yValues.length) {
      const frames = await this.videoAnalysis.getFrames();
      const trace = [];

      let frame;
      const point = [0, 0];

      for (let i = 0; i < xValues.length; ++i) {
        frame = frames[i];
        point[0] = xValues[i];
        point[1] = yValues[i];

        if (!Number.isNaN(frame) && !Number.isNaN(point[0]) && !Number.isNaN(point[1])) {
          this._transformPoint(point);
          trace.push({ frame, x: point[0], y: point[1] });
        }
      }

      this.videoAnalysis.emit('center-of-mass-trace-updated', trace);
    }
  }

  setVideoOrigin(originX, originY) {
    this.xform.originX = originX;
    this.xform.originY = originY;
  }
}
