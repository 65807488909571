export class VideoAnalysisAPI {
  constructor(rpc) {
    this.rpc = rpc;
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {object} strings
   *
   * Pass an object containing translated strings.
   * Strings:
   *  'Velocity'
   *  'Acceleration'
   *  'px'
   */
  setTextStrings(experimentId, strings) {
    return this.rpc.sendRequest('set-va-text-strings', { experimentId, ...strings });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} visible
   */
  setVideoVisibility(experimentId, visible) {
    return this.rpc.sendRequest('set-video-visibilty', { experimentId, visible });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} properties.scaleUnit
   */
  setVideoScaleUnits(experimentId, units) {
    return this.rpc.sendRequest('set-video-scale-units', { experimentId, units });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} properties.scaleStartX
   * @param {number} properties.scaleStartY
   * @param {number} properties.scaleEndX
   * @param {number} properties.scaleEndY
   * @param {number} properties.scaleDistance
   */
  setVideoScale(experimentId, properties) {
    return this.rpc.sendRequest('set-video-scale', { experimentId, ...properties });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} startFrame
   * @param {number} endFrame
   */
  setVideoTrimRange(experimentId, startFrame, endFrame) {
    return this.rpc.sendRequest('set-video-trim-range', { experimentId, startFrame, endFrame });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} rate
   */
  setFrameAdvanceRate(experimentId, frameAdvanceRate) {
    return this.rpc.sendRequest('set-frame-advance-rate', { experimentId, frameAdvanceRate });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} rate
   */
  setFrameRateMultiplier(experimentId, frameRateMultiplier) {
    return this.rpc.sendRequest('set-frame-rate-multiplier', { experimentId, frameRateMultiplier });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} selectedFrame
   */
  setSelectedFrame(experimentId, selectedFrame) {
    return this.rpc.sendRequest('set-selected-video-frame', { experimentId, selectedFrame });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} originX
   * @param {number} originY
   */
  setVideoOrigin(experimentId, originX, originY) {
    return this.rpc.sendRequest('set-video-origin', { experimentId, originX, originY });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} angle
   */
  setVideoAxisRotation(experimentId, angle) {
    return this.rpc.sendRequest('set-video-axis-rotation', { experimentId, angle });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} showTrails
   */
  setVideoShowTrails(experimentId, showTrails) {
    return this.rpc.sendRequest('set-video-show-trails', { experimentId, showTrails });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {object} properties
   * @param {number} properties.videoId
   * @param {number} properties.datasetId
   * @param {number} properties.graphId
   * @param {number} properties.baseColId
   * @param {string} properties.archivePath
   * @param {boolean} properties.visible
   * @param {boolean} properties.showTrails
   * @param {number} properties.originX
   * @param {number} properties.originY
   * @param {number} properties.rotationAngle
   * @param {number} properties.frameRate
   * @param {number} properties.frameRateMultiplier
   */
  setVideoProperties(experimentId, properties) {
    return this.rpc.sendRequest('set-video-properties', { experimentId, ...properties });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  getFrameTimes(experimentId) {
    return this.rpc.sendRequest('get-frame-times', { experimentId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} nameSuffix
   */
  createObject(experimentId, nameSuffix) {
    return this.rpc.sendRequest('create-object', { experimentId, nameSuffix });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} objectId
   * @param {number[]} values
   */
  updateObjectValues(experimentId, objectId, values) {
    return this.rpc.sendRequest('update-object-values', { experimentId, objectId, values });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} objectId
   * @param {object} props
   * @param {string} props.name
   * @param {number} props.mass
   * @param {string} props.units
   */
  updateObjectProperties(experimentId, objectId, props) {
    return this.rpc.sendRequest('update-object-properties', { experimentId, objectId, ...props });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} objectId
   * @param {number} time
   */
  removePoint(experimentId, objectId, timeStamp) {
    return this.rpc.sendRequest('remove-point', { experimentId, objectId, timeStamp });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} mode: 'rectangular' or 'polar'
   */
  setCoordinateMode(experimentId, mode) {
    return this.rpc.sendRequest('set-va-coordinate-mode', { experimentId, mode });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} toAbsolute
   * @param {number} time
   * @return {Promise} convertedTime
   */
  convertVideoTime(experimentId, toAbsolute, time) {
    return this.rpc.sendRequest('convert-video-time', { experimentId, toAbsolute, time });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} objectId
   * @return {Promise} array of x/y pixel pairs w/o origin offset and rotation applied
   */
  getObjectSourcePixels(experimentId, objectId) {
    return this.rpc.sendRequest('get-object-source-pixels', { experimentId, objectId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} objectId
   * @param {string} color
   */
  changeVideoObjectTraceColor(experimentId, objectId, color) {
    return this.rpc.sendRequest('change-video-object-trace-color', {
      experimentId,
      objectId,
      color,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  enableVideoDataReplay(experimentId) {
    return this.rpc.sendRequest('enable-video-data-replay', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  disableVideoDataReplay(experimentId) {
    return this.rpc.sendRequest('disable-video-data-replay', { experimentId });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} video absolute value timestamp
   */
  setVideoReplayTimestamp(experimentId, timestamp) {
    return this.rpc.sendRequest('set-video-replay-timestamp', { experimentId, timestamp });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} enable
   */
  enableCenterOfMassColumns(experimentId, enable) {
    return this.rpc.sendRequest('enable-va-center-of-mass', { experimentId, enable });
  }

  /**
   * @param {boolean} enable
   */
  enableAccelerationColumns(experimentId, enable) {
    return this.rpc.sendRequest('enable-va-acceleration-columns', { experimentId, enable });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {object} vectorDisplay
   * @param {number} vectorDisplay.posState
   * @param {number} vectorDisplay.velState
   * @param {number} vectorDisplay.accState
   * @param {string} vectorDisplay.posColor
   * @param {string} vectorDisplay.velColor
   * @param {string} vectorDisplay.accColor
   * @param {number} vectorDisplay.velScale
   * @param {number} vectorDisplay.accScale
   * @param {number} vectorDisplay.frequency
   */
  async setVectorDisplay(experimentId, vectorDisplay) {
    return this.rpc.sendRequest('set-va-vector-display', { experimentId, ...vectorDisplay });
  }

  /**
   *
   * @param {string} token
   */
  validateJWT(token) {
    return this.rpc.sendRequest('validate-jwt', { token });
  }
}
