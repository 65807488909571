import { autorun as _autorun } from 'mobx';

class _AutorunHelper {
  _firstTime = true;

  _completion = () => {};

  constructor(fn) {
    this._completion = _autorun(() => {
      fn(this._firstTime);
      this._firstTime = false;
    });
  }

  get completion() {
    return () => {
      this._completion();
      this._completion = null;
    };
  }
}

/**
 * Sets up a mobx autorun function that receives a flag indicating if this is
 * the first time the function has been run, thus allowing us to distinguish
 * between the first invocation (mobx internal bookkeeping) and subsequent
 * invocations (observable state changes).
 *
 * @param {function(boolean)} func an autorun function. Receives a single
 * boolean argument which is true if this is the first time the autrun has
 * been called, false if this is a subsequent invocation.
 * @returns {function()} call this to cancel the autorun.
 */
export function autorun(func) {
  const helper = new _AutorunHelper(func);
  return helper.completion;
}
