import { css, unsafeCSS } from 'lit';
import { breakpoint } from './mixins/breakpoints.css.js';
import { theme } from './mixins/theme.css.js';
import { vstColorGraphVariables } from '../../utils/traceColor/traceColors.js';

export const customPropertyStyles = css`
  :host,
  html {
    /* brand colors */
    --vst-color-brand-400: hsl(180, 100%, 14%);
    --vst-color-brand-300: hsl(182, 100%, 23%);
    --vst-color-brand-200: hsl(184, 42%, 58%);
    --vst-color-brand-100: hsl(180deg 60% 92%);

    --vst-color-brand-dark: var(--vst-color-brand-400);
    --vst-color-brand: var(--vst-color-brand-300);
    --vst-color-brand-bg: var(--vst-color-brand-100);

    /* rebrand secondary colors - not currently in use, but here for reference */
    --vst-color-orange: hsl(19, 87%, 54%);
    --vst-color-gold: hsl(40, 97%, 59%);
    --vst-color-peach: hsl(26, 96%, 82%);
    --vst-color-chartreuse: hsl(73, 100%, 67%);

    --vst-color-secondary-dark: var(--vst-color-orange);
    --vst-color-secondary: var(--vst-color-gold);
    --vst-color-secondary-bg: var(--vst-color-peach);

    /* semantic colors */
    --vst-color-success-400: hsl(142deg 100% 20%);
    --vst-color-success-300: hsl(135deg 96% 28%);
    --vst-color-success-200: hsl(138deg 90% 35%);
    --vst-color-success-100: hsl(127deg 46% 93%);

    --vst-color-success-dark: var(--vst-color-success-400);
    --vst-color-success: var(--vst-color-success-300);
    --vst-color-success-ui: var(--vst-color-success-200);
    --vst-color-success-bg: var(--vst-color-success-100);

    --vst-color-warning-400: hsl(20deg 98% 36.9%);
    --vst-color-warning-300: hsl(19deg 87.1% 53.6%);
    --vst-color-warning-200: hsl(20deg 92% 64%);
    --vst-color-warning-100: hsl(32deg 100% 96.5%);

    --vst-color-warning: var(--vst-color-warning-300);
    --vst-color-warning-ui: var(--vst-color-warning-200);
    --vst-color-warning-bg: var(--vst-color-warning-100);

    --vst-color-info-300: hsl(219deg 86% 38%);
    --vst-color-info-200: hsl(214deg 94% 55%);
    --vst-color-info-100: hsl(211deg 60% 91%);

    --vst-color-info: var(--vst-color-info-300);
    --vst-color-info-ui: var(--vst-color-info-200);
    --vst-color-info-bg: var(--vst-color-info-100);

    --vst-color-important: hsl(266, 52%, 48%);
    --vst-color-important-bg: hsl(265, 49%, 89%);

    --vst-color-danger-400: hsl(0deg 98% 34%);
    --vst-color-danger-300: hsl(0deg 78% 47%);
    --vst-color-danger-200: hsl(0deg 89% 56%);
    --vst-color-danger-100: hsl(0deg 59% 90%);

    --vst-color-danger-dark: var(--vst-color-danger-400);
    --vst-color-danger: var(--vst-color-danger-300);
    --vst-color-danger-ui: var(--vst-color-danger-200);
    --vst-color-danger-bg: var(--vst-color-danger-100);
    --vst-color-danger-fg: var(--vst-color-danger-400);

    /* graph colors */
    --vst-color-graph-red: hsl(356, 70%, 49%);
    --vst-color-graph-baby-blue: hsl(206, 58%, 51%);
    --vst-color-graph-vomit: hsl(56, 72%, 42%);
    --vst-color-graph-blue: hsl(230, 54%, 43%);
    --vst-color-graph-pink: hsl(335, 69%, 63%);
    --vst-color-graph-purple: hsl(291, 38%, 28%);

    ${unsafeCSS(vstColorGraphVariables)}

    --vst-color-graph-1: var(--vst-color-graph-1-light);
    --vst-color-graph-2: var(--vst-color-graph-2-light);
    --vst-color-graph-3: var(--vst-color-graph-3-light);
    --vst-color-graph-4: var(--vst-color-graph-4-light);
    --vst-color-graph-5: var(--vst-color-graph-5-light);
    --vst-color-graph-6: var(--vst-color-graph-6-light);
    --vst-color-graph-7: var(--vst-color-graph-7-light);
    --vst-color-graph-8: var(--vst-color-graph-8-light);

    /* dark & light mode colors */
    --vst-color-dark-300: hsl(200deg 6% 10%);
    --vst-color-dark-200: hsl(200deg 4% 16%);
    --vst-color-dark-100: hsl(195deg 3% 25%);
    --vst-color-neutral: hsl(197deg 3% 46%);
    --vst-color-light-300: hsl(200deg 4% 69%);
    --vst-color-light-200: hsl(204deg 9% 89%);
    --vst-color-light-100: hsl(210deg 11% 96%);
    --vst-color-bg-dark: hsl(200deg, 10%, 7%);
    --vst-color-bg-light: #fefefe;
    --vst-color-logo-text-dark: hsl(0, 0%, 13%);
    --vst-color-logo-text-light: hsl(100, 100%, 100%);

    /* foreground & background colors */
    --vst-color-fg-primary: var(--vst-color-dark-300);
    --vst-color-fg-secondary: var(--vst-color-dark-200);
    --vst-color-fg-tertiary: var(--vst-color-dark-100);
    --vst-color-bg-primary: var(--vst-color-light-300);
    --vst-color-bg-secondary: var(--vst-color-light-200);
    --vst-color-bg-tertiary: var(--vst-color-light-100);
    --vst-color-bg: var(--vst-color-bg-light);

    /* Elements */
    --vst-color-btn: var(--vst-color-fg-primary);
    --vst-color-btn-bg: var(--vst-color-bg);
    --vst-color-btn-bg-hover: var(--vst-color-bg);
    --vst-color-btn-bg-active: var(--vst-color-bg-tertiary);
    --vst-color-btn-border: var(--vst-color-neutral);
    --vst-color-btn-text-bg-hover: var(--vst-color-bg-tertiary);
    --vst-color-btn-danger: var(--vst-color-danger-400);
    --vst-color-btn-danger-hover: var(--vst-color-danger-400);
    --vst-color-btn-danger-bg-hover: var(--vst-color-danger-100);
    --vst-color-toolbar-bg: var(--vst-color-bg-secondary);
    --vst-color-btn-toolbar-bg: var(--vst-color-bg-secondary);
    --vst-color-btn-toolbar-bg-hover: var(--vst-color-bg);
    --vst-color-media-btn-bg-hover: var(--vst-color-bg-tertiary);
    --vst-color-icon-hover: var(--vst-color-bg-tertiary);
    --vst-color-btn-graph-bg: #fff;
    --vst-color-btn-graph-border: var(--vst-color-fg-tertiary);
    --vst-color-btn-graph-bg-hover: #fff;
    --vst-color-btn-graph-border-hover: var(--vst-color-fg-secondary);
    --vst-color-examine-handle-bg: var(--vst-color-bg-secondary);
    --vst-color-prediction-toolbar-bg: var(--vst-color-bg);
    --vst-color-sensor-bg: var(--vst-color-bg);
    --vst-color-proximity-connect-bg: var(--vst-color-warning-bg);
    --vst-color-proximity-connect-text: var(--vst-color-warning);
    --vst-color-connect-pane-bg: var(--vst-color-warning-bg);
    --vst-color-connect-pane-text: var(--vst-color-warning);
    --vst-color-inner-popover-bg: var(--vst-color-bg);
    --vst-grid-selected-background-color: var(--vst-color-info-bg);
    --vst-grid-selected-border-color: var(--vst-color-info);
    --vst-grid-body-background-color: var(--vst-color-bg);
    --vst-grid-border-color: var(--vst-color-bg-primary);
    --vst-shadow-btn-hover: var(--vst-shadow-m);
    --vst-color-sample-video-bg: var(--vst-color-bg);
    --vst-color-notes-bg: var(--vst-color-bg);
    --vst-opacity-disabled: 0.45;
    --vst-color-predictions-overlay-bg: rgba(255, 255, 255, 0.65);
    --vst-color-popover-border: transparent;
    --vst-color-text-brand: var(--vst-color-brand);
    --vst-color-text-brand-hover: var(--vst-color-brand-dark);
    --vst-color-text-success: var(--vst-color-success);
    --vst-color-text-success-hover: var(--vst-color-success-dark);
    --vst-color-text-info: var(--vst-color-info-ui);
    --vst-color-text-info-hover: var(--vst-color-info);
    --vst-color-text-warning: var(--vst-color-warning);
    --vst-color-text-warning-hover: var(--vst-color-warning);
    --vst-color-text-important: var(--vst-color-important);
    --vst-color-text-important-hover: var(--vst-color-important-dark);
    --vst-color-text-danger: var(--vst-color-danger);
    --vst-color-text-danger-hover: var(--vst-color-danger-dark);
    --vst-color-tag-brand-bg: var(--vst-color-brand-bg);
    --vst-color-tag-brand-border: var(--vst-color-brand-dark);
    --vst-color-tag-brand: var(--vst-color-brand-dark);
    --vst-color-tag-success-bg: var(--vst-color-success-bg);
    --vst-color-tag-success-border: var(--vst-color-success-dark);
    --vst-color-tag-success: var(--vst-color-success-dark);
    --vst-color-tag-info-bg: var(--vst-color-info-bg);
    --vst-color-tag-info-border: var(--vst-color-info);
    --vst-color-tag-info: var(--vst-color-info);
    --vst-color-tag-warning-bg: var(--vst-color-warning-bg);
    --vst-color-tag-warning-border: var(--vst-color-warning);
    --vst-color-tag-warning: var(--vst-color-warning);
    --vst-color-tag-important-bg: var(--vst-color-important-bg);
    --vst-color-tag-important-border: var(--vst-color-important);
    --vst-color-tag-important: var(--vst-color-important);
    --vst-color-tag-danger-bg: var(--vst-color-danger-bg);
    --vst-color-tag-danger-border: var(--vst-color-danger-dark);
    --vst-color-tag-danger: var(--vst-color-danger-dark);
    --vst-color-divider-thumb-bg: var(--vst-color-bg-tertiary);
    --vst-color-divider-thumb-border: var(--vst-color-bg-primary);
    --vst-color-divider-thumb-bg-active: var(--vst-color-fg-secondary);
    --vst-color-logo: #5a5b5d;
    --vst-color-logo-text: var(--vst-color-logo-text-dark);
    --vst-color-logo-text-inverted: var(--vst-color-logo-text-light);
    --vst-color-input-bg: var(--vst-color-bg);
    --vst-color-bg-graph: var(--vst-color-bg);
    --vst-color-calibration-bg: var(--vst-color-success-bg);
    --vst-color-calibration-border: var(--vst-color-success-dark);
    --vst-color-calibration-rsr-bg: rgba(255, 255, 255, 0.7);
    --vst-color-calibration-rsr-border: transparent;
    --vst-color-required-device-disconnected: hsl(47deg 93% 94%);
    --vst-color-selected: var(--vst-color-info-200);

    /* Font Family
    https://css-tricks.com/snippets/css/system-font-stack/ */
    --vst-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
      Cantarell, 'Helvetica Neue', sans-serif;
    --vst-font-family-mono: Consolas, Monaco, monospace;

    /* Font Size */
    /* Clamp prevents font sizes from getting too big or too small when a user changes their default font sizes */
    /* Safari 12 fallback, TODO: convert to clamp when we drop Safari 12 support */
    --vst-font-size-2xs: min(max(6px, 0.5rem), 12px);
    --vst-font-size-xs: min(max(8px, 0.75rem), 18px);
    --vst-font-size-s: min(max(10px, 0.875rem), 22px);
    --vst-font-size-m: min(max(12px, 1rem), 24px);
    --vst-font-size-l: min(max(20px, 1.5rem), 36px);
    --vst-font-size-xl: min(max(24px, 2rem), 48px);
    --vst-font-size-2xl: min(max(32px, 2.5rem), 72px);
    --vst-font-size-3xl: min(max(48px, 4rem), 96px);

    /* Line Height */
    --vst-line-height-s: 1.3;
    --vst-line-height-m: 1.5;

    /* Spacing */
    /* Use px for spacing to differentiate zoom from default font size change and keep ui from getting too big or too small at different font sizes */
    --vst-space-3xs: 2px;
    --vst-space-2xs: 4px;
    --vst-space-xs: 8px;
    --vst-space-s: 12px;
    --vst-space-m: 16px;
    --vst-space-l: 24px;
    --vst-space-xl: 32px;
    --vst-space-2xl: 48px;
    --vst-space-3xl: 64px;

    /* Sizing */
    /* Safari 12 fallback TODO: convert to clamp when we drop Safari 12 support */
    --vst-toolbar-height: min(max(32px, 3rem), 54px);
    --vst-dialog-header-height: min(max(28px, 2.75rem), 48px);
    --vst-required-device-border-size: 5px;

    --vst-icon-size-2xs: min(max(8px, 0.75rem), 16px);
    --vst-icon-size-xs: min(max(14px, 1rem), 20px);
    --vst-icon-size-s: min(max(16px, 1.25rem), 24px);
    --vst-icon-size-m: min(max(20px, 1.5rem), 28px);
    --vst-icon-size-l: min(max(28px, 2rem), 36px);

    /* Transition */
    --vst-transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
    --vst-transition-speed-fast: 0.1s;
    --vst-transition-speed: 0.3s;
    --vst-transition-speed-slow: 0.5s;
    --vst-transition-fast: all var(--vst-transition-speed-fast) var(--vst-transition-easing);
    --vst-transition: all var(--vst-transition-speed) var(--vst-transition-easing);
    --vst-transition-slow: all var(--vst-transition-speed-slow) var(--vst-transition-easing);

    /* Animations */
    --startup-animation-duration: 0.4s;
    --startup-animation-timing-function: cubic-bezier(0.21, 0.1, 0.11, 0.95);
    --startup-animation-base-delay: 0.3s;
    --startup-animation-secondary-delay: calc(
      var(--startup-animation-base-delay) + var(--startup-animation-duration)
    );

    /* Border Radius */
    --vst-radius: 4px;
    --vst-radius-pill: 9999px;
    --vst-radius-circle: 50%;

    /* Shadow */
    --vst-shadow-xs: 0px 1px 1px rgba(0, 0, 0, 0.1);
    --vst-shadow-s: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    --vst-shadow-m: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --vst-shadow-l: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    --vst-shadow-xl: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

    /* Z-Index
(https://philipwalton.com/articles/what-no-one-told-you-about-z-index/) */
    --vst-z-graph-tools-btn: 149;
    --vst-z-toolbar: 100;
    --vst-z-selection: 125;
    --vst-z-annotation: 150;
    --vst-z-infobox: 175;
    --vst-z-graph-legend: 180;
    --vst-z-minigraph: 185;
    --vst-z-popover: 200;
    --vst-z-dialog: 300;
    --vst-z-soft-alert: 400;

    /* Breakpoints -  Used by JS to check what screen size we are */
    --vst-screen-size: xs;

    /* from globals - deprecated */
    --gutter: 0.5625rem;
    --transition-easing: cubic-bezier(0.4, 0, 0.2, 1);

    --transition-default-speed: 0.4s;
    --transition-fastest-speed: 0.125s;
    --transition-faster-speed: 0.25s;
    --transition-slower-speed: 0.6s;
    --transition-default: all var(--transition-default-speed) var(--transition-easing);
    --transition-faster: all var(--transition-faster-speed) var(--transition-easing);
    --transition-slower: all var(--transition-slower-speed) var(--transition-easing);

    --box-shadow-default-rotated: -1px 0 1px rgba(0, 0, 0, 0.1);
    --box-shadow-inset: inset 0 0 1px 1px rgba(0, 0, 0, 0.15);
    --box-shadow-inset-darker: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  }

  ${theme(
    'dark',
    css`
      --vst-color-success-400: hsl(142deg 100% 20%);
      --vst-color-success-300: hsl(135deg 96% 28%);
      --vst-color-success-200: hsl(138deg 90% 35%);
      --vst-color-success-100: hsl(127deg 46% 93%);
      --vst-color-success-bg: var(--vst-color-success-400);

      --vst-color-warning-300: hsl(20, 95%, 68%);
      --vst-color-warning-200: hsl(21, 100%, 77%);
      --vst-color-warning-100: hsl(22, 100%, 86%);

      --vst-color-warning-bg: transparent;

      --vst-color-info-300: #1c3461;
      --vst-color-info-200: #1d4d8c;
      --vst-color-info-100: #b2d8f7;

      /* Danger */
      --vst-color-danger-400: #570f0f;
      --vst-color-danger-300: #a1201b;
      --vst-color-danger-200: #ff4e45;
      --vst-color-danger-100: #fcb6b1;

      --vst-color-fg-primary: var(--vst-color-light-100);
      --vst-color-fg-secondary: var(--vst-color-light-200);
      --vst-color-fg-tertiary: var(--vst-color-light-300);

      --vst-color-bg-primary: var(--vst-color-dark-100);
      --vst-color-bg-secondary: var(--vst-color-dark-200);
      --vst-color-bg-tertiary: var(--vst-color-dark-300);

      --vst-color-bg: var(--vst-color-bg-dark);

      /* graph colors */
      --vst-color-graph-1: var(--vst-color-graph-1-dark);
      --vst-color-graph-2: var(--vst-color-graph-2-dark);
      --vst-color-graph-3: var(--vst-color-graph-3-dark);
      --vst-color-graph-4: var(--vst-color-graph-4-dark);
      --vst-color-graph-5: var(--vst-color-graph-5-dark);
      --vst-color-graph-6: var(--vst-color-graph-6-dark);
      --vst-color-graph-7: var(--vst-color-graph-7-dark);
      --vst-color-graph-8: var(--vst-color-graph-8-dark);

      /* Elements */
      --vst-color-btn-text-bg-hover: var(--vst-color-bg-secondary);
      --vst-color-btn-danger: var(--vst-color-danger-300);
      --vst-color-btn-danger-hover: var(--vst-color-fg-primary);
      --vst-color-btn-danger-bg-hover: var(--vst-color-danger-300);
      --vst-color-toolbar-bg: var(--vst-color-bg-secondary);
      --vst-color-btn-toolbar-bg: var(--vst-color-bg-secondary);
      --vst-color-btn-toolbar-bg-hover: var(--vst-color-bg-primary);
      --vst-color-media-btn-bg-hover: var(--vst-color-bg-primary);
      --vst-color-icon-hover: var(--vst-color-bg-secondary);
      --vst-color-btn-graph-bg: var(--vst-color-bg-secondary);
      --vst-color-btn-graph-border: var(--vst-color-neutral);
      --vst-color-btn-graph-bg-hover: var(--vst-color-bg-primary);
      --vst-color-btn-graph-border-hover: var(--vst-color-bg-primary);
      --vst-color-examine-handle-bg: var(--vst-color-bg-primary);
      --vst-color-prediction-toolbar-bg: var(--vst-color-bg-tertiary);
      --vst-color-sensor-bg: var(--vst-color-bg-primary);
      --vst-color-proximity-connect-bg: transparent;
      --vst-color-proximity-connect-text: var(--vst-color-warning-100);
      --vst-color-connect-pane-bg: transparent;
      --vst-color-connect-pane-text: var(--vst-color-warning-100);
      --vst-color-inner-popover-bg: var(--vst-color-bg-tertiary);
      --vst-grid-selected-background-color: var(--vst-color-bg-primary);
      --vst-grid-selected-border-color: var(--vst-color-neutral);
      --vst-grid-body-background-color: var(--vst-color-bg);
      --vst-grid-border-color: var(--vst-color-neutral);
      --vst-color-sample-video-bg: var(--vst-color-bg-tertiary);
      --vst-color-notes-bg: var(--vst-color-bg-tertiary);
      --vst-color-btn: var(--vst-color-fg-primary);
      --vst-color-btn-bg: var(--vst-color-bg-primary);
      --vst-color-btn-bg-hover: var(--vst-color-bg-secondary);
      --vst-color-btn-bg-active: var(--vst-color-bg-tertiary);
      --vst-color-btn-border: var(--vst-color-neutral);
      --vst-shadow-btn-hover: 0;
      --vst-opacity-disabled: 0.25;
      --vst-color-predictions-overlay-bg: rgba(0, 0, 0, 0.45);
      --vst-color-popover-border: var(--vst-color-neutral);
      --vst-color-text-brand: var(--vst-color-fg-primary);
      --vst-color-text-brand-hover: var(--vst-color-fg-primary);
      --vst-color-text-success: var(--vst-color-success);
      --vst-color-text-success-hover: var(--vst-color-success-dark);
      --vst-color-text-info: var(--vst-color-info-ui);
      --vst-color-text-info-hover: var(--vst-color-info);
      --vst-color-text-warning: var(--vst-color-warning);
      --vst-color-text-warning-hover: var(--vst-color-warning);
      --vst-color-text-important: var(--vst-color-important);
      --vst-color-text-important-hover: var(--vst-color-important-dark);
      --vst-color-text-danger: var(--vst-color-danger-200);
      --vst-color-text-danger-hover: var(--vst-color-danger-100);
      --vst-color-bg-brand: var(--vst-color-brand-bg);
      --vst-color-bg-success: var(--vst-color-success-bg);
      --vst-color-bg-warning: var(--vst-color-warning-bg);
      --vst-color-bg-important: var(--vst-color-important-bg);
      --vst-color-bg-danger: var(--vst-color-danger-bg);
      --vst-color-tag-brand-bg: var(--vst-color-brand);
      --vst-color-tag-brand-border: var(--vst-color-brand);
      --vst-color-tag-brand: var(--vst-color-fg-primary);
      --vst-color-tag-success-bg: var(--vst-color-success);
      --vst-color-tag-success-border: var(--vst-color-success);
      --vst-color-tag-success: var(--vst-color-fg-primary);
      --vst-color-tag-info-bg: var(--vst-color-info);
      --vst-color-tag-info-border: var(--vst-color-info);
      --vst-color-tag-info: var(--vst-color-fg-primary);
      --vst-color-tag-warning-bg: var(--vst-color-warning);
      --vst-color-tag-warning-border: var(--vst-color-warning);
      --vst-color-tag-warning: var(--vst-color-fg-primary);
      --vst-color-tag-important-bg: var(--vst-color-important);
      --vst-color-tag-important-border: var(--vst-color-important);
      --vst-color-tag-important: var(--vst-color-fg-primary);
      --vst-color-tag-danger-bg: var(--vst-color-danger);
      --vst-color-tag-danger-border: var(--vst-color-danger);
      --vst-color-tag-danger: var(--vst-color-fg-primary);
      --vst-color-divider-thumb-bg: var(--vst-color-fg-secondary);
      --vst-color-divider-thumb-border: var(--vst-color-neutral);
      --vst-color-divider-thumb-bg-active: var(--vst-color-bg-primary);
      --vst-color-logo: var(--vst-color-fg-primary);
      --vst-color-logo-text: var(--vst-color-logo-text-light);
      --vst-color-logo-text-inverted: var(--vst-color-logo-text-dark);
      --vst-color-bg-graph: var(--vst-color-bg-secondary);
      --vst-color-calibration-bg: var(--vst-color-bg-secondary);
      --vst-color-calibration-border: var(--vst-color-neutral);
      --vst-color-calibration-rsr-bg: var(--vst-color-bg-secondary);
      --vst-color-calibration-rsr-border: var(--vst-color-neutral);
      --vst-color-input-bg: transparent;
      --vst-dark-outline-color: var(--vst-color-brand-100);
      --vst-color-selected: var(--vst-color-danger-400);
    `,
  )}

  ${breakpoint(
    's',
    css`
      :host,
      html {
        --vst-screen-size: s;
      }
    `,
  )}

  ${breakpoint(
    'm',
    css`
      :host,
      html {
        --vst-screen-size: m;
      }
    `,
  )}

  ${breakpoint(
    'l',
    css`
      :host,
      html {
        --vst-screen-size: l;
      }
    `,
  )}

  ${breakpoint(
    'xl',
    css`
      :host,
      html {
        --vst-screen-size: xl;
      }
    `,
  )}

  ${breakpoint(
    ':host',
    's',
    css`
      :host,
      html {
        --vst-space-l: 2rem;
      }
    `,
  )}
`;
