import * as platform from './PLATFORM_ID';
import { RPCService, RPCServiceClient } from './wasm.js';

export async function createRPCModule() {
  if (platform.init) {
    return platform.init();
  }
  if (window.__isSessionClient) return new RPCServiceClient();
  return new RPCService();
}
