import EventEmitter from 'eventemitter3';

let nextId = 0;

export class DataShareView extends EventEmitter {
  constructor({
    apiId = '',
    baseColumnId = '',
    baseMax = 10,
    baseMin = 0,
    meterColumnId = '', // used for meters only
    connectLines = true,
    leftTraceColumnIds = [],
    leftTraceMax = 10,
    leftTraceMin = 0,
    id = nextId++,
    viewType = '',
    title = '',
    drawLines = null,
    drawPoints = null,
    graphType = '',
    rightTraceColumnIds = [],
    rightTraceMax = 10,
    rightTraceMin = 0,
  }) {
    super();
    this._apiId = apiId;
    this._baseColumnId = baseColumnId;
    this._baseMax = baseMax;
    this._baseMin = baseMin;
    this._meterColumnId = meterColumnId;
    this._connectLines = connectLines;
    this._leftTraceColumnIds = leftTraceColumnIds;
    this._leftTraceMax = leftTraceMax;
    this._leftTraceMin = leftTraceMin;
    this._id = id;
    this._viewType = viewType;
    this._title = title;
    this._drawLines = drawLines;
    this._drawPoints = drawPoints;
    this._graphType = graphType;
    this._rightTraceColumnIds = rightTraceColumnIds;
    this._rightTraceMax = rightTraceMax;
    this._rightTraceMin = rightTraceMin;
  }

  // emits
  update(props) {
    Object.keys(props).forEach(key => {
      if (this[key] !== props[key]) {
        this[key] = props[key];
      }
    });
  }

  get apiId() {
    return this._apiId;
  }

  get baseColumnId() {
    return this._baseColumnId;
  }

  set baseColumnId(baseColumnId) {
    this._baseColumnId = baseColumnId;
    this.emit('baseColumnId-changed', baseColumnId);
  }

  get baseMax() {
    return this._baseMax;
  }

  set baseMax(baseMax) {
    this._baseMax = baseMax;
    this.emit('baseMax-changed', baseMax);
  }

  get baseMin() {
    return this._baseMin;
  }

  set baseMin(baseMin) {
    this._baseMin = baseMin;
    this.emit('baseMin-changed', baseMin);
  }

  get meterColumnId() {
    return this._meterColumnId;
  }

  set meterColumnId(meterColumnId) {
    this._meterColumnId = meterColumnId;
    this.emit('meterColumnId-changed', meterColumnId);
  }

  get connectLines() {
    return this._connectLines;
  }

  set connectLines(connectLines) {
    this._connectLines = connectLines;
    this.emit('connectLines-changed', connectLines);
  }

  get drawLines() {
    return this._drawLines;
  }

  set drawLines(drawLines) {
    this._drawLines = drawLines;
    this.emit('trace-drawing-style-changed', { drawLines, drawPoints: this._drawPoints });
  }

  get drawPoints() {
    return this._drawPoints;
  }

  set drawPoints(drawPoints) {
    this._drawPoints = drawPoints;
    this.emit('trace-drawing-style-changed', { drawPoints, drawLines: this._drawLines });
  }

  get graphType() {
    return this._graphType;
  }

  set graphType(newType) {
    this._graphType = newType;
    this.emit('graphType-changed', newType);
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
    this.emit('id-changed');
  }

  get leftTraceColumnIds() {
    return this._leftTraceColumnIds;
  }

  set leftTraceColumnIds(leftTraceColumnIds) {
    this._leftTraceColumnIds = leftTraceColumnIds;
    this.emit('leftTraceColumnIds-changed', leftTraceColumnIds);
  }

  get leftTraceMax() {
    return this._leftTraceMax;
  }

  set leftTraceMax(leftTraceMax) {
    this._leftTraceMax = leftTraceMax;
    this.emit('leftTraceMax-changed', leftTraceMax);
  }

  get leftTraceMin() {
    return this._leftTraceMin;
  }

  set leftTraceMin(leftTraceMin) {
    this._leftTraceMin = leftTraceMin;
    this.emit('leftTraceMin-changed', leftTraceMin);
  }

  get rightTraceColumnIds() {
    return this._rightTraceColumnIds;
  }

  set rightTraceColumnIds(value) {
    this._rightTraceColumnIds = value;
    this.emit('right-trace-column-ids-changed', value);
  }

  get rightTraceMax() {
    return this._rightTraceMax;
  }

  set rightTraceMax(rightTraceMax) {
    this._rightTraceMax = rightTraceMax;
    this.emit('right-trace-max-changed', rightTraceMax);
  }

  get rightTraceMin() {
    return this._rightTraceMin;
  }

  set rightTraceMin(rightTraceMin) {
    this._rightTraceMin = rightTraceMin;
    this.emit('right-trace-min-changed', rightTraceMin);
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
    this.emit('title-changed', title);
  }

  get viewType() {
    return this._viewType;
  }

  set viewType(viewType) {
    this._viewType = viewType;
    this.emit('viewType-changed', viewType);
  }
}
