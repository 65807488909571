/**
 * returns an object array for VAs two graph setup
 * @name computeGraphLayout
 * @returns {array}
 */
export const computeGraphLayout = dataWorld => {
  const columns = dataWorld.getColumns();
  const baseColumn = columns.find(c => c.type === 'time');
  const baseColumnId = baseColumn ? baseColumn.id : null;
  const graphOneLeftColumns = columns.filter(c => c.type !== 'time' && c.type !== 'calc');
  const graphTwoLeftColumns = columns.filter(c => c.type !== 'time' && c.type === 'calc');
  const graphOneLeftColumnIds = Array.from(new Set(graphOneLeftColumns.map(c => c.id)));
  const graphTwoLeftColumnIds = Array.from(new Set(graphTwoLeftColumns.map(c => c.id)));

  return [
    {
      baseColumnId,
      leftColumnIds: graphOneLeftColumnIds,
      options: {
        appearance: {
          lines: false,
          points: true,
        },
        baseRange: { min: 0, max: 3 },
        leftRange: { min: 0, max: 3 },
      },
    },
    {
      baseColumnId,
      leftColumnIds: graphTwoLeftColumnIds,
      options: {
        appearance: {
          lines: false,
          points: true,
        },
        baseRange: { min: 0, max: 3 },
        leftRange: { min: 0, max: 3 },
      },
    },
  ];
};
