import { uniqueId } from 'lodash-es';
import { getNotchWidth } from '@utils/notch.js';

// const verboseLogging = false;
// Porting of ye ol popover manager code
export class PopoverManagerApi {
  // this is a singleton created once by the popover-manager service
  constructor(toast, enabledContent) {
    this.toast = toast;
    this.enabledContent = enabledContent;
    this.popovers = [];
    this.anchoredPopovers = [];
    this._popoverWrapperEl = undefined;

    const reposition = () => {
      for (let i = 0; i < this.popovers.length; ++i) {
        PopoverManagerApi.positionPopover(this.popovers[i]);
      }
    };

    window.addEventListener('resize', reposition);

    this.resizeObserver = new ResizeObserver(reposition);
  }

  async create(elName, config = {}) {
    const { toast, enabledContent } = this;

    console.assert(elName);

    if (!this._popoverWrapperEl) {
      console.warn('no popover wrapper attached. Fallback to standard div');
      const popoverParent = document.querySelector('#app') || document.body;
      const wrapper = document.createElement('div');
      wrapper.id = 'popover_wrapper';
      popoverParent.appendChild(wrapper);
      this._popoverWrapperEl = wrapper;
    }

    const childEl = document.createElement(elName); // NOTE: caller is responsible for ensuring the component was imported

    if (!customElements.get(childEl.tagName.toLowerCase())) {
      // element isn't registered as a custom element
      throw Error(
        `Possible issue with childEl import verify spelling and that this ${elName} component is being imported`,
      );
    }

    const popoverConfig = config.popover || {};
    await import('@components/vst-core-popover/vst-core-popover.js');
    const popoverEl = document.createElement('vst-core-popover');

    this.resizeObserver.observe(childEl);

    if (config.preventCancel) {
      popoverEl.canClose = false;
    }

    if (config.preventCancelFromScreen) {
      popoverEl.canCloseFromScreen = false;
    }

    if (config.properties) {
      const { properties } = config;
      Object.keys(properties).forEach(propertyKey => {
        childEl[propertyKey] = properties[propertyKey];
      });
    }

    popoverEl.setPopoverChild(childEl, popoverConfig);
    this._popoverWrapperEl.appendChild(popoverEl);

    await popoverEl.updateComplete;

    popoverEl.shadowRoot.querySelector('#popover').style.visibility = 'hidden'; // hide the childEl until we position it properly below

    const id = uniqueId();
    const popoverObj = {
      id,
      popoverEl,
      childEl,
      config,
      onRemove: () => {},
      remove: () => {
        popoverObj.onRemove();
        popoverEl.remove();
        this.resizeObserver.unobserve(childEl);
      },
    };

    this.popovers.push(popoverObj);

    this._popoverWrapperEl.dispatchEvent(
      new CustomEvent('popover-creation', {
        bubbles: true,
        composed: true,
        detail: {
          id,
          title: config.popover.header?.title || elName,
        },
      }),
    );

    requestAnimationFrame(() => {
      setTimeout(() => {
        PopoverManagerApi.positionPopover(popoverObj);

        popoverEl.shadowRoot.querySelector('#popover').style.visibility = '';

        const { outsideTapEvents = {} } = config;
        const clickEvent =
          typeof outsideTapEvents.click === 'string' ? outsideTapEvents.click : 'click';

        if (config.popover.type !== 'dialog') {
          let clickedInPopover = false;

          const listener = () => {
            clickedInPopover = true;
            setTimeout(() => {
              clickedInPopover = false;
            });
          };

          childEl.addEventListener(clickEvent, listener, true);

          popoverEl.bind(document.querySelector('#app'), clickEvent, () => {
            if (!clickedInPopover && !config.preventCancel) {
              popoverEl.dispatchEvent(new CustomEvent('close'));
              childEl.removeEventListener(clickEvent, listener, true);
            }
            clickedInPopover = false;
          });
        }

        const elsToExclude = [popoverEl, toast.element]; // we never want toasts to be restricted, as they can come up in many different scenarios
        const chromeBarEl = document.querySelector('vst-ui-chromebar');
        if (chromeBarEl) {
          elsToExclude.push(chromeBarEl); // make sure the chrome packaged app top bar doesn't get restricted.
        }
        // If there are soft alerts defined at the app level then exlcude them too.
        const softAlertEl = document
          .querySelector('#app')
          .shadowRoot.querySelectorAll('vst-ui-soft-alert');
        softAlertEl.forEach(e => {
          elsToExclude.push(e);
        });
        const removeHandler =
          config.popover.type === 'dialog'
            ? enabledContent.restrict(elName, elsToExclude)
            : enabledContent.extend(elName, elsToExclude);

        popoverObj.onRemove = () => removeHandler();
      });
    });

    return popoverEl; // pass the popoverEl back in case you want to assign popover.create as a variable
  }

  present(
    componentName,
    {
      popover,
      preventCancel,
      preventCancelFromScreen,
      outsideTapEvents,

      // element arguments
      properties,
      events,
    },
  ) {
    const { anchoredPopovers } = this;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve, reject) => {
      let popoverEl;

      const once = fun => {
        let current = fun;
        return (...args) => {
          current(...args);
          current = () => {};
        };
      };

      const currentAnchor = anchoredPopover => anchoredPopover.anchor === popover.anchor;

      const cleanupOnce = once(() => {
        if (popoverEl) {
          this.destroy(popoverEl);
        }

        const index = anchoredPopovers.findIndex(currentAnchor);
        if (index !== -1) {
          anchoredPopovers.splice(index, 1);
        }
      });

      const rejectOnce = once(err => {
        cleanupOnce();
        reject(err);
      });

      const resolveOnce = once(args => {
        cleanupOnce();
        resolve(args);
      });

      const resolveCancelOnce = once(reason =>
        resolveOnce({
          cancelled: true,
          reason,
          message: `${componentName} popover cancelled. Reason: ${reason}.`,
        }),
      );

      try {
        (async () => {
          if (popover.type !== 'dialog' && popover.anchor) {
            const previousAnchoredPopover = anchoredPopovers.find(currentAnchor);
            if (previousAnchoredPopover) {
              previousAnchoredPopover.close();
              resolveCancelOnce('toggle-existing-popover');
              return;
            }

            const anchoredPopover = {
              anchor: popover.anchor,
              close: () => {
                resolveCancelOnce('popover-toggled-close');
              },
            };

            anchoredPopovers.push(anchoredPopover);
          }

          popoverEl = await this.create(
            componentName,
            {
              popover,
              preventCancel,
              preventCancelFromScreen,
              outsideTapEvents,
              properties,
            },
            false,
          );

          const componentEl = popoverEl.querySelector(componentName);
          const completeWorkflow = arg => {
            componentEl.dispatchEvent(new CustomEvent('complete-workflow', { detail: arg }));
          };

          const cancelWorkflow = () => {
            componentEl.dispatchEvent(new CustomEvent('cancel-workflow'));
          };
          const failWorkflow = arg => {
            componentEl.dispatchEvent(new CustomEvent('fail-workflow', { detail: arg }));
          };

          const eventSetupArgs = {
            component: componentEl,
            completeWorkflow,
            cancelWorkflow,
            failWorkflow,
          };

          const selectedEvents = typeof events === 'function' ? events(eventSetupArgs) : events;

          if (selectedEvents) {
            Object.keys(selectedEvents).forEach(eventName => {
              popoverEl.bind(componentEl, eventName, (...args) => {
                selectedEvents[eventName](...args);
              });
            });
          }

          popoverEl.bind(componentEl, 'complete-workflow', args => resolveOnce(args.detail));

          popoverEl.bind(componentEl, 'fail-workflow', args => rejectOnce(args.detail));

          popoverEl.bind(componentEl, 'cancel-workflow', () =>
            resolveCancelOnce('workflow-cancelled'),
          );

          popoverEl.bind('close', () => resolveCancelOnce('popover-closed'));
        })();
      } catch (e) {
        rejectOnce(e);
      }
    });
  }

  destroy(popoverEl) {
    try {
      const index = this.popovers.findIndex(popoverObj => popoverObj.popoverEl === popoverEl);
      const popoverObj = this.popovers.splice(index, 1)[0];
      const { id } = popoverObj;
      popoverObj.remove();

      this._popoverWrapperEl.dispatchEvent(
        new CustomEvent('popover-removal', {
          bubbles: true,
          composed: true,
          detail: {
            id,
            title: popoverObj.config.popover.header?.title || popoverObj.childEl,
          },
        }),
      );
    } catch (error) {
      console.warn(error);
    }
  }

  destroyAll() {
    this.popovers.map(obj => obj.popoverEl).forEach(popoverEl => popoverEl.close());

    this.popovers = []; // reset popovers array
  }

  popPopover() {
    const popoverObj = this.popovers.slice(-1)[0];
    return popoverObj ? this.destroy(popoverObj.popoverEl) : console.warn('no popovers to pop');
  }

  hasPopovers() {
    return this.popovers.length > 0;
  }

  hasDialogs() {
    let hasDialog = false;

    if (this.hasPopovers()) {
      this.popovers.forEach(popover => {
        if (popover.config.popover.type === 'dialog') {
          hasDialog = true;
        }
      });
    }

    return hasDialog;
  }

  getPopoverSelectorDebugInfo() {
    const { popoverSelector } = this;
    return popoverSelector.getDebugInfo();
  }

  static positionPopover(popObj) {
    const config = popObj.config.popover;
    const { popoverEl } = popObj;
    const { childEl } = popObj;

    const type = config.type || 'popover'; // default to popover

    const wrapper = popObj.popoverEl.shadowRoot.querySelector('#popover');

    let orientation = 'CENTER';
    // dialog
    if (type === 'dialog') {
      popoverEl.classList.add('dialog');
    } else {
      wrapper.classList.add(type);

      // TODO: cleanup
      if (type === 'contextmenu') {
        popObj.popoverEl.addEventListener('contextmenu', e => {
          popoverEl.cancelClicked();
          e.preventDefault();
        });
      }

      if (config.orientation) {
        orientation = config.orientation.toUpperCase();
      }

      const windowWidth = document.documentElement.clientWidth;
      const windowHeight = document.documentElement.clientHeight;

      const anchor = config.anchor || document.body;
      const anchorRect = anchor.getBoundingClientRect();
      const anchorCenter = anchorRect.width / 2; // horizontal center
      const anchorMiddle = anchorRect.height / 2; // vertical center
      // console.log('anchorRect');
      // console.log(anchorRect);

      let childRect = childEl.getBoundingClientRect();
      const childCenter = childRect.width / 2; // horizontal center
      const childMiddle = childRect.height / 2; // vertical center
      // console.log('childRect');
      // console.log(childRect);

      const padding = 8; // TODO: Can't get a proper width or height using css borders to create triangle. Hard coding for now.

      // let chromeMenubarHeight = 0;

      // if (this.configuration.platform.name === 'Chrome') {
      //   chromeMenubarHeight = parseInt(getComputedStyle(document.querySelector('#app').getPropertyValue('--chrome-menubar-height'), 10);
      // }

      let verifyOrientation;

      const setXY = (orientation, finished) => {
        const position = {};
        let offsetTop = 0;

        switch (orientation) {
          case 'TOP':
            position.x = anchorRect.left + anchorCenter - childCenter;
            position.y = anchorRect.top - childRect.height - (padding + 1);
            break;
          case 'BOTTOM':
            position.x = anchorRect.left + anchorCenter - childCenter;
            position.y = anchorRect.bottom + padding - 1;
            break;
          case 'RIGHT':
            position.x = anchorRect.right + padding;
            position.y = anchorRect.top - childMiddle + anchorMiddle;
            break;
          case 'LEFT':
            position.x = anchorRect.left - childRect.width - padding;
            position.y = anchorRect.top - childMiddle + anchorMiddle;
            break;
          case 'CENTER':
            position.x = windowWidth / 2 - childCenter;
            position.y = windowHeight / 2 - childMiddle;
            break;
          default:
            console.warn('no popover orientation hint - use TOP, LEFT, RIGHT, BOTTOM, or CENTER');
            break;
        }

        if (type === 'contextmenu') {
          position.x = config.extras.x; // TODO: clean this up
          position.y = config.extras.y;
        }

        if ('visualViewport' in window) {
          offsetTop = window.visualViewport.offsetTop;
        }

        // set the position on the content
        wrapper.style.left = `${position.x}px`;
        // compensate for open soft keyboards
        wrapper.style.top = `${position.y + offsetTop}px`;

        if (!finished) {
          verifyOrientation();
        }
      };

      verifyOrientation = () => {
        childRect = childEl.getBoundingClientRect();

        if (windowWidth < childRect.width) {
          orientation = 'CENTER';
          setXY(orientation, true);
        } else if (orientation === 'LEFT' && childRect.left < 0) {
          if (windowWidth - anchorRect.right < childRect.width) {
            orientation = 'CENTER';
          } else {
            orientation = 'RIGHT';
          }
          setXY(orientation, true);
        } else if (orientation === 'RIGHT' && childRect.right >= windowWidth) {
          if (anchorRect.left < childRect.width) {
            orientation = 'CENTER';
          } else {
            orientation = 'LEFT';
          }
          setXY(orientation, true);
        }
      };

      setXY(orientation);

      wrapper.classList.add(orientation.toLowerCase());

      // check if if the content is within the window bounds and ajust accordingly
      const popoverBounds = wrapper.getBoundingClientRect();
      const gutter = 4;

      if (popoverBounds.top <= 0) {
        wrapper.style.top = `${gutter}px`;
      }
      if (popoverBounds.right >= windowWidth) {
        wrapper.style.left = `${windowWidth - popoverBounds.width - gutter}px`;
      }
      if (popoverBounds.left <= 0) {
        wrapper.style.left = `${gutter}px`;
      }
      if (popoverBounds.bottom >= windowHeight) {
        wrapper.style.top = `${windowHeight - popoverBounds.height - gutter}px`;
      }

      // iOS notch fixes https://www.quirksmode.org/blog/archives/2017/10/safeareainset_v.html
      const notchWidth = getNotchWidth();
      if (parseFloat(wrapper.style.left) <= gutter + notchWidth) {
        wrapper.style.left = `calc(${notchWidth}px + ${wrapper.style.left})`;
      }
      if (
        windowWidth - popoverBounds.width - parseFloat(wrapper.style.left) <=
        gutter + notchWidth
      ) {
        wrapper.style.left = `calc(${
          windowWidth - popoverBounds.width - gutter
        }px - ${notchWidth}px)`;
      }
      // End iOS notch
    }
  }
}
