import ally from 'ally.js';

function create() {
  const noop = () => {};
  const restrictions = [];
  let handle = null;

  // helper methods
  const currentTopRestriction = () =>
    restrictions.length > 0 ? restrictions[restrictions.length - 1] : null;

  const refreshFocus = () => {
    if (handle) {
      handle.disengage();
    }

    handle = null;

    const topRestriction = currentTopRestriction();
    if (!topRestriction) {
      return;
    }

    const { filters, extensions } = topRestriction;

    const totalEls = filters.slice();

    extensions.forEach(extension => {
      extension.filters.forEach(filter => totalEls.push(filter));
    });

    handle = ally.maintain.disabled({ filter: totalEls });
  };

  const removeRestriction = restriction => {
    const restrictionIndex = restrictions.indexOf(restriction);
    if (restrictionIndex !== -1) {
      restrictions.splice(restrictionIndex);
    }
    refreshFocus();
  };

  const addRestrictionContext = restriction => {
    restrictions.push(restriction);
    refreshFocus();
  };

  const getFilterList = elements => {
    const collectedEls = [];

    const forEachElement = (nodes, fn) => {
      let node;
      // eslint-disable-next-line no-restricted-syntax
      for (node of nodes) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          fn(node);
        }
      }
    };

    const getShadowRoots = el => {
      const root = el.shadowRoot;
      if (root) {
        collectedEls.push(root);
        getShadowRoots(root);
      }

      forEachElement(el.children, getShadowRoots);
    };

    forEachElement(elements, el => {
      collectedEls.push(el);
      getShadowRoots(el);
    });

    return collectedEls;
  };

  // factory for restriction contexts
  const createRestriction = (name, targets = []) => {
    const extensions = [];
    const filters = getFilterList(targets);

    const restriction = {
      name,
      targets,
      filters,
      extensions,

      addExtension: extension => {
        extensions.push(extension);
        refreshFocus();
      },
      removeExtension: extension => {
        const extensionIndex = extensions.indexOf(extension);
        if (extensionIndex !== -1) {
          extensions.splice(extensionIndex);
        }
      },
      remove: () => {
        restriction.filters = null;
        restriction.extensions = null;
        restriction.refreshFocus = noop;
        restriction.addExtension = noop;
        restriction.removeExtension = noop;
        restriction.remove = noop;
        removeRestriction(restriction);
      },
    };

    addRestrictionContext(restriction);
    return restriction;
  };

  // factory for extension context
  const createExtension = (restriction, name, targets) => {
    const filters = getFilterList(targets);

    const extensionContext = {
      name,
      targets,
      filters,
      remove: () => {
        restriction.removeExtension(extensionContext);
        extensionContext.filters = null;
        extensionContext.remove = noop;
      },
    };

    restriction.addExtension(extensionContext);

    return extensionContext;
  };

  // selector api
  return {
    getDebugInfo: () =>
      restrictions.map(({ name, targets, filters, extensions }) => ({
        name,
        targets,
        filters,
        extensions: extensions.map(({ name, targets, filters }) => ({
          name,
          targets,
          filters,
        })),
      })),
    restrict: (name, filters) => {
      const context = createRestriction(name, filters);
      return () => {
        context.remove();
      };
    },
    extend: (name, filters) => {
      const currentRestriction = currentTopRestriction();
      if (!currentRestriction) {
        return noop;
      }

      const context = createExtension(currentRestriction, name, filters);

      return () => {
        context.remove();
      };
    },
  };
}

export const enabledContent = {
  create,
};
