import { launchQueueHandler } from '@services/adapters/launch-queue/index.js';
import * as platform from './PLATFORM_ID';

export const createStartupFileHandler = (dataWorld, fileIO, toast) => {
  if (platform.createStartupFileHandler) {
    return platform.createStartupFileHandler(dataWorld, fileIO, toast);
  }

  return launchQueueHandler;
};
