let fileIOServices = {};

export const onSaveCallbacks = new Set();

export const notifyFileSaved = filename => {
  onSaveCallbacks.forEach(cb => cb(filename));
};

export const setFileIOServices = services => {
  fileIOServices = services;
};

export const getFileIOServices = () => fileIOServices;
