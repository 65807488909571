// keep in sync with VSTAnalysis library: enum VSTAnalysis_ErrorType
export const errorType = {
  NO_ERROR: 0,
  TOO_FEW_POINTS: 1, // User asked for fit with fewer points than coefficients
  EXCEPTION: 2, // Catch all exceptions in curve fit library and return this error (INF or NAN computation?)
  BAD_PARAMETER: 3, // fitType not right?  degree on polynomial not right?
  BAD_CHARACTER: 4, // Custom equation has an illegal character (e.g. ";")
  PARSE: 5, // Could not parse custom equation
  MISSING_COEFFICIENT: 6, // Custom equation has no coefficients
  MISSING_X: 7, // Custom equation has no variable
  TOO_MANY_COEFFICIENTS: 8, // Custom equation has too many coefficients
  BAD_ARGUMENT: 9, // Some parameter was wrong (size < 0, NULL where not optional etc.)
  DELTA_ZERO: 10, // Points passed in to FFT have no x-delta
  BAD_HANDLE: 11, // Handle to user-defined equation is not valid
  BAD_COEFFICIENT_INDEX: 12, // Caller asked for an index that doesn't exist
};
