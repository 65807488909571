import { observable, computed, makeObservable, observe } from 'mobx';

// BlockInfo is a mobx store that contains information for initializing and configuring a Block.
// It's the principal unit of persistence for all information pertaining to a block.

// Version history:
//  - 1.0.0 intitial development.
const CURRENT_VERSION = '1.0.0';

export class BlockInfo {
  /**
   * Construct BlockInfo store
   * @param {BlockInfoConfig} values generic javascript object.
   */
  constructor(values = {}) {
    this._blockId = values.blockId;
    this._version = CURRENT_VERSION;

    this.showsTable = values.showsTable ?? false;
    this.graphIds = values.graphIds ?? [];
    this.numberOfGraphs = values.numberOfGraphs ?? (values.type === 'graph' ? 1 : 0);
    this.meterIds = values.meterIds ?? [];
    this.meterSize = values.meterSize ?? '';
    this.type = values.type;
    this.layout = values.layout ?? {};

    // (TODO) Eventually add table information

    this._observers = [];

    makeObservable(this, {
      showsGraphs: computed,
      showsTable: observable,
      numberOfGraphs: observable, // Number of graphs is 1, 2, or 3.
      graphIds: observable,
      meterIds: observable,
      meterSize: observable,
      type: observable,
      layout: observable,
    });
  }

  get showsGraphs() {
    return this.numberOfGraphs > 0;
  }

  /**
   * Apply value changes to the info object.
   * @param {BlockInfoConfig} values generic javascript object
   */
  importValues({ version, showsTable, graphIds, meterIds, meterSize, layout }) {
    // Handle differences in versions (to be continued).
    if (version !== this.version) {
      console.warn(
        `Attempting to import BlockInfo version ${this.version} from version ${version}`,
      );
    }

    if (showsTable !== undefined) this.showsTable = showsTable;
    if (graphIds !== undefined) this.graphIds = graphIds;
    if (meterIds !== undefined) this.meterIds = meterIds;
    if (meterSize !== undefined) this.meterSize = meterSize;
    if (layout !== undefined) this.layout = layout;
  }

  /**
   * Export settings as a generic javascript object.
   * @returns {Object} a generic javascript object suitable for persistence or broadcast.
   */
  exportValues() {
    return {
      blockId: this.blockId,
      version: this.version,
      showsTable: this.showsTable,
      graphIds: [...new Set(this.graphIds)], // eliminate duplicates
      numberOfGraphs: this.numberOfGraphs,
      meterIds: [...new Set(this.meterIds)], // eliminate duplicates
      meterSize: this.meterSize,
      type: this.type,
      layout: this.layout,
    };
  }

  /**
   * @returns {String} unique identifier for this block.
   */
  get blockId() {
    return this._blockId;
  }

  /**
   * @returns {String} version string of the form "1.2.3"
   */
  get version() {
    return this._version;
  }

  /**
   * @returns {Array<String>} array containing graph names as used by layout store & vst-core-graph et al.
   */
  static get GRAPH_NAMES() {
    return ['graph_1', 'graph_2', 'graph_3'];
  }

  /**
   * [Experimental] Observe all changes to observable properties.
   * @param {*} action a function that receives a mobx `change` event.
   * @see https://mobx.js.org/intercept-and-observe.html
   */
  addObserver(action) {
    const disposer = observe(this, () => {
      action(this.exportValues());
    });

    this._observers.push(disposer);
  }

  /**
   * Stop observing all changes for this object.
   */
  stopObserving() {
    this._observers.forEach(dispose => dispose());
    this._observers = [];
  }
}

/**
 * @typedef {Object} BlockInfoConfig
 * @property {String} [blockId] unique identifier for this block.
 * @property {String} [version] version string of the form "1.2.3"
 * @property {Boolean} [showsTable] whether to show table.
 * @property {Array<String>} [graphIds] array of graph ids.
 * @property {Array<String>} [meterIds] array of meter ids.
 * @property {String} [meterSize] size of the meter.
 * @property {String} [type] type of block.
 * @property {Object} [layout] layout settings for the block.
 */
