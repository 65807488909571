import EventEmitter from 'eventemitter3';

export class NativeModulesAPI extends EventEmitter {
  constructor(rpc) {
    super();
    this.rpc = rpc;
  }

  /**
   *
   * @param {object} config
   * @param {object} config.sensorMap - Parsed sensormap object
   * @param {object} config.featureFlags - Parsed feature flags object
   * @param {object=} config.firmwareInfo - Firmware updater object, only required if native code was built with ENABLE_FIRMWARE_UPDATER defined
   * @param {object=} config.deviceServerPorts - USB and BLE device server ports (required by Native Client)
   * @param {string} config.deviceServerPorts.usb - USB device server port
   * @param {string} config.deviceServerPorts.bluetooth - BLE device server port
   */
  init(config) {
    return this.rpc.sendRequest('init', config);
  }

  /**
   *
   */
  uninit(params) {
    return this.rpc.sendRequest('uninit', params);
  }

  /**
   *
   * @param {Object} params
   * @param {Boolean} params.enabled - whether logging should be enabled
   * @param {Number} params.level - default verbosity level [0-7],
   * @param {Object} params.domains - dictionary of domain:level pairs to override verbosity level for individual domains. A full list of valid domain names
   * can be obtained from the call to loggingGetConfig().
   */
  loggingSetConfig(params) {
    return this.rpc.sendRequest('logging-set-config', params);
  }

  /*
   * Returns a dictionary in the format described for loggingSetConfig(),
   * so it can be modified in place and passed back to loggingSetConfig().
   *
   * The returned domains dictionary contains entries for all available logging
   * domains, so it can be used to populate a suitable UI.
   */
  loggingGetConfig(params) {
    return this.rpc.sendRequest('logging-get-config', params);
  }

  /**
   * Requests that the backend prints out build info
   *
   * @param {Number} params.level : logging level to use, optional (defaults to 4, NOTICE)
   */
  loggingPrintBuildInfo(params) {
    return this.rpc.sendRequest('logging-print-build-info', params);
  }
}
