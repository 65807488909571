import { LitElement, html, css } from 'lit';
import platform from 'platform';

import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { getText } from '@utils/i18n.js';
import { externalLink, importVideoLarge, offline, download, play } from '@icons';

import { globalStyles } from '@styles/vst-style-global.css.js';

import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';

import { vaWelcomeStyles } from './va-welcome.css.js';
import { sampleVideos } from './sampleVideos.js';

import '@components/vst-ui-file-import/vst-ui-file-import.js';
import '@components/vst-ui-spinner/vst-ui-spinner.js';
import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-logo-vernier/vst-ui-logo-vernier.js';
import '@components/vst-style-spinner/vst-style-spinner.js';
import '@components/vst-core-external-import/vst-core-external-import.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';

const VIDEO_IMPORT_ACCEPTS = [
  'video/mp4',
  'video/x-m4v',
  'video/ogg',
  'video/x-matroska',
  'video/webm',
  'video/*',
  '.ogg',
  '.mp4',
  '.mp4v',
  '.mpg4',
  '.mkv',
  '.mov',
  '.webm',
];

function handleSampleVideoDownloadProgress({ detail: { progress }, currentTarget }) {
  const progressEl = currentTarget.querySelector('.sample-video__progress');
  progressEl.style.width = `${progress * 100}%`;
}

function computeSampleVideoIcon(video) {
  if (video.status === 'uncached' && navigator.onLine) return download;
  if (video.status === 'cached') return play;
  if (video.status !== 'cached' && !navigator.onLine) return offline;
  return download;
}

function computeSampleVideoIconColor(video) {
  return video.status !== 'cached' && navigator.onLine
    ? 'var(--vst-color-brand)'
    : 'var(--vst-color-fg-primary)';
}

class VaWelcome extends Requester(LitElement) {
  static get properties() {
    return {
      dataCollectionError: {
        type: String,
      },
      isLoadingSampleVideo: {
        state: true,
      },
      showInfo: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      globalStyles,
      vaWelcomeStyles,
      css`
        .btn[variant='outline'] {
          border-radius: var(--vst-radius) !important;
          white-space: normal;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.dataCollectionError = '';
    this.isLoadingSampleVideo = false;
    this.showInfo = false;
    this.sampleVideos = sampleVideos;

    this.hasNativeFileSystem =
      isFeatureFlagEnabled('ff-web-native-file-system') && 'chooseFileSystemEntries' in window;
    this.handleOnlineChange = () => this.requestUpdate();
  }

  firstUpdated() {
    // TODO: the following is a temp hack to work around a serious rendering issue on iOS 13.4 when scrollbars are attempted to be set to display: block.
    if (platform.os.family === 'OS X' && navigator.maxTouchPoints > 0) {
      this.style.setProperty('--scrollbar-display', 'none');
    }

    [this.$dataWorld, this.$popoverManager] = this.requestServices(['dataWorld', 'popoverManager']);

    const btnEls = Array.from(this.shadowRoot.querySelectorAll('button'));
    const linkEls = Array.from(this.shadowRoot.querySelectorAll('a'));
    this.clickableEls = btnEls.concat(linkEls);
    this.headerEls = Array.from(this.shadowRoot.querySelectorAll('.section-header'));
    this.logoEl = this.shadowRoot.querySelector('#vernier_logo');
    this.fileImportEl = this.shadowRoot.querySelector('#file_import');

    this.isSessionReady = this.$dataWorld.session;
    this._bindHandleDelayedImport = this._handleDelayedImport.bind(this);

    if (!this.isSessionReady) {
      this.$dataWorld.once('session-started', this._bindHandleDelayedImport);
    }

    this.checkSampleCache();
  }

  async checkSampleCache() {
    const cachedVideos = this.sampleVideos.map(video => caches.match(video.url));
    const videoStatuses = await Promise.all(cachedVideos);
    this.sampleVideos.forEach((video, index) => {
      video.status = videoStatuses[index] ? 'cached' : 'uncached';
    });
    this.requestUpdate();
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('offline', this.handleOnlineChange);
    window.addEventListener('online', this.handleOnlineChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.$dataWorld.off('session-started', this._bindHandleDelayedImport);
    this.requestUpdate();
    window.removeEventListener('offline', this.handleOnlineChange);
    window.removeEventListener('online', this.handleOnlineChange);
  }

  _handleDelayedImport() {
    this.isSessionReady = true;
    if (this._videoImportEvent) {
      this._handleImportVideo(this._videoImportEvent);
    }
  }

  handleSampleVideoDownloadAndImport(event, video) {
    if (!window.wasmModuleReady)
      document.addEventListener('wasm-ready', () => this._handleLoadSampleVideo(event, video), {
        once: true,
      });
    else this._handleLoadSampleVideo(event, video);
  }

  async handleSampleVideoDownload(event, video) {
    if (video.status !== 'uncached') return;
    event.stopPropagation();

    try {
      const importEl = event.target
        .closest('.sample-video')
        .querySelector('vst-core-external-import');
      this.isLoadingSampleVideo = true;
      video.loading = true;
      this.requestUpdate();
      await importEl.triggerFetch();
    } finally {
      this.isLoadingSampleVideo = false;
      video.loading = false;
      this.requestUpdate();
      this.disableButtons(false);
    }
  }

  async _handleLoadSampleVideo(event, video) {
    this.disableButtons(true);
    video.loading = true;
    this.requestUpdate();

    // When the video is done downloading, do the import
    event.currentTarget.addEventListener(
      'download-finish',
      event => this._handleImportVideo(event),
      { once: true },
    );

    const importEl = event.currentTarget.querySelector('vst-core-external-import');

    try {
      await importEl.triggerFetch();
    } finally {
      this.disableButtons(false);
      video.loading = false;
      this.requestUpdate();
    }
  }

  handleSampleVideoDownloadStart(event, video) {
    event.stopPropagation();
    video.status = 'caching';
    // Updating sampleVideos state requires a render
    this.requestUpdate();
  }

  handleSampleVideoDownloadFinish(event, video) {
    event.stopPropagation();
    video.status = 'cached';
    // Updating sampleVideos state requires a render
    this.requestUpdate();
  }

  handleSampleVideoDownloadError(event, video) {
    video.status = 'uncached';
    this.requestUpdate();
  }

  _handleImportVideo(e) {
    if (!this.isSessionReady) {
      this._videoImportEvent = { detail: e.detail, target: e.target };
      this.dataCollectionError = getText(`Loading...`);
      e.target.classList.add('starting-session');
    } else {
      this._videoImportEvent = null;

      try {
        e.target.classList.add('starting-session');
        this.dataCollectionError = '';
        this.dispatchEvent(new CustomEvent('import-video', { detail: e.detail }));
      } catch (err) {
        this.dataCollectionError = getText('Unable to load video, try again');
        e.target.classList.remove('starting-session');
        console.error(err);
      }
    }
  }

  _handleOpenFile(e) {
    const fileData = e.detail;
    const { type, name } = fileData.file;

    // type can be empty, so checking the file extension of name also
    const isValidVideoFileExtension = VIDEO_IMPORT_ACCEPTS.some(type => name.includes(type));

    if (type.includes('video') || isValidVideoFileExtension) {
      this._handleImportVideo(e);
    } else {
      this.dispatchEvent(new CustomEvent('open-file', { detail: { fileData } }));
    }
  }

  async _handleOpenFileViaNativeFileSystem(e) {
    this.$fileIO = this.requestService('fileIO');

    try {
      const { file, fileHandle } = await this.$fileIO.showOpenDialog([
        {
          extensions: ['vmbl'],
          mimeTypes: ['video/*'],
          name: 'Vernier Video Analysis',
        },
      ]);
      const params = { detail: { file, filepath: file.name }, target: e.target };
      const { type } = file;

      if (type.includes('video')) {
        this._handleImportVideo(params);
      } else {
        this.dispatchEvent(
          new CustomEvent('open-file', {
            detail: { fileData: { file, filepath: file.name, name: file.name, fileHandle } },
          }),
        );
      }
    } catch (err) {
      console.warn(err);
    }
  }

  disableButtons(disable) {
    const opacity = disable ? 0.2 : 1;

    this.clickableEls.forEach(el => {
      if (disable) {
        el.setAttribute('disabled', disable); // for some reason el.disabled = true doesn't work on a tags, so using setAttribute
      } else {
        el.removeAttribute('disabled');
      }
    });

    this.headerEls.forEach(el => {
      el.style.opacity = opacity;
    });

    this.logoEl.style.opacity = opacity;
  }

  _showErrorMessage(error) {
    this.disableButtons(false);
    this.dataCollectionError = error;
  }

  _computeMessage() {
    if (this.dataCollectionError) {
      return html`<p class="caption" variant="error">${this.dataCollectionError}</p>`;
    }
    return html` <p class="caption" variant="light">${getText('From your phone or computer')}</p> `;
  }

  render() {
    return html`
      <div class="main-content">
        <section class="section-new">
          <h2 class="overline" margin="block-end-xs">${getText('New Experiment')}</h2>
          <ul class="new-session">
            <li class="new-session__item">
              <vst-ui-file-import
                accept=${VIDEO_IMPORT_ACCEPTS.join()}
                @selected="${this._handleImportVideo}"
                id="file_import"
              >
                <div class="new-session__btn" id="sensor_based">
                  <vst-ui-spinner class="loading-spinner"></vst-ui-spinner>
                  <vst-ui-icon class="new-session__icon" .icon="${importVideoLarge}"></vst-ui-icon>
                  <div class="new-session__description">
                    <h3 class="heading" size="l" margin="0">${getText('Import Video')}</h3>
                    ${this._computeMessage()}
                  </div>
                </div>
              </vst-ui-file-import>
            </li>
          </ul>
        </section>

        <section>
          <h2 class="overline" margin="block-end-xs">${getText('Open Saved File')}</h2>

          ${this.hasNativeFileSystem
            ? html`
                <button
                  class="btn"
                  variant="outline"
                  @click="${this._handleOpenFileViaNativeFileSystem}"
                >
                  ${getText('Choose File')}
                </button>
              `
            : html`
                <vst-ui-file-import
                  accept="${platform.os.family === 'iOS' ||
                  (platform.os.family === 'OS X' && navigator.maxTouchPoints > 0)
                    ? '*'
                    : `.vmbl,${VIDEO_IMPORT_ACCEPTS.join()}`}"
                  @selected="${this._handleOpenFile}"
                >
                  <div class="btn" variant="outline">${getText('Choose File')}</div>
                </vst-ui-file-import>
              `}
        </section>

        <section>
          <h2 class="overline" margin="block-end-xs">${getText('From Vernier.com')}</h2>
          <a
            class="btn"
            variant="outline"
            href="http://www2.vernier.com/manuals/video-analysis-manual.pdf"
            target="_blank"
            rel="noopener"
          >
            <span>${getText('User Manual')}</span>
            <vst-ui-icon
              class="vernier-links__icon"
              size="xs"
              .icon="${externalLink}"
            ></vst-ui-icon>
          </a>
        </section>

        <section class="sample-videos-container">
          <h2 class="overline" margin="block-end-xs">${getText('Sample Videos')}</h2>
          <div class="sample-videos">
            ${(this.sampleVideos || []).map(
              (video, index) => html`
                <button
                  id="sample_video_${index}"
                  class="sample-video"
                  ?loading="${video.loading}"
                  ?disabled="${this.isLoadingSampleVideo}"
                  ?data-cached="${video.status === 'cached'}"
                  ?data-online="${navigator.onLine}"
                  @click="${event => this.handleSampleVideoDownloadAndImport(event, video)}"
                  @download-start="${event => this.handleSampleVideoDownloadStart(event, video)}"
                  @download-progress="${event => handleSampleVideoDownloadProgress(event)}"
                  @download-finish="${event => this.handleSampleVideoDownloadFinish(event, video)}"
                  @download-error="${event => this.handleSampleVideoDownloadError(event, video)}"
                >
                  <img
                    alt=""
                    class="sample-video__img"
                    crossorigin
                    loading="lazy"
                    src="${video.thumbnail}"
                  />
                  <vst-core-external-import href="${video.url}"></vst-core-external-import>
                  <h3 class="title sample-video__title" margin="xs">
                    ${(video.title && video.title()) || video.alt()}
                    <vst-style-tooltip>
                      <div
                        tabindex="0"
                        variant="icon"
                        size="s"
                        ?data-cached="${video.status === 'cached'}"
                        class="btn sample-video__icon ${video.status !== 'caching'
                          ? 'sample-video__icon--hover'
                          : ''}"
                        @click="${event => this.handleSampleVideoDownload(event, video)}"
                        @keyup="${event =>
                          event.keyCode === 13 ? this.handleSampleVideoDownload(event, video) : ''}"
                      >
                        <vst-ui-icon
                          ?hidden="${video.status === 'caching'}"
                          .icon="${computeSampleVideoIcon(video)}"
                          color="${computeSampleVideoIconColor(video)}"
                        ></vst-ui-icon>
                        <vst-ui-spinner ?hidden="${video.status !== 'caching'}"></vst-ui-spinner>
                      </div>
                      <span role="tooltip" position="block-start-end">
                        ${video.status === 'cached'
                          ? getText('Play video')
                          : getText('Download video')}
                      </span>
                    </vst-style-tooltip>
                  </h3>
                  <span id="sample_video_progress_" class="sample-video__progress"></span>
                </button>
              `,
            )}
          </div>
        </section>
        <vst-ui-logo-vernier class="vernier-logo" id="vernier_logo"></vst-ui-logo-vernier>
      </div>
    `;
  }
}

customElements.define('va-welcome', VaWelcome);
