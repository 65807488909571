import EventEmitter from 'eventemitter3';

let nextId = 0;

export class DataShareColumn extends EventEmitter {
  constructor({
    apiId = '',
    id = nextId++,
    color = '',
    dataSetName = '',
    formatStr,
    groupId,
    liveValue,
    liveValueTimeStamp = -1,
    position,
    setId = '',
    symbol = 'circle',
    values = [],
    valuesTimeStamp = -1,
    name = '',
    units = '',
  }) {
    super();
    this._apiId = apiId;
    this._id = id;
    this._liveValueTimeStamp = liveValueTimeStamp;
    this._valuesTimeStamp = valuesTimeStamp;
    this._values = values;
    this._setId = setId;
    this._symbol = symbol;
    this._groupId = groupId;
    this._dataSetName = dataSetName;
    this._color = color;
    this._liveValue = liveValue;
    this._name = name;
    this._units = units;
    this._formatStr = formatStr;
    this._position = position;

    this._nativeId = '';
  }

  update(props) {
    Object.keys(props).forEach(key => {
      if (this[key] !== props[key]) {
        this[key] = props[key];
      }
    });
  }

  get apiId() {
    return this._apiId;
  }

  get id() {
    return this._id;
  }

  get color() {
    return this._color;
  }

  set color(color) {
    this._color = color;
    this.emit('color-changed', color);
  }

  get liveValue() {
    return this._liveValue;
  }

  set liveValue(liveValue) {
    this._liveValue = liveValue;
  }

  get liveValueTimeStamp() {
    return this._liveValueTimeStamp;
  }

  set liveValueTimeStamp(liveValueTimeStamp) {
    if (liveValueTimeStamp > this.liveValueTimeStamp) {
      this._liveValueTimeStamp = liveValueTimeStamp;
      // NOTE: we need liveValue to be a number, then pass it throught "formatStr"
      // right now, liveValue is a string
      this.emit('live-value-changed', this.liveValue);
    }
  }

  set dataSetName(dataSetName) {
    this._dataSetName = dataSetName;
    this.emit('dataset-name-changed', dataSetName);
  }

  get valuesTimeStamp() {
    return this._valuesTimeStamp;
  }

  set valuesTimeStamp(valuesTimeStamp) {
    this._valuesTimeStamp = valuesTimeStamp;
  }

  get values() {
    return this._values;
  }

  set values(values) {
    this._values = values;
    this.emit('values-changed', values);
  }

  set updatedRows(updatedRows) {
    this._updatedRows = updatedRows;
    this.emit('updated-rows-changed', updatedRows);
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.emit('name-changed', name);
  }

  get nativeId() {
    return this._nativeId;
  }

  set nativeId(nativeId) {
    this._nativeId = nativeId;
  }

  get units() {
    return this._units;
  }

  set units(units) {
    this._units = units;
    this.emit('units-changed', units);
  }

  get formatStr() {
    return this._formatStr;
  }

  set formatStr(formatStr) {
    this._formatStr = formatStr;
    this.emit('formatStr-changed', formatStr);
  }

  get position() {
    return this._position;
  }

  set position(position) {
    this._position = position;
    this.emit('position-changed', position);
  }

  get setId() {
    return this._setId;
  }

  set setId(setId) {
    this._setId = setId;
    this.emit('set-id-changed', setId);
  }

  get symbol() {
    return this._symbol;
  }

  set symbol(symbol) {
    this._symbol = symbol;
    this.emit('symbol-changed', symbol);
  }

  get groupId() {
    return this._groupId;
  }

  set groupId(groupId) {
    this._groupId = groupId;
    this.emit('group-id-changed', groupId);
  }
}
