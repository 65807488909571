import EventEmitter from 'eventemitter3';

export class DataCollectionAPI extends EventEmitter {
  constructor(rpc) {
    super();
    this.rpc = rpc;

    const RPC_METHODS = {};
    [
      'settings-changed',
      'rolling-counter-error',
      'device-error',
      'keep-values-ready',
      'device-sensor-enum-completed',
    ].forEach(id => {
      RPC_METHODS[id] = (server, notification) => {
        const { params } = notification;
        this.emit(id, params);
      };
    });

    this.rpc.registerMethods(RPC_METHODS, this);
  }

  /**
   * Cancel a Keep Request
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise} Promise<{ success: true|false }>
   */
  cancelKeep(experimentId) {
    return this.rpc.sendRequest('keep-cancel', { experimentId });
  }

  /* MOVE TO SENSOR WORLD */
  /**
   * Poll for device connect/disconnect updates
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise} Promise<Null>
   */
  pollDevices(experimentId) {
    return this.rpc.sendRequest('-poll-devices', { experimentId });
  }

  createExperiment() {
    return this.rpc.sendRequest('create-experiment');
  }

  /**
   *
   * @param {object}    params
   * @param {number}    experimentId - UDM id of the experiment
   * @param {boolean=}  empty - Set true to create empty session
   * @param {string=}   sessionType - DataShare, ManualEntry
   * @param {string=}   collectionMode - time-based, events-with-entry, selected-events, drop-counting, photogate-timing, full-spectrum, manual
   * @param {string=}   datasetName
   * @param {string=}   sourceName - if sessionType == 'DataShare', this specifies the source name
   * @param {string=}   sourceURI - if sessionType == 'DataShare', this specifies the source uri
   * @param {string=}   spectrumMode - for full-spectrum mode: transmittance, intensity, fluorescence, raw
   * @param {string}    sessionSubtype - optional, subtype for session.
   * @returns {Promise}
   */
  sessionStarting(params) {
    // TODO: move this to dataWorld
    return this.rpc.sendRequest('experiment-starting', params);
  }

  /**
   * Close down the current session
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Promise} Promise<Null>
   */
  sessionClosing(experimentId) {
    // TODO: move this to dataWorld
    return this.rpc.sendRequest('experiment-closing', { experimentId });
  }

  /**
   * Set session restoring
   */
  sessionRestoring(params) {
    // TODO: move this to dataWorld
    return this.rpc.sendRequest('experiment-restoring', params);
  }

  /* MOVE TO SENSOR WORLD */
  /**
   * Notify the sensorworld that a USB device was attached.
   *
   * This is used for platforms that handle USB interraction
   * outside of this module.  (ie. Native Client)
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} deviceId
   * @param {string} vendorId
   * @param {string} productId
   * @returns {Promise} Promise<Null>
   */
  usbDeviceAttached(params) {
    return this.rpc.sendRequest('device-attached', params);
  }

  /* MOVE TO SENSOR WORLD */
  /**
   * Notify the sensorworld that a USB device was detached.
   *
   * This is used for platforms that handle USB interraction
   * outside of this module.  (ie. Native Client)
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} deviceId
   * @param {string} vendorId
   * @param {string} productId
   * @returns {Promise} Promise<Null>
   */

  usbDeviceDetached(params) {
    return this.rpc.sendRequest('device-detached', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} mode
   */
  setSpectrumDataMode(params) {
    return this.rpc.sendRequest('set-spectrum-data-mode', params);
  }

  /**
   *
   * @param {*} params
   * @param {number} experimentId - UDM id of the experiment
   */
  setCollectionParams(params) {
    return this.rpc.sendRequest('set-collection-params', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} mode
   */
  setCollectionMode(experimentId, mode) {
    return this.rpc.sendRequest('set-collection-mode', { experimentId, mode });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number=} duration
   */
  startMeasurements(experimentId, duration) {
    return this.rpc.sendRequest('start-measurements', { experimentId, duration });
  }

  /**
   *
   * @param {*} params
   */
  stopMeasurements(params) {
    return this.rpc.sendRequest('stop-measurements', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} canConfig
   */
  setCanConfigure(experimentId, canConfigure) {
    return this.rpc.sendRequest('can-configure', { experimentId, canConfigure });
  }

  // SENSORWORLD
  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean=} force - optional
   */
  searchForSensors(experimentId, force) {
    return this.rpc.sendRequest('search-for-sensors', { experimentId, force });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} timestamp
   * @param {*} accumulate
   * @param {*} average
   */
  keepData(experimentId, timestamp, accumulate, average) {
    // TODO: change naming for consistency
    return this.rpc.sendRequest('keep-event', {
      experimentId,
      timestamp,
      accumulate,
      average,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} enable
   */
  enableLiveVoltage(experimentId, enable) {
    return this.rpc.sendRequest('enable-live-voltage', { experimentId, enable });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {object} loggingConfig
   */
  prepareRemoteLogging(experimentId, deviceId, loggingConfig) {
    return this.rpc.sendRequest('prepare-remote-logging', {
      experimentId,
      deviceId,
      config: loggingConfig,
    });
  }

  /**
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {boolean} deleteDataAfterRetrieval
   */
  retrieveLoggedData(experimentId, deviceId, deleteDataAfterRetrieval) {
    return this.rpc.sendRequest('retrieve-logged-data', {
      experimentId,
      deviceId,
      deleteDataAfterRetrieval,
      runNumber: 1,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  deleteLoggedData(deviceId, experimentId) {
    return this.rpc.sendRequest('delete-logged-data', { deviceId, experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  detachRemoteDevices(experimentId) {
    return this.rpc.sendRequest('detach-remote-devices', { experimentId });
  }

  /**
   * Fetches the remote logging / offline collection status.
   * @param {number} experimentId udm id for the experiment.
   * @param {number} deviceId id of the device from which to fetch status.
   * @returns {Promise<object>} dictionary containing the remote status info.
   */
  getOfflineCollectionStatus(experimentId, deviceId) {
    return this.rpc.sendRequest('get-offline-collection-status', { deviceId, experimentId });
  }

  /**
   * Sets whether data marks are nebaled or disabled in the UDM store.
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} enabled true to mark feature as enabled, false to mark it as disabled.
   * @returns {Promise} success will indicate operation succeeded.
   * @summary The state is initially forwarded to the front end via the `settings-changed` notification on file open and device add.
   * @see DataCollection.handlers.js
   */
  setDataMarksEnabled(experimentId, enabled) {
    return this.rpc.sendRequest('set-datamarks-enabled', {
      experimentId,
      dataMarksEnabled: enabled,
    });
  }

  /**
   * Extends collection duration by a given factor.
   * @param {number} factor factor by which to extend duration; must be greater than 0.
   */
  extendCollectionDuration(experimentId, factor) {
    return this.rpc.sendRequest('extend-collection-duration', {
      experimentId,
      factor,
    });
  }
}
