// This file contains Vernier DataShare API common defines and code

// DataShare API Paths
const path = {
  INFO: '/info',
  STATUS: '/status',
  COLUMNS: '/columns',

  START_COLLECTION: '/control/start',
  STOP_COLLECTION: '/control/stop',
};

function getInfoURL(serverURL) {
  if (serverURL) return serverURL + path.INFO;
  return path.INFO;
}

function getStatusURL(serverURL) {
  if (serverURL) return serverURL + path.STATUS;
  return path.STATUS;
}

function getColumnPath(columnId) {
  return `${path.COLUMNS}/${columnId}`;
}

function getColumnURL(serverURL, columnId) {
  if (serverURL) return serverURL + getColumnPath(columnId);
  return getColumnPath(columnId);
}

function getStartCollectionURL(serverURL) {
  if (serverURL) return serverURL + path.START_COLLECTION;
  return path.START_COLLECTION;
}

function getStopCollectionURL(serverURL) {
  if (serverURL) return serverURL + path.STOP_COLLECTION;
  return path.STOP_COLLECTION;
}

// pass info.apiVers
function parseVersion(apiVers) {
  let version = null;
  const match = apiVers.match(/^\s*(\d+)\.(\d+)\.(\d+)\s*$/);

  if (match && match.length === 4) {
    version = {
      major: parseInt(match[1], 10),
      minor: parseInt(match[2], 10),
      micro: parseInt(match[3], 10),
    };
  } else {
    throw new Error(`Failed to parse version string: ${apiVers}`);
  }
  return version;
}

export const api = {
  path,
  // Get full URL to DataShare Resources
  getInfoURL,
  getStatusURL,
  getColumnURL,
  getStartCollectionURL,
  getStopCollectionURL,
  // helpers
  getColumnPath,
  parseVersion,
};
