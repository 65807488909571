import EventEmitter from 'eventemitter3';
// import * as platform from './PLATFORM_ID';

const priv = Symbol('priv');

export class Accessiblity extends EventEmitter {
  constructor() {
    super();
    const baseFontSize = getComputedStyle(document.documentElement).getPropertyValue(
      '--base-font-size',
    );
    // TODO: Read a localstorage option here in electron/chrome
    this._accessibilityScale = parseFloat(baseFontSize);
    // TODO: setup keyboard listeners here for keyboard ctrl++
  }

  get scale() {
    return this._accessibilityScale;
  }

  set scale(newScale) {
    if (newScale !== this._accessibilityScale) {
      this._accessibilityScale = newScale;
      document.documentElement.style.setProperty('--base-font-size', `${newScale}em`);
      this.emit('accessibility-scale-changed', this._accessibilityScale);
      // TODO: Set a localstorage option here for future app loads
    }
  }
}

export function createAccessibility() {
  [priv].baseFontSize = getComputedStyle(document.documentElement).getPropertyValue(
    '--base-font-size',
  );
  [priv].scale = parseFloat([priv].baseFontSize);

  return new Accessiblity();
}
