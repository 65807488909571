import allyLib from 'ally.js';

let handler = null;
let filter = null;

function disengage() {
  // filter is an array of DOM nodes
  if (handler) {
    handler.disengage();
  }
}

function getFilter() {
  return filter;
}

function maintainDisabled(_filter) {
  return allyLib.maintain.disabled({
    filter: _filter,
  });
}

function disable(_filter) {
  // filter is either an array or a single DOM nodes
  disengage();
  handler = allyLib.maintain.disabled({ filter: _filter });
  filter = _filter;
}

export const ally = {
  disengage,
  getFilter,
  maintainDisabled,
  disable,
};
