import * as platform from './PLATFORM_ID';

const requestBluetooth = async () => {
  if (platform.requestBluetooth) {
    return platform.requestBluetooth();
  }

  return { success: true };
};

export const createPermissons = () => ({
  requestBluetooth,
});
