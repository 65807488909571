import { createPromiseSequence } from '@utils/promiseSequence.js';
import * as platform from './PLATFORM_ID';

let beforeCloseFunction = null;

const close = async () => {
  await beforeCloseFunction?.();
  if (platform.close) {
    platform.close();
    return;
  }
  window.close();
};

const isEventValid = async event => {
  if (platform.isEventValid) {
    return platform.isEventValid(event);
  }

  return true;
};

/**
 * @typedef {Object} AppLifecycle
 * @property {Object} onClose
 * @property {Function} onClose.addPromise
 * @property {Function} closeWindow
 *
 * @return {AppLifecycle}
 */
export const createAppLifecycle = (
  preClosePromises = createPromiseSequence(),
  forceCloseNextAttempt = false,
) => ({
  closeWindow(force = false, event = { type: undefined }) {
    if (!isEventValid(event)) {
      return Promise.resolve();
    }

    if (force === true || forceCloseNextAttempt) {
      return close();
    }

    event.returnValue = false;
    // eslint-disable-next-line no-param-reassign
    forceCloseNextAttempt = true;

    return preClosePromises
      .execute()
      .then(close)
      .catch(err => {
        if (err.cancelClose) {
          // eslint-disable-next-line no-param-reassign
          forceCloseNextAttempt = false;
        } else {
          event.returnValue = true;
          close();
        }
      });
  },
  onClose: {
    addBeforeClose(beforeClose) {
      beforeCloseFunction = beforeClose;
    },
    addPromise(priority, promise) {
      preClosePromises.add(priority, promise);
    },
  },
});
