let el;

export const createToast = () => {
  const queue = [];
  let timer = null;

  const _showToast = () => {
    el.show();
  };

  const _hideToast = () => {
    el.hide();
    clearTimeout(timer);
    timer = null;
  };

  const _makeToast = toastObj => {
    el.message = toastObj.message;
    if (toastObj.options.action && toastObj.options.action.text) {
      el.actionMessage = toastObj.options.action.text;

      el.addEventListener('actionClicked', () => {
        toastObj.options.action.callback();
        _hideToast();
      });
    }

    const _checkQueue = () => {
      el.removeEventListener('transitionend', _checkQueue);
      if (queue.length > 0) {
        _makeToast(queue.shift());
      }
    };

    _showToast();

    if (toastObj.options.duration === Infinity) {
      timer = null; // if duration is LENGTH_INDEFINITE don't setup timer. Next snackbar will instantly dismiss this one, or the user can tap it away
    } else {
      timer = setTimeout(() => {
        _hideToast();
        el.addEventListener('transitionend', _checkQueue);
      }, toastObj.options.duration);
    }
  };

  return {
    async attachWrapper(node) {
      await import('@components/vst-ui-toast/vst-ui-toast.js');

      el = document.createElement('vst-ui-toast');

      this.wrapperNode = node;
      this.wrapperNode.appendChild(el);

      el.addEventListener('doneClicked', () => {
        _hideToast();
      });
    },
    makeToast(stringKey, options = { duration: this.LENGTH_SHORT }) {
      if (!this.wrapperNode) {
        this.attachWrapper(document.body);
      }

      const toastObj = {
        message: stringKey,
        options,
      };

      queue.push(toastObj);

      if (timer === null) {
        _makeToast(queue.shift());
      }
    },
    hideToast() {
      _hideToast();
    },
    get element() {
      return el;
    },

    get LENGTH_SHORT() {
      return 1500;
    },

    get LENGTH_MEDIUM() {
      return 5000;
    },

    get LENGTH_LONG() {
      return 10000;
    },

    get LENGTH_INDEFINITE() {
      return Infinity;
    },
  };
};
