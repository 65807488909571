export class VstUiDialogManager extends HTMLElement {
  connectedCallback() {
    if (!this.parentNode.host.isDialogMediator)
      throw new Error(
        'a <vst-ui-dialog-manager> should be in the shadowRoot of a DialogMediator component.',
      );

    this.querySelectorAll('vst-ui-dialog').forEach(dialog => {
      this.dispatchEvent(
        new CustomEvent('register-dialog', { composed: true, bubbles: true, detail: dialog }),
      );
    });

    this.mutationObserver = new MutationObserver(this.childConnected.bind(this));
    this.mutationObserver.observe(this, { childList: true });
  }

  childConnected(mutationRecords) {
    mutationRecords.forEach(mutationRecord => {
      if (mutationRecord.type !== 'childList') return;
      Array.from(mutationRecord.addedNodes).forEach(dialog => {
        if (dialog.nodeType !== 1) return;
        if (!/vst-ui-dialog|style/i.test(dialog.nodeName)) {
          console.error(
            `${dialog.nodeName}: only <vst-ui-dialog> elements should be children of the <vst-ui-dialog-manager> component.`,
          );
          return;
        }
        this.dispatchEvent(
          new CustomEvent('register-dialog', { composed: true, bubbles: true, detail: dialog }),
        );
      });
      Array.from(mutationRecord.removedNodes).forEach(dialog => {
        if (dialog.nodeName !== 'vst-ui-dialog') return;
        this.dispatchEvent(
          new CustomEvent('dispose-dialog', { composed: true, bubbles: true, detial: dialog }),
        );
      });
    });
  }
}
customElements.define('vst-ui-dialog-manager', VstUiDialogManager);
