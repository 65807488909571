import { http } from '@utils/http.js';
import * as platform from './PLATFORM_ID';

let manifest = {};

const getAppVersion = () => {
  if (platform.getAppVersion) {
    return platform.getAppVersion();
  }

  return manifest.version;
};

const getAppName = () => {
  if (platform.getAppName) {
    return platform.getAppName();
  }

  return manifest.name;
};

const getAppShortName = () => {
  if (platform.getAppShortName) {
    return platform.getAppShortName();
  }

  return manifest.short_name;
};

export const createAppManifest = async () => {
  try {
    if (platform.fetchManifest) {
      manifest = await platform.fetchManifest();
    } else {
      manifest = await http.getJSON('manifest.json');
    }
  } catch (err) {
    console.warn(err);
  }

  return {
    getAppVersion,
    getAppName,
    getAppShortName,
  };
};
