import { LitElement, html } from 'lit';
import { when } from 'lit/directives/when.js';
import { getText } from '@utils/i18n.js'; // TODO pass i18n strings in
import { globalStyles } from '@styles/vst-style-global.css.js';

import '@components/vst-style-tooltip/vst-style-tooltip.js';
import vstUiToolbarStyles from './vst-ui-toolbar.css.js';

class VstUiToolbar extends LitElement {
  static get properties() {
    return {
      canControl: {
        type: Boolean,
        reflect: true,
      },
      canKeep: {
        type: Boolean,
        reflect: true,
      },
      isCollecting: { type: Boolean },
      collectBtnText: { type: String },
      collectBtnMoreText: { type: String },
      dataLoggingEnabled: { type: Boolean },
      experimentName: { type: String },
      notify: {
        type: String,
        reflect: true,
      },
      keepButtonEnabled: { type: Boolean },
      collectButtonEnabled: { type: Boolean },
      dataMarksEnabled: { type: Boolean },
    };
  }

  static get styles() {
    return [globalStyles, vstUiToolbarStyles];
  }

  constructor() {
    super();
    this.canKeep = false;
    this.canControl = false;
    this.dataLoggingEnabled = false;
    this.isCollecting = false;
    this.keepButtonEnabled = true;
    this.collectButtonEnabled = true;

    this.notify = '';
    this.experimentName = '';
    this.collectBtnMoreText = '';
  }

  connectedCallback() {
    super.connectedCallback();
    const toolbarButtonEls = this.querySelectorAll('button');

    toolbarButtonEls.forEach(toolbarButtonEl => {
      toolbarButtonEl.addEventListener('click', e => {
        const { action } = e.currentTarget.dataset;

        /**
         * Toolbar button events
         *
         * @event data-action
         * @type {object}
         * @property {object} anchor - The element the action is anchored to
         * @property {string} orientation - top|right|bottom|left
         */
        this.dispatchEvent(
          new CustomEvent(action, {
            detail: {
              anchor: e.currentTarget,
              orientation: 'bottom',
            },
          }),
        );
      });
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    document.addEventListener('keydown', e => {
      if (e.keyCode === 32 && !this._windowIsFocused) {
        if (this.canControl) {
          const collectBtn = this.shadowRoot.getElementById('collect_btn');
          if (!collectBtn.disabled) {
            this.collectData();
          }
        } else if (this.canKeep) {
          const keepBtn = this.shadowRoot.querySelector('#keep_btn');
          if (!keepBtn.disabled) {
            this.keepData();
          }
        }
      }
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    window.addEventListener('focusin', () => {
      this._windowIsFocused = true;
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    window.addEventListener('focusout', () => {
      this._windowIsFocused = false;
    });
  }

  /**
   * Toggles start/stop collection
   *
   */
  collectData() {
    const collectBtn = this.shadowRoot.getElementById('collect_btn');
    if (this.collectButtonEnabled) {
      this.collectButtonEnabled = false;
      if (this.isCollecting) {
        this.dispatchEvent(new CustomEvent('stop-collection'));
      } else {
        this.dispatchEvent(new CustomEvent('start-collection'));
      }
      setTimeout(() => {
        // prevent users from spamming the collect btn since sometimes it puts us in a bad state.
        this.collectButtonEnabled = true;
      }, 500);
    }

    collectBtn.classList.toggle('is-loading');
    // remove the loading class no matter what after 4 seconds.
    setTimeout(() => {
      collectBtn.classList.remove('is-loading');
    }, 8000);
  }

  keepData() {
    /**
     * Keep point event
     *
     * @event keep-point
     */
    this.dispatchEvent(new CustomEvent('keep-point'));
  }

  /**
   * Sets focus on the #keep_btn element
   *
   */
  focusKeep() {
    const keepBtn = this.shadowRoot.querySelector('#keep_btn');
    keepBtn.focus();
  }

  get _collectBtnClass() {
    return this.collectBtnMoreText
      ? 'toolbar-collect toolbar-collect--two-line'
      : 'toolbar-collect';
  }

  _keepButtonEnabledChanged() {
    const keepBtn = this.shadowRoot.querySelector('#keep_btn');

    if (keepBtn && this.canKeep) {
      if (this.keepButtonEnabled) {
        keepBtn.focus();
      } else {
        keepBtn.blur();
      }
    }
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'keepButtonEnabled') {
        this._keepButtonEnabledChanged();
      }
    });
  }

  markData() {
    this.dispatchEvent(
      new CustomEvent('mark-data', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`
      <div
        class="toolbar"
        id="toolbar"
        ?connected="${this.connected}"
        ?canControl="${this.canControl}"
        ?canKeep="${this.canKeep}"
        ?isCollecting="${this.isCollecting}"
      >
        <div class="left-content">
          <slot name="toolbar_left"></slot>
        </div>

        <div class="center-content">
          <slot name="toolbar_center"></slot>
          ${this.canControl
            ? html`
                <vst-style-tooltip>
                  <button
                    id="collect_btn"
                    class="${this._collectBtnClass}"
                    ?disabled="${!this.collectButtonEnabled}"
                    @click="${this.collectData}"
                  >
                    ${when(
                      this.dataLoggingEnabled,
                      () => getText('Start Logging'),
                      () => (this.isCollecting ? getText('Stop') : getText('Collect')),
                    )}
                    ${this.collectBtnMoreText
                      ? html`
                          <span class="toolbar-collect__second-line"
                            >${this.collectBtnMoreText}</span
                          >
                        `
                      : ``}
                  </button>
                  <span role="tooltip" position="block-end"
                    >${this.isCollecting
                      ? getText('Stop Collection')
                      : getText('Start Collection')}</span
                  >
                </vst-style-tooltip>
              `
            : ``}
          ${this.canKeep && this.canControl
            ? html`
                <button
                  id="keep_btn"
                  class="toolbar-collect--secondary toolbar-keep"
                  @click="${this.keepData}"
                  ?disabled="${!this.keepButtonEnabled}"
                >
                  ${getText('Keep')}
                </button>
              `
            : ``}
          ${this.dataMarksEnabled && this.canControl
            ? html`
                <button
                  id="mark_btn"
                  class="toolbar-collect--secondary"
                  @click="${this.markData}"
                  ?disabled=${!this.isCollecting}
                >
                  ${getText('Mark')}
                </button>
              `
            : ``}
        </div>

        <div class="right-content">
          <slot name="toolbar_right"></slot>
        </div>
      </div>
      <div class="replay-content">
        <slot name="toolbar_replay"></slot>
      </div>
    `;
  }
}

customElements.define('vst-ui-toolbar', VstUiToolbar);
