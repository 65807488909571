import { css } from 'lit';

import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { buttonDisabled, buttonReset } from '@styles/mixins/buttons.css.js';

import { dialogDefault } from '@styles/mixins/dialogs.css.js';

export const vaWelcomeStyles = css`
  :host {
    --scrollbar-display: block;
    border: none;
    display: flex;
    background-color: var(--vst-color-bg);
    flex-direction: column;
    max-block-size: calc(100vh - 50px - var(--chrome-menubar-height, 0px));
    block-size: auto;
    /* stylelint-disable-line length-zero-no-unit */
  }

  ${dialogDefault}

  ${breakpoint(
    's',
    css`
      :host {
        width: 32.8125rem;
      }
    `,
  )}

  ${breakpoint(
    'l',
    css`
      :host {
        overflow: hidden !important;
      }
    `,
  )}

  .main-content {
    display: flex;
    flex-direction: column;
    padding: var(--vst-space-2xs);
    overflow: auto;
  }

  .main-content section {
    margin-block-end: var(--vst-space-l);
  }

  ${breakpoint(
    'xs',
    css`
      .main-content {
        height: 100%;
        justify-content: space-evenly;
      }
    `,
  )}

  ${breakpoint(
    'xs',
    css`
      .main-content {
        display: grid;
        flex: 1;
        height: auto;
        flex-shrink: 0;
        grid-template-columns: auto;
        grid-gap: var(--vst-space-xl);
        padding: var(--vst-space-m);
      }

      .main-content section:not(:last-of-type) {
        margin-block-end: 0;
      }
    `,
  )}

  ${breakpoint(
    'm',
    css`
      .main-content {
        grid-template-columns: 1fr 1fr;
      }

      .section-new {
        grid-column: 1/-1;
      }
    `,
  )}

${breakpoint(
    'l',
    css`
      .main-content {
        grid-template-rows: auto auto 1fr auto;
        max-block-size: 80vh;
      }
    `,
  )}

  ${css`
    @media (max-height: 45em) {
      .main-content {
        grid-template-rows: auto;
      }
    }
  `}

  /* ///////////////////////////// */
  .new-session {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .new-session__item {
    position: relative;
    margin-block-end: 0.75rem;
  }

  ${breakpoint(
    's',
    css`
      .new-session__item {
        margin-block-end: 1rem;
      }
    `,
  )}

  .new-session__item:last-of-type {
    margin-block-end: 0;
  }

  ${buttonReset(css`.new-session__btn`)}
  ${buttonDisabled(css`.new-session__btn`)}
  .new-session__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1rem;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;
    border-radius: var(--vst-radius);
  }

  ${breakpoint(
    'xs',
    css`
      .new-session__btn {
        flex-direction: row;
        align-items: center;
      }
    `,
  )}
  .new-session__btn:hover .new-session__icon {
    opacity: 1;
    /*  border-color: $color-action-dark; */
    border-width: 3px;
    padding: calc(0.5rem - 2px);
  }

  ${breakpoint(
    's',
    css`
      .new-session__btn:hover .new-session__icon {
        padding: calc(1.125rem - 2px);
      }
    `,
  )}

  .new-session__icon {
    display: block;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    padding: 0.5rem;
    border: 1px solid var(--vst-color-warning-ui);
    border-radius: 100%;
    /*  opacity: .6; */
  }

  ${breakpoint(
    's',
    css`
      .new-session__icon {
        width: 5rem;
        height: 5rem;
        padding: 1.125rem;
      }
    `,
  )}

  .new-session__description {
    margin: 0;
    margin-block-start: var(--vst-space-xs);
    flex: 1;
  }

  ${breakpoint(
    'xs',
    css`
      .new-session__description {
        margin: 0;
        margin-inline-start: var(--vst-space-m);
      }
    `,
  )}

  .heading[size='l'] {
    margin: 0;
    color: var(--vst-color-text-brand);
  }

  .new-session__btn:hover .heading[size='l'],
  .starting-session .heading[size='l'] {
    color: var(--vst-color-text-brand-hover);
  }

  .caption[size='s'] {
    color: #fff;
  }
  /*  loading spinner which shows as experiments are being loaded in */
  .loading-spinner {
    display: none;
    width: 3rem;
    height: 3rem;
    position: absolute;
  }

  ${breakpoint(
    'xs',
    css`
      .loading-spinner {
        width: 5rem;
        height: 5rem;
        top: calc(50% - 2.5rem);
      }
    `,
  )}

  ${breakpoint(
    'm',
    css`
      section.full-grid-width {
        grid-column: 1/-1;
      }
    `,
  )}

  /* //////////////////// */
  .sample-videos-container {
    grid-column: 1/-1;
    overflow: auto;
  }
  .sample-videos {
    display: flex;
    height: min-content;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    grid-column: 1/-1;
    padding: var(--vst-space-2xs);
  }

  ${breakpoint(
    'l',
    css`
      .sample-videos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--vst-space-m);
        width: 100%;
        overflow-x: visible;
        padding-block-start: 0;
        padding-block-end: 0;
      }

      .sample-videos::-webkit-scrollbar {
        display: var(--scrollbar-display);
      }

      .sample-videos::-webkit-scrollbar-thumb {
        /* this fixes a really weird iOS bug where the welcome screen was cut off https://vernier.atlassian.net/browse/VA-1368 */
        display: var(--scrollbar-display);
      }
    `,
  )}

  /* //////////////////// */
  .sample-video {
    position: relative;
    cursor: pointer;
    border: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background-color: var(--vst-color-sample-video-bg);
    border-radius: var(--vst-radius);
    box-shadow: var(--vst-shadow-s);
    border: 1px solid var(--vst-color-bg-primary);
    transition: box-shadow 0.3s;
    block-size: 12rem;
    inline-size: 12rem;
    /* TODO - fix */
    flex: 0 0 auto;
    margin: var(--vst-space-xs);
    margin-block-start: 0;
    margin-block-end: 0;
    outline-offset: -5px;
    display: flex;
    flex-direction: column;
  }

  .sample-video__img {
    flex: 1;
  }

  ${breakpoint(
    'l',
    css`
      .sample-video {
        inline-size: auto;
        margin: 0;
        display: flex;
        flex-direction: column;
      }
    `,
  )}

  .sample-video:not([data-online]):not([data-cached]) {
    filter: grayscale(100%);
    color: var(--vst-color-bg-primary);
    cursor: initial;
  }
  .sample-video:not([loading])[disabled] {
    filter: grayscale(100%);
    color: var(--vst-color-bg-primary);
    cursor: initial;
  }
  .sample-video__icon {
    border-radius: var(--vst-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  .sample-video__icon--hover:hover {
    background-color: var(--vst-color-bg-tertiary);
    border-radius: var(--vst-radius);
  }

  .sample-video:first-of-type {
    margin-inline-start: 0;
  }
  .sample-video:last-of-type {
    margin-inline-end: 0;
  }
  .sample-video img {
    width: 100%;
    flex-grow: 1;
    background-size: cover;
    pointer-events: none;
    object-fit: cover;
  }
  .sample-video__title {
    margin: 0;
    padding: var(--vst-space-s) var(--vst-space-m);
    display: flex;
    inline-size: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .sample-video vst-ui-spinner {
    inline-size: var(--vst-icon-size-m);
    block-size: var(--vst-icon-size-m);
  }
  .sample-video__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    transform-origin: left;
    height: 5px;
    background-color: var(--vst-color-brand);
  }

  .sample-video:not([disabled])[data-online]:hover,
  .sample-video:not([disabled])[data-cached]:hover {
    box-shadow: var(--vst-shadow-m);
  }

  @keyframes sampleLoader {
    0% {
      transform: scaleX(0);
    }
    90% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  /* //////////////////// */
  .starting-session {
    opacity: 1 !important;
  }
  .starting-session .loading-spinner {
    display: block;
  }
  .starting-session .new-session__icon {
    opacity: 0.6;
    border-color: transparent;
  }

  /* ///////////////////////////// */
  .vernier-links {
    padding-inline-start: 0;
    margin: -0.2em 0 0 0;
    list-style: none;
  }

  ${buttonDisabled(css`.vernier-links__link`)}
  .vernier-links__link {
    color: var(--vst-color-fg-secondary);
    display: inline-grid;
    grid-template-columns: 0.75rem 1fr;
    grid-gap: 0.375rem;
  }
  .vernier-links__link:hover {
    color: var(--vst-color-brand-dark);
  }
  .vernier-links__link:hover .vernier-links__icon {
    opacity: 1;
  }

  .vernier-links__icon {
    --vst-ui-icon-color: var(--vst-color-fg-tertiary);
    margin-inline-start: var(--vst-space-2xs);
  }

  .vernier-links li {
    margin-block-end: 0.5em;
  }
  .vernier-links li:last-of-type {
    margin-block-end: 0;
  }

  /* ///////////////////////////// */
  .vernier-logo {
    width: 8rem;
    margin: 0 auto;
    opacity: 0.8;
    transition: opacity var(--transition-slower-speed) var(--transition-easing);
  }

  ${breakpoint(
    'm',
    css`
      .vernier-logo {
        grid-column: 1/-1;
      }
    `,
  )}
`;
