import { action, observable, computed, makeObservable } from 'mobx';
import { MultiGraphAppearance } from './MultigraphAppearance';

export class DataMark extends MultiGraphAppearance {
  static COLLECTED = 'collected';

  static USER_TAGGED = 'user-tagged';

  constructor(options = {}) {
    super(options);
    this._id = options.id || 0; // We will fetch an id as necessary.
    // TODO: (@ejdeposit) change to setId once persisted on backend
    this._dataSetId = options.dataSetId || 0;
    this._type = options.type || DataMark.COLLECTED;
    this._rowIndex = options.rowIndex || 0;
    this.text = options.text || '';
    this._hasMoved = {};

    console.assert(typeof this.text === 'string');

    makeObservable(this, {
      text: observable,

      udmExport: computed,
      rowIndex: computed,

      setText: action,
      udmImport: action,
    });
  }

  setText(newText) {
    this.text = newText;
    console.assert(typeof this.text === 'string');
  }

  /**
   * @readonly
   * @returns { Number } unique id of this data mark
   */
  get id() {
    return this._id;
  }

  getHasMoved(graphId) {
    if (graphId in this._hasMoved) {
      return this._hasMoved[graphId];
    }
    return false;
  }

  setHasMoved(graphId, value) {
    this._hasMoved[graphId] = value;
  }

  /**
   * @readonly
   * @returns { String } DataMark.COLLECTED for data marks, DataMark.USER_TAGGED for data tags.
   */
  get type() {
    return this._type;
  }

  /**
   * @readonly
   * @returns {*} dataSetId with which this data mark is associated.
   */
  get dataSetId() {
    return this._dataSetId;
  }

  /**
   * @readonly
   * @returns {*} setId with which this data mark is associated, but is stored as column id.
   */
  get setId() {
    return this._dataSetId.toString();
  }

  /**
   * @return {Number} row index for this data mark.
   */
  get rowIndex() {
    return this._rowIndex;
  }

  /**
   * Set the row index for this data mark. Only works if type is USER_TAGGED.
   * @param {Number} index new row index.
   */
  set rowIndex(index) {
    if (this._type !== DataMark.USER_TAGGED) {
      throw new Error('Cannot change rowIndex of collected DataMark.');
    }
    this._rowIndex = index;
  }

  /**
   * @returns {Object} suitable for persisting in the back end store.
   */
  get udmExport() {
    return {
      ...this.mixinUdmExport,
      id: parseInt(this._id),
      dataSetId: parseInt(this._dataSetId),
      type: this._type,
      rowIndex: this.rowIndex,
      text: this.text,
    };
  }

  /**
   * Imports new values. This is used by Data Sharing.
   * @param {Object} newValues
   */
  udmImport(newValues) {
    const { text = null } = newValues;
    if (text) this.setText(text);
    super.udmImport(newValues);
  }
}
