import { IndexedDB } from '@utils/indexedDB.js'; // NOTE: weird circular dependeny when usign the alias @utils in this file
import { readFileAsArrayBuffer } from '@utils/fileio-helpers.js'; // NOTE: weird circular dependeny when usign the alias @utils in this file
import { v4 as uuid } from 'uuid';

// The archiver. The archiving logic is in place, and was debugged in broad strokes,
//
// The Archive Format
// ------------------
//
// The archive is a standard tar, with extra vstudm magic and version info stored in part
// of the file header which is unused in the original tar format. This means the archives can be
// inspected using standard tar (e.g., for debugging), but we can tell our archive from a bog
// standard tar ball, and refuse to open the latter.
//
// The tar has a fixed structure, with all the files held in a root directory 'vstudm/' -- this is
// to avoid a tar-bomb (extracting an unknown number of files into users working directory).
//
// Inside the root directory is stored the UDM xml document; this is always called 'data.udm',
// and additionally this file must be output as the last file in the archive. (This allows UDM to write
// directly into the tar file as need without having to regenerate the entire archive.)
//
// Attachments are stored in sub-directories; these can be arbitrarily named, as long as the
// paths match the references inside the UDM xml document. For simplicity, video files should
// be stored under 'videos/'.
//
// So a VA archive will look like this
//
//   MyExperiment.vmbl -
//                        vstudm/
//                        vstudm/data.udm
//                        vstudm/videos/myExperimentVideo937.mp4
//
//
// The archive is purely for user to download and for us to open; since we do not have access to the
// filesystem, we don't use it internally, instead we store the archive constituent parts in IndexedDB
// as separate entries, which are themselves organised in notional directory tree.
//
// So in the DB, a VA document will be represented by two (or more) entries, and look something like:
//
//   'vstudm/MyExperiment456/vstudm/data.udm'              <-- this is the UDM xml doc
//   'vstudm/MyExperiment456/vstudm/videos/MyVideo45.mp4'  <-- this is the associated video
//
// This allows us (a) to play the video directly from the DB, and (b) write changes to the XML part
// independently from changes to the (large) video file. The actual archive tar gets only generated
// from these separate entries by the writeDocument() function when the user choses to 'download it'.
//
// The root 'directory' for the VA document (in the above example 'vstudm/MyExperiment456/') is obtained
// by the _idbfsName() function.

function _bufferToString(buffer) {
  return new TextDecoder().decode(buffer);
}

function _stringToBuffer(string) {
  return new TextEncoder().encode(string);
}

// Generates a document name to use in the DB -- use UUID to avoid collisions between tabs,
// see MEG-498
function _autoName() {
  return uuid();
}

const ARCHIVE_MESSAGE = {
  REQUEST: 'ARCHIVE_REQUEST',
  RESPONSE: 'ARCHIVE_RESPONSE',
};

export class UdmArchive {
  static get dataBaseName() {
    return IndexedDB.name;
  }

  constructor(params = {}) {
    // FIXME: deprecated service
    this.configuration = {
      platform: 'deprecated',
    };

    this._sequence = 0;

    // This is the name that is used in the database; unfortunately, we cannot change this, because it is
    // encoded in the keys, so this is probably not the name under which it will be exported
    this.name = params.name || _autoName(); // name of the document per se

    // This is the user visible 'file' name, which we save with metadata on the data.udm object
    this.exportName = this.name;
    this.importPath = null;

    // any attachments bundled in it
    this.attachments = params.attachments ?? {};

    // Offset of the next write operation -- used and advanced by
    // the _write functions, possibly not needed, depending on the
    // write API
    this.currentOffset = 0;

    this.maxDBSize = params.maxDBSize;
    this.leaveAfterPrune = params.leaveAfterPrune;

    this.db = new IndexedDB();

    // iOS WKWebView does not support Service Workers for whatever daft reasons,
    // so check before invoking them.
    if (!window.__isSessionClient && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'message',
        async ({ data: { type, id, property, args } }) => {
          if (type === ARCHIVE_MESSAGE.REQUEST) {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            const activeServiceWorker = serviceWorkerRegistration.active;
            const payload = await (typeof this[property] === 'function'
              ? this[property](...args)
              : this[property]);
            activeServiceWorker.postMessage({
              type: ARCHIVE_MESSAGE.RESPONSE,
              id,
              payload,
            });
          }
        },
      );
    }
  }

  get archivePaths() {
    return Object.keys(this.attachments).map(attachment => this._makeKey(attachment));
  }

  // Prunes the db to the threshold set at construction
  // Called at the start of every new session, to keep DB use
  // under control
  async _prune() {
    let maxOfQuota;
    const maxUsage = 200 * 1024 * 1024;

    try {
      const e = await navigator.storage.estimate();
      maxOfQuota = e.quota / 10;
    } catch (e) {
      maxOfQuota = maxUsage;
    }
    const maxDBSize = Math.floor(Math.min(this.maxDBSize, maxOfQuota, maxUsage));

    if (maxDBSize !== null && maxDBSize >= 0) {
      // eslint-disable-next-line consistent-return
      return this.db.size().then(params => {
        // eslint-disable-line consistent-return
        const printSize = size => {
          let u = 'KB';
          let s = Math.floor(size / 1024);

          if (s > 1024) {
            s = Math.floor(s / 1024);
            u = 'MB';
          }

          return `${s}${u}`;
        };

        console.log(
          `Current IndexedDB contains ${params.documentCount} documents, size is ${printSize(
            params.size,
          )} (prune threshold ${printSize(maxDBSize)})`,
        );

        // pruning follows LRU policy, but it doesn't update this.attachments
        // which has attachments in the alphabetical  order of the paths (keys).
        // TODO: refactor pruning to be driven from this.attachments and still follow LRU policy
        // until prune constraints are reached.
        if (params.size >= maxDBSize || params.documentCount > this.leaveAfterPrune) {
          return this.db.prune(this.leaveAfterPrune).then(() =>
            this.db.size().then(params => {
              const size = printSize(params.size);
              this.db.keys().then(dbKeys => {
                const keys = Object.keys(this.attachments);
                for (let i = 0; i < keys.length; i++) {
                  if (!dbKeys.includes(keys[i])) {
                    delete this.attachments[keys[i]];
                  }
                }
              });
              console.log(
                `IndexedDB was pruned, left ${params.documentCount} documents, new size ${size}`,
              );
            }),
          );
        }
      });
    }

    return Promise.resolve();
  }

  // Static constructor
  // Creates a new empty archive of given name
  static create(params = {}) {
    const archive = new UdmArchive(params);

    if (window.__isSessionClient) {
      return new Proxy(archive, {
        get(target, property) {
          if (['then'].includes(property)) return target[property];
          const requestAndListen = async (property, args) => {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            const activeServiceWorker = serviceWorkerRegistration.active;
            const id = crypto.randomUUID();
            return new Promise((resolve, reject) => {
              activeServiceWorker.postMessage({
                type: ARCHIVE_MESSAGE.REQUEST,
                id,
                property,
                args,
              });
              const messageHandler = ({ data }) => {
                if (data.type === ARCHIVE_MESSAGE.RESPONSE && data.id === id) {
                  if (data.payload) resolve(data.payload);
                  else reject(new Error(`Archive property: ${property} not found`));
                  navigator.serviceWorker.removeEventListener('message', messageHandler);
                }
              };
              navigator.serviceWorker.addEventListener('message', messageHandler);
            });
          };
          if (typeof target[property] === 'function') {
            return function (...args) {
              return requestAndListen(property, args);
            };
          }
          return requestAndListen(property);
        },
      });
    }

    return archive.db.open().then(() =>
      archive._prune().then(() =>
        archive._findSequenceStart().then(start => {
          console.debug(`Archive numbering sequence starts at ${start}`);
          archive._sequence = start;

          if (!params.name) {
            archive.name = _autoName();
          }
          return archive.db.clear(archive._makeKey()).then(() => archive);
        }),
      ),
    );
  }

  // populates the archive from DB,
  // expects name to be passes in params
  open(params = {}) {
    this._reset(params);

    // eslint-disable-next-line consistent-return
    return this.db.keys(this._makeKey()).then(keys => {
      // eslint-disable-line consistent-return
      let valid = false;
      const xmlKey = this._makeKey('data.udm');
      keys.forEach(k => {
        if (k === xmlKey) {
          valid = true;
        } else {
          this.attachments[this._getPath(k)] = {};
        }
      });

      if (!valid) {
        return Promise.reject(new Error(`Document ${this.name} doesn't exist`));
      }
    });
  }

  // Loads archive from a buffer
  load(params = {}) {
    console.assert(params.path);
    console.assert(params.buffer);
    let p;

    if (!params.reuseSession) {
      this.name = _autoName();
      p = this._prune();
    } else {
      p = Promise.resolve();
    }

    this.exportName = params.name;
    this.importPath = params.path;

    return p.then(() => this._parse(params.buffer));
  }

  // New session, simply means changing the internal name, so that next time we write into
  // the DB, it's done using new keys
  newSession(params = {}) {
    let p;

    if (!params.reuseSession) {
      this.name = params.name || _autoName();
      p = this._prune();
    } else {
      console.debug('reusing old session DB entry');
      p = Promise.resolve();
    }

    return p.then(() =>
      // clear any existing entries using this key
      this.db.clear(this._makeKey()),
    );
  }

  // Clears the entire indexed DB -- use with extreme care
  clear() {
    return this.db.clear();
  }

  // Sets the archive user facing name
  setExportName(name) {
    this.exportName = name;

    // This function can be called before the xml fragment was stored, in which case the
    // the new name gets automatically written on the next xml write, so we catch the
    // 'no key' error here
    // eslint-disable-next-line consistent-return
    return this.db.setMeta(this._makeKey('data.udm'), { exportName: name }).catch(e => {
      // eslint-disable-line consistent-return
      if (!e.message.startsWith('no key')) {
        return Promise.reject(e);
      }
    });
  }

  // state reset, called on open
  _reset(params = {}) {
    this.name = params.name || _autoName(); // name of the document in the db
    this.exportName = this.name; // the user facing name
    this.importPath = null;
    this.attachments = {};
    this.currentOffset = 0;
  }

  // Finds where the document numbering sequence should start based on what is
  // already in the DB
  _findSequenceStart() {
    return this.listDocuments().then(docs => {
      let max = 0;
      let max2 = 0;

      docs.forEach(d => {
        const i = parseInt(d.key);
        if (!Number.isNaN(i)) {
          if (i > max) {
            max = i;
          }

          // Because the DB pruning uses LRU policy, the numbers grow for ever,
          // so we implement a manual wrap at 10000 to avoid stupidly large numbers
          // featuring in the keys, for this we find a maximum from the botton half
          // of the id space
          if (i < 5000 && i > max2) {
            max2 = i;
          }
        }
      });

      if (max >= 9999) {
        max = max2;
        console.debug(`post rolover sequence starts at ${max}`);
      }

      return max;
    });
  }

  // constructs notional path for our document, used as the
  // indexedDB key
  // fileName is the name of the file this key represents, e.g.,
  // the actual file name for attachments
  // prefix is the notional subdirectory for attachemtns, e.g.,
  // 'videos'
  // With no parameters generates the base key prefix
  _makeKey(fileName, prefix) {
    console.assert(this.name);
    if (prefix) {
      return `${this.name}/vstudm/${prefix}/${fileName}`;
    }
    if (fileName) {
      return `${this.name}/vstudm/${fileName}`;
    }

    return `${this.name}/vstudm`;
  }

  // inverse to _makeKey()
  _getPath(key) {
    const pfx = `${this.name}/vstudm/`;
    if (key.startsWith(pfx)) {
      return key.substring(pfx.length);
    }

    return key;
  }

  // Calculates the size of the archive in bytes using the metadata
  // store, i.e., avoiding loading large data chunks
  _archiveSize() {
    let size = 1024; // the archive terminating block

    const calcSize = meta => {
      const len = meta.length;

      let tail = len % 512;
      if (tail) {
        tail = 512 - tail;
      }

      return 512 + len + tail;
    };

    return this.db.keys(this._makeKey()).then(keys => {
      const promises = [];
      keys.forEach(key => {
        promises.push(
          this.db.getMeta(key).then(meta => {
            size += calcSize(meta);
          }),
        );
      });

      return Promise.all(promises).then(() => size);
    });
  }

  // parse archive from a buffer, if the DB contains document of the
  // same name, this will be completely deleted first
  // the returned promise resolves with the xml string
  _parse(buffer) {
    if (!this.name) {
      return Promise.reject(new Error('Name not set'));
    }

    let offset = 0;
    let hdr = this._parseHeader(buffer);

    if (!hdr) {
      return Promise.reject(new Error('not a UDM archive'));
    }

    return this.db.clear(this._makeKey()).then(() => {
      const promises = [];

      while (hdr) {
        // all files in the archive are under vstudm/ directory,
        // which we strip -- if there is no slash in the name
        // then this is not a valid udm archive
        const firstSlash = hdr.name.indexOf('/');
        if (firstSlash < 0) {
          return Promise.reject(new Error('malformed UDM archive'));
        }

        // Extract the file into the DB
        const name = hdr.name.substr(firstSlash + 1);
        promises.push(this._extractData(buffer, name));

        // if it's not the canonical UDM xml file then treat it as an
        // attachment
        if (name !== 'data.udm') {
          this.attachments[name] = {};
        }

        offset += hdr.length + 512;
        const tail = offset % 512;
        if (tail) {
          offset += 512 - tail;
        }

        // Advance buffer by offset
        const chunk = buffer.subarray(offset, offset + 512);
        hdr = this._parseHeader(chunk);
      }
      return Promise.all(promises).then(() => {
        const key = this._makeKey('data.udm');
        return this.db.get(key).then(xml => xml);
      });
    });
  }

  _defaultMeta() {
    return {
      exportName: this.exportName,
      importPath: this.importPath,
    };
  }

  // writes the XML part of the document
  // content should be a string (Uint8Array is supported but inefficient)
  writeXml(content) {
    const meta = this._defaultMeta();

    if (typeof content === 'string') {
      return this.db.set(this._makeKey('data.udm'), content, meta);
    }
    if (content instanceof Uint8Array) {
      const xml = _bufferToString(content);
      return this.db.set(this._makeKey('data.udm'), xml, meta);
    }

    return Promise.reject(new Error(`Unhandled input type ${typeof content}`));
  }

  // writes out this archive for user download
  // the returned promise resolves with Uint8Array containing
  // the archive
  exportDocument() {
    if (!this.name) {
      return Promise.reject(new Error('Document name not set'));
    }

    let exportBuffer;

    this.currentOffset = 0;

    // Welcome to promise hell -- async promise-based loop
    // iterates while _cond() is true, calling _func()
    const promiseLoop = (_cond, _func) =>
      new Promise((_res, _rej) => {
        const loop = () => {
          if (!_cond()) {
            _res();
          } else {
            _func()
              .then(loop)
              .catch(e => {
                _rej(e);
              });
          }
        };

        loop();
      });

    const processAttachment = path =>
      this.db
        .get(this._makeKey(path))
        .then(content =>
          this._writeHeader(exportBuffer, `vstudm/${path}`, content.byteLength).then(() =>
            this._writeFile(exportBuffer, content),
          ),
        );

    const attachments = Object.keys(this.attachments);
    let i = 0;

    return this._archiveSize().then(size => {
      // First of all, allocate the export buffer
      console.debug(`exporting document, archive size is ${size}`);
      exportBuffer = new Uint8Array(size);

      // Now process any attachements in strict sequence
      return promiseLoop(
        () => i < attachments.length,
        () => processAttachment(attachments[i++]),
      ).then(() =>
        // Finally write the UDM document
        this.db.get(this._makeKey('data.udm')).then(content => {
          const contentBuffer = _stringToBuffer(content);
          return this._writeHeader(exportBuffer, 'vstudm/data.udm', contentBuffer.byteLength).then(
            () =>
              this._writeFile(exportBuffer, contentBuffer).then(() => {
                if (this.currentOffset % 512 !== 0) {
                  return Promise.reject(new Error('Incorrectly aligned final currentOffset'));
                }

                exportBuffer.fill(0, this.currentOffset, this.currentOffset + 1024);
                return exportBuffer;
              }),
          );
        }),
      );
    });
  }

  // Adds attachment to the archive
  async addAttachment(prefix, file) {
    const mimeType = file.type || 'video/mp4';
    const fileContents = new Uint8Array(await readFileAsArrayBuffer(file));
    await this.db.set(this._makeKey(file.name, prefix), fileContents, {
      mimeType,
    });
    const path = `${prefix}/${file.name}`;
    this.attachments[path] = {};
    // return the full archive path of the attachment, including the root folder
    // this will get stored in the xml doc
    return { archPath: `vstudm/${path}`, fileContents };
  }

  // Replace attachment to the archive
  async replaceAttachment(prefix, curFileName, file) {
    const mimeType = file.type || 'video/mp4';
    const curKey = this._makeKey(curFileName, prefix);
    const key = this._makeKey(file.name, prefix);
    const fileContents = await this.db.get(key);
    await this.db.remove(curKey);
    await this.db.set(key, fileContents, { mimeType });

    const path = `${prefix}/${file.name}`;
    const curPath = `${prefix}/${curFileName}`;
    delete this.attachments[curPath];
    this.attachments[path] = {};
    // return the full archive path of the attachment, including the root folder
    // this will get stored in the xml doc
    return { archPath: `vstudm/${path}`, fileContents };
  }

  setAttachmentId(rawPath, id) {
    // For convenience, handle both UDM stored path and the key, so that
    // we can chain up directly to addAttachment()
    let path = rawPath;
    if (path.startsWith('vstudm/')) {
      path = path.substr(7);
    }
    const attachment = this.attachments[path];
    attachment.id = id;
    return id;
  }

  getAttachmentPaths() {
    return Object.keys(this.attachments);
  }

  async getAttachment(path) {
    const key = this._makeKey(path);
    const meta = await this.db.getMeta(key);
    const data = await this.db.get(key);
    return { data, mimeType: meta.mimeType || 'video/mp4' }; // TODO: the mimeType never seems to be set when opening a file
  }

  removeAttachment(path) {
    console.log('remove attachment %s', path);
    // pruning can get this.attachments and the DB contents out of sync.
    // so allow for the inconsistency here. not great.
    delete this.attachments[path];
    return this.db.remove(this._makeKey(path));
  }

  clearAttachments(prefix) {
    const keys = Object.keys(this.attachments);
    const promises = [];
    keys.forEach(k => {
      if (!prefix || k.startsWith(prefix)) {
        promises.push(this.removeAttachment(k));
      }
    });

    return Promise.all(promises);
  }

  removeAttachmentById(id) {
    const keys = Object.keys(this.attachments);
    let path;
    keys.forEach(k => {
      if (this.attachments[k].id === id) {
        path = k;
      }
    });

    if (!path) {
      return Promise.reject(new Error(`Could not find id ${id}`));
    }

    return this.removeAttachment(path);
  }

  // Extracts data for a single component file from a user archive
  // buffer is Uint8Array holding the entire archive document
  // path is the notional path of the fragment (relative to the vstudm/ archive root)
  _extractData(buffer, path) {
    let offset = 0;
    let chunk = buffer.subarray(0, 512);
    let hdr = this._parseHeader(chunk);
    let promise;

    // Locate the matching file header in the archive
    while (hdr) {
      if (hdr.name === `vstudm/${path}`) {
        const data = buffer.subarray(offset + 512, offset + 512 + hdr.length);

        // Convert the xml part of the doc to utf-8 before storing it in the db
        if (path === 'data.udm') {
          const xml = _bufferToString(data);
          promise = this.db.set(this._makeKey(path), xml, this._defaultMeta());
        } else {
          promise = this.db.set(this._makeKey(path), data);
        }
        break;
      }

      offset += 512 + hdr.length;

      // files are padded to next 512 bytes.
      const tail = offset % 512;

      if (tail) {
        offset += 512 - tail;
      }

      chunk = buffer.subarray(offset, offset + 512);
      hdr = this._parseHeader(chunk);
    }

    if (!promise) {
      return Promise.reject(new Error(`Failed to extract data for ${path}`));
    }

    return promise;
  }

  // Calculate check sum of the file header
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line class-methods-use-this
  _calcCheckSum(buffer) {
    // sum of the header (unsigned) bytes, with space
    // substituted for the 8 checksum bytes.
    let sum = 256;
    let i;

    for (i = 0; i < 148; i++) {
      sum += buffer[i];
    }

    for (i = 156; i < 512; i++) {
      sum += buffer[i];
    }

    return sum;
  }

  // Parse header; the header is assumed to be at the start of the buffer
  _parseHeader(buffer) {
    console.assert(buffer instanceof Uint8Array);
    console.assert(buffer.length >= 512);

    // Field   -  size - what
    // --------------------------
    //   0        100  file name
    // 124         12  file length
    // 148          8  checksum
    // 265          6  'vstudm' magic
    // 271          3  udm version

    // Generates string from a portion of the buffer,
    // handling 0-terminators
    const stringFromBuffer = (offset, len) => {
      let b = buffer.subarray(offset, offset + len);
      const o = b.indexOf(0);

      if (o > -1) {
        b = buffer.subarray(offset, offset + o);
      }

      return String.fromCharCode(...b);
    };

    const parseCheckSum = () => {
      // six octal digits
      const s = stringFromBuffer(148, 6);
      return parseInt(s, 8);
    };

    // The first byte of the checksum can only be 0 if this is a null
    // header
    if (buffer[148] === 0) {
      return null;
    }

    const sum1 = this._calcCheckSum(buffer);
    const sum2 = parseCheckSum();

    if (sum1 !== sum2) {
      console.warn(`checksum mismatch, calculated  ${sum1}, parsed ${sum2}`);
      return null;
    }

    // Check for udm magic
    if (stringFromBuffer(265, 6) !== 'vstudm') {
      return null;
    }

    // All we need from here is the file name and size, ignore the rest
    const hdr = {};
    hdr.name = stringFromBuffer(0, 100);
    hdr.length = parseInt(stringFromBuffer(124, 12), 8);

    return hdr;
  }

  // Writes appropriate file header into the archive tar, represented by exportBuffer
  _writeHeader(exportBuffer, path, length) {
    return new Promise((resolve /* , reject */) => {
      // write header to where the archive is at current offset
      const buffer = new Uint8Array(512);
      buffer.fill(0);

      let i;
      const nameLen = Math.min(path.length, 99);

      for (i = 0; i < nameLen; i++) {
        buffer[i] = path.charCodeAt(i);
      }

      // Mode
      const mode = 436; // 0664, but can't use octals in strict mode
      const m = mode.toString(8);

      for (i = 0; i < Math.min(m.length, 8); i++) {
        buffer[i + 100] = m.charCodeAt(i);
      }

      // Size as octal digits, at most 11
      const l = length.toString(8);

      for (i = 0; i < Math.min(l.length, 11); i++) {
        buffer[i + 124] = l.charCodeAt(i);
      }

      // magic
      for (i = 0; i < 6; i++) {
        buffer[265 + i] = 'vstudm'.charCodeAt(i);
      }

      // Checksum is 6 octal digits followed by 0 and space
      let sum = this._calcCheckSum(buffer);
      sum = sum.toString(8);

      for (i = 0; i < 6; i++) {
        buffer[i + 148] = sum.charCodeAt(i);
      }

      // +1 to skip over one 0
      buffer[i + 148 + 1] = ' '.charCodeAt(0);

      exportBuffer.set(buffer, this.currentOffset);

      // and advance
      this.currentOffset += 512;
      resolve();
    });
  }

  // Writes file contents into the archive tar
  // data is Uint8Array
  async _writeFile(exportBuffer, data) {
    const size = data.byteLength;

    exportBuffer.set(data, this.currentOffset);

    // Advance current offset
    this.currentOffset += size;

    let tail = size % 512;
    if (tail) {
      tail = 512 - tail;
    }

    exportBuffer.fill(0, this.currentOffset, this.currentOffset + tail);

    // Advance current offset
    this.currentOffset += tail;
  }

  // for testing; pass in xml doc string
  _createTar(xmlDoc) {
    let tarLen = xmlDoc.length;
    const tail = tarLen % 512;
    const pad = 512 - tail;

    tarLen += 3 * 512 + pad;

    const buffer = new Uint8Array(tarLen);

    // Write header
    const hdr = buffer.subarray(0, 512);
    hdr.fill(0);

    let i;
    const path = 'vstudm/data.udm';

    for (i = 0; i < path.length; i++) {
      buffer[i] = path.charCodeAt(i);
    }

    // Size as octal digits, at most 11
    const l = xmlDoc.length.toString(8);

    for (i = 0; i < Math.min(l.length, 11); i++) {
      buffer[i + 124] = l.charCodeAt(i);
    }

    // magic
    const magic = 'vstudm';
    for (i = 0; i < magic.length; i++) {
      buffer[265 + i] = String(magic).charCodeAt(i);
    }

    // Checksum is 6 octal digits followed by 0 and space
    let sum = this._calcCheckSum(buffer);
    sum = sum.toString(8);

    const sumLen = Math.min(sum.length, 6);

    for (i = 0; i < 6 - sumLen; i++) {
      buffer[i + 148] = '0'.charCodeAt(0);
    }

    for (; i < 6; i++) {
      buffer[i + 148] = sum.charCodeAt(i - (6 - sumLen));
    }

    // +1 to skip over one 0
    buffer[i + 148 + 1] = ' '.charCodeAt(0);

    // write doc
    for (i = 0; i < xmlDoc.length; i++) {
      buffer[i + 512] = xmlDoc.charCodeAt(i);
    }

    // write final padding
    const ftr = buffer.subarray(512 + xmlDoc.length);
    ftr.fill(0);

    return buffer;
  }

  // returns an array of objects representing each document in the
  // indexedDB, in the following format
  // {
  //   key,        // the DB key prefix for the document
  //   exportName  // the user-friendly name of the document
  // }
  listDocuments() {
    let i = 0;
    const docs = [];

    const promiseLoop = (_cond, _func) =>
      new Promise((_res, _rej) => {
        const loop = () => {
          if (!_cond()) {
            _res();
          } else {
            _func()
              .then(loop)
              .catch(e => {
                _rej(e);
              });
          }
        };
        loop();
      });

    const processMeta = key =>
      // eslint-disable-next-line consistent-return
      this.db.getMeta(key).then(meta => {
        // eslint-disable-line consistent-return
        const end = key.lastIndexOf('/vstudm/data.udm');
        if (end < 0) {
          return Promise.reject(new Error(`Malformed key ${key}`));
        }

        const k = key.substring(0, end);
        const d = { exportName: meta.exportName, key: k };

        docs.push(d);
      });

    return this.db
      .keys()
      .then(keys => {
        // Filter the keys down to those pointing at the xml doc
        const filtered = [];
        keys.forEach(k => {
          if (k.endsWith('/data.udm')) {
            filtered.push(k);
          }
        });
        return filtered;
      })
      .then(keys =>
        promiseLoop(
          () => i < keys.length,
          () => processMeta(keys[i++]),
        ).then(() => docs),
      );
  }
}
