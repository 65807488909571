import EventEmitter from 'eventemitter3';

let nextId = 0;

export class DataShareDataSet extends EventEmitter {
  constructor({ apiId = '', id = `${nextId++}`, name = 'Run', position = 0, columnIds = [] }) {
    super();
    this._apiId = apiId;
    this._id = id;
    this._name = name;
    this._position = position;
    this._columnIds = columnIds;

    this._nativeId = '';
  }

  update(props) {
    Object.keys(props).forEach(key => {
      if (this[key] !== props[key]) {
        this[key] = props[key];
      }
    });
  }

  get apiId() {
    return this._apiId;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.emit('name-changed', this.name);
  }

  get nativeId() {
    return this._nativeId;
  }

  set nativeId(nativeId) {
    this._nativeId = nativeId;
  }

  get position() {
    return this._position;
  }

  set position(position) {
    this._position = position;
    this.emit('position-changed', position);
  }

  get columnIds() {
    return [...this._columnIds]; // return a copy
  }

  set columnIds(columnIds) {
    this._columnIds = columnIds;
    this.emit('column-ids-changed', columnIds);
  }
}
