const ARCHIVE_MIME_TYPES = ['application/x-gambl', 'application/x-vmbl'];

export const getDefaultType = () => {
  let type = 'gambl';

  switch (APP_ID) {
    case 'SA':
      type = 'smbl';
      break;
    case 'VA':
      type = 'vmbl';
      break;
    case 'IA':
      type = 'imbl';
      break;
    default:
      type = 'gambl';
  }

  return type;
};

export function getExtensionFromPath(filepath) {
  const els = filepath.split('.');
  const extension = els[els.length - 1];
  return extension;
}

export const getMimeType = type => {
  if (type === 'csv') return 'text/csv';
  if (type === 'png') return 'image/png';
  if (type === 'imbl') return 'application/x-imbl';
  if (type === 'vmbl') return 'application/x-vmbl';
  if (type === 'gambl') return 'application/x-gambl';
  if (type === 'smbl') return 'application/x-smbl';
  if (type === 'pdf') return 'application/pdf';

  return `application/xml`;
};

/**
 * Check whether the given file type indicators indicate a text-based file
 *
 * @param {Object} opts
 * @param {string} opts.mimetype
 * @param {string} opts.extension
 *
 * @return {boolean}
 */
export function isKnownTextFormat({ mimetype, extension }) {
  // Cordova apps seem to be getting bogus mime types (e.g. iOS gets
  // `application/octet-stream`) causing us to use the incorrect file
  // import method (text instead of archive). So ignoring the mime type and
  // using the extension should suffice.
  if (window.cordova) {
    return !ARCHIVE_MIME_TYPES.includes(getMimeType(extension));
  }
  const _mimetype = mimetype || getMimeType(extension);
  return !ARCHIVE_MIME_TYPES.includes(_mimetype);
}

export const getOpenableTypes = () => {
  let types = ['csv'];

  switch (APP_ID) {
    case 'GA':
      types = ['gambl', 'ambl', 'smbl', 'imbl', 'qmbl'];
      break;
    case 'SA':
      types = ['smbl'];
      break;
    case 'VA':
      types = ['vmbl'];
      break;
    case 'IA':
      types = ['imbl'];
      break;
    default:
      types = ['ambl'];
  }

  return types;
};

export const getName = (name, unique) => {
  const makeUniqueId = (length = 4) => {
    // mostly unique id
    const possible = '0123456789';
    let id = '';

    for (let i = 0; i < length; ++i) {
      id += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return id;
  };

  const cleanExtension = (name, type) => name.split(`.${type}`)[0]; // TODO make this more robust
  let newName = `${cleanExtension(name, getDefaultType())}`;

  if (unique) {
    newName = `${newName}_${makeUniqueId()}`;
  }

  return newName;
};

export function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.onerror = event => reject(event);
    reader.onabort = event => reject(event);
    reader.readAsArrayBuffer(file);
  });
}

export function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.onerror = event => reject(event);
    reader.onabort = event => reject(event);
    reader.readAsText(file);
  });
}
