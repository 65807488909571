import { rpc } from './rpc.js';
import * as platform from './PLATFORM_ID';

const { RPCMessageChannel } = rpc;

export const init = rpcModule => {
  const listeners = [];
  let rpcMessageChannel = null;

  function notifyListeners(data) {
    const message = { data };

    if (rpcMessageChannel) {
      rpcMessageChannel.processMessage(data);
    }

    listeners.forEach(listener => {
      listener(message);
    });
  }

  rpcModule.onmessage = notifyListeners;

  rpcMessageChannel = new RPCMessageChannel({
    // mirror the NaCl interface
    postMessage: jsonRPC => {
      rpcModule.postMessage(jsonRPC);
    },

    addEventListener: (name, listener) => {
      if (name === 'message') {
        listeners.push(listener);
      }
    },
  });

  return Promise.resolve({
    rpc: rpcMessageChannel,
    listener: document.createElement('embed'),
  });
};

export const createRPCMessageChannel = async (rpcModule, progessCallback) => {
  if (platform.init) {
    return platform.init(RPCMessageChannel, progessCallback);
  }

  return init(rpcModule);
};
